import React, { useEffect, useState, useCallback } from 'react';
import { Trash2, Upload, Download, Settings, RotateCw, AlertTriangle, FileType, Archive } from 'lucide-react';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { PDFDocument } from 'pdf-lib';
import heic2any from 'heic2any';
import Documentation from '../shared/Documentation';
import useTranslate from '../../hooks/useTranslate';
import Loading from '../../components/shared/Loading';

const ImageConverter = () => {
  // State management
  const { translateText, currentLanguage } = useTranslate();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [convertedFiles, setConvertedFiles] = useState([]);
  const [outputFormat, setOutputFormat] = useState('PNG');
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [quality, setQuality] = useState(90);
  const [resize, setResize] = useState({ width: '', height: '' });
  const [progress, setProgress] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [isConverting, setIsConverting] = useState(false);
  const [translations, setTranslations] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  // Handle iframe resizing
  useEffect(() => {
    const handleResize = () => {
      const height = document.documentElement.scrollHeight;
      window.parent.postMessage(height, "*");
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [convertedFiles, progress]);

  // Translation effect
  useEffect(() => {
    const translateComponents = async () => {
      setIsLoading(true);
      try {
        const translatedContent = {
          title: await translateText('Image Converter', currentLanguage),
          description: await translateText('Convert images online, for free.', currentLanguage),
          uploadTitle: await translateText('Upload Files', currentLanguage),
          dragDropText: await translateText('Drag & Drop files here', currentLanguage),
          selectFilesText: await translateText('Select Files', currentLanguage),
          selectedFilesTitle: await translateText('Selected Files', currentLanguage),
          outputFormatTitle: await translateText('Output Format', currentLanguage),
          advancedSettingsText: await translateText('Advanced Settings', currentLanguage),
          convertButtonLabel: await translateText('Convert', currentLanguage),
          convertingLabel: await translateText('Converting...', currentLanguage),
          qualityLabel: await translateText('Quality (JPEG/WebP/HEIC)', currentLanguage),
          resizeLabel: await translateText('Resize', currentLanguage),
          widthPlaceholder: await translateText('Width', currentLanguage),
          heightPlaceholder: await translateText('Height', currentLanguage),
          convertedFilesTitle: await translateText('Converted Files', currentLanguage),
          downloadLabel: await translateText('Download', currentLanguage),
          deleteLabel: await translateText('Delete', currentLanguage),
          downloadAllLabel: await translateText('Download All as ZIP', currentLanguage),
          errorMessage: await translateText('Conversion failed:', currentLanguage),
          heicSupportMessage: await translateText('HEIC format supported', currentLanguage)
        };
        setTranslations(translatedContent);
      } catch (error) {
        console.error('Translation error:', error);
        setTranslations({
          title: 'Image Converter',
          description: 'Convert images online, for free.',
          uploadTitle: 'Upload Files',
          dragDropText: 'Drag & Drop files here',
          selectFilesText: 'Select Files',
          selectedFilesTitle: 'Selected Files',
          outputFormatTitle: 'Output Format',
          advancedSettingsText: 'Advanced Settings',
          convertButtonLabel: 'Convert',
          convertingLabel: 'Converting...',
          qualityLabel: 'Quality (JPEG/WebP/HEIC)',
          resizeLabel: 'Resize',
          widthPlaceholder: 'Width',
          heightPlaceholder: 'Height',
          convertedFilesTitle: 'Converted Files',
          downloadLabel: 'Download',
          deleteLabel: 'Delete',
          downloadAllLabel: 'Download All as ZIP',
          errorMessage: 'Conversion failed:',
          heicSupportMessage: 'HEIC format supported'
        });
      } finally {
        setIsLoading(false);
      }
    };

    translateComponents();
  }, [translateText, currentLanguage]);

  // File handling functions
  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
  };

  const handleDrop = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    const files = Array.from(event.dataTransfer.files);
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
  }, []);

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const removeFile = (index) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const removeConvertedFile = (index) => {
    setConvertedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  // HEIC conversion function
  const convertHEICToBlob = async (file) => {
    try {
      const resultBlob = await heic2any({
        blob: file,
        toType: 'image/png',
        quality: quality / 100
      });
      return new File([resultBlob], file.name.replace(/\.heic$/i, '.png'), {
        type: 'image/png'
      });
    } catch (error) {
      throw new Error(`HEIC conversion failed: ${error.message}`);
    }
  };

  // Image processing function
  const processImage = async (file) => {
    if (file.type.toLowerCase() === 'image/heic' || file.name.toLowerCase().endsWith('.heic')) {
      return await convertHEICToBlob(file);
    }
    return file;
  };

  // Main conversion function
  const convertImages = async (format = outputFormat) => {
    if (selectedFiles.length === 0) {
      setErrorMessage(translations.errorMessage);
      return;
    }

    setProgress(0);
    setErrorMessage('');
    setIsConverting(true);

    const interval = 100;
    const progressUpdateInterval = setInterval(() => {
      setProgress((prev) => Math.min(prev + 1, 100));
    }, interval);

    try {
      const converted = await Promise.all(
        selectedFiles.map(async (file, index) => {
          // Process HEIC files first
          const processedFile = await processImage(file);
          const img = await createImageBitmap(processedFile);
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          if (resize.width && resize.height) {
            canvas.width = parseInt(resize.width);
            canvas.height = parseInt(resize.height);
          } else {
            canvas.width = img.width;
            canvas.height = img.height;
          }

          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

          let dataUrl;
          const formatLower = format.toLowerCase();

          if (formatLower === 'pdf') {
            const pdfDoc = await PDFDocument.create();
            const page = pdfDoc.addPage([canvas.width, canvas.height]);
            const imgData = canvas.toDataURL('image/png').split(',')[1];
            const image = await pdfDoc.embedPng(imgData);
            page.drawImage(image, { x: 0, y: 0, width: canvas.width, height: canvas.height });
            const pdfBytes = await pdfDoc.save();
            dataUrl = URL.createObjectURL(new Blob([pdfBytes], { type: 'application/pdf' }));
          } else if (formatLower === 'svg') {
            const svgString = `<svg xmlns="http://www.w3.org/2000/svg" width="${canvas.width}" height="${canvas.height}"><image href="${canvas.toDataURL('image/png')}" width="${canvas.width}" height="${canvas.height}" /></svg>`;
            dataUrl = `data:image/svg+xml;base64,${btoa(svgString)}`;
          } else {
            dataUrl = canvas.toDataURL(`image/${formatLower}`, quality / 100);
          }

          return {
            name: `${file.name.split('.')[0]}.${formatLower}`,
            dataUrl,
            originalSize: file.size,
            newSize: Math.round((dataUrl.length * 3) / 4),
          };
        })
      );

      clearInterval(progressUpdateInterval);
      setProgress(100);
      setConvertedFiles(converted);
    } catch (error) {
      clearInterval(progressUpdateInterval);
      setErrorMessage(`${translations.errorMessage} ${error.message}`);
      setProgress(0);
    } finally {
      setIsConverting(false);
    }
  };

  // Download functions
  const downloadFile = (file) => {
    const link = document.createElement('a');
    link.href = file.dataUrl;
    link.download = file.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadAllAsZip = async () => {
    if (convertedFiles.length === 0) {
      alert(translations.errorMessage);
      return;
    }

    const zip = new JSZip();
    convertedFiles.forEach((file) => {
      const base64Data = file.dataUrl.split(',')[1];
      zip.file(file.name, base64Data, { base64: true });
    });

    const content = await zip.generateAsync({ type: 'blob' });
    saveAs(content, 'converted_images.zip');
  };

  if (isLoading) {
    return <Loading />;
  }

  // Render component
  return (
    <>
      <div className="max-w-4xl mx-auto px-4">
        <div className="text-center mb-6">
          <h1 className="text-3xl lg:text-4xl font-bold mb-2 text-white">{translations.title}</h1>
          <p className="text-sm text-gray-300">{translations.description}</p>
          <p className="text-sm text-[#F7AA01] mt-1">{translations.heicSupportMessage}</p>
        </div>

        <nav className="mb-6 overflow-x-auto">
          <ul className="flex justify-center gap-2 md:gap-4 whitespace-nowrap">
            {['PNG', 'JPEG', 'WEBP', 'PDF', 'SVG'].map((format) => (
              <li key={format}>
                <button
                  onClick={() => setOutputFormat(format)}
                  className={`px-3 py-1 md:px-4 md:py-2 rounded-lg font-medium text-sm md:text-base ${
                    outputFormat === format ? 'bg-[#F7AA01] text-[#2F2F30]' : 'bg-gray-700 text-gray-300'
                  } hover:bg-[#F7AA01] hover:text-[#2F2F30] transition-colors duration-200`}
                >
                  {format}
                </button>
              </li>
            ))}
          </ul>
        </nav>

        <div className="mb-6">
          <h2 className="text-xl lg:text-2xl mb-2 font-medium text-white">{translations.uploadTitle}</h2>
          <div
            className="border-2 border-dashed border-gray-600 rounded-lg p-6 text-center cursor-pointer bg-gray-700 flex flex-col justify-center items-center transition-colors duration-200 hover:border-[#F7AA01]/50"
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onClick={() => document.getElementById('fileInput').click()}
          >
            <Upload size={48} className="text-gray-300 mb-4" />
            <p className="text-gray-300">{translations.dragDropText}</p>
            <p className="text-gray-300">or</p>
            <button className="bg-[#F7AA01] text-[#2F2F30] px-4 py-2 rounded-lg mt-2 flex items-center gap-2 hover:bg-[#F7AA01]/80 transition-colors duration-200">
              <Upload size={20} />
              {translations.selectFilesText}
            </button>
            <input
              id="fileInput"
              type="file"
              onChange={handleFileChange}
              accept="image/*,.heic"
              multiple
              className="hidden"
            />
          </div>
        </div>

        {selectedFiles.length > 0 && (
          <div className="mb-6">
            <div className="flex flex-col">
              {selectedFiles.map((file, index) => (
                <div key={index} className="flex justify-between items-center bg-gray-700 p-2 rounded-lg mb-2">
                  <span className="truncate text-white">{file.name}</span>
                  <div className="flex items-center gap-2">
                    <span className="text-gray-300 text-sm">
                      {(file.size / 1024).toFixed(1)} KB
                    </span>
                    <button
                      onClick={() => removeFile(index)}
                      className="text-red-500 hover:text-red-400 transition-colors duration-200"
                      title={translations.deleteLabel}
                    >
                      <Trash2 size={20} />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        <div className="mb-6">
          <h2 className="text-xl lg:text-2xl mb-2 font-medium text-white">{translations.outputFormatTitle}</h2>
          <select
            value={outputFormat}
            onChange={(e) => setOutputFormat(e.target.value)}
            className="w-full p-2 border rounded-lg bg-gray-700 text-white border-gray-600 focus:border-[#F7AA01] focus:ring-1 focus:ring-[#F7AA01] outline-none"
          >
            <option>PNG</option>
            <option>JPEG</option>
            <option>WEBP</option>
            <option>PDF</option>
            <option>SVG</option>
          </select>
        </div>

        <div className="flex flex-col sm:flex-row justify-between items-center mb-6 gap-4">
          <button
            onClick={() => setShowAdvanced(!showAdvanced)}
            className="flex items-center gap-2 text-[#F7AA01] hover:text-[#F7AA01]/80 transition-colors duration-200"
          >
            <Settings size={20} />
            {translations.advancedSettingsText}
          </button>
          <button
            onClick={() => convertImages(outputFormat)}
            disabled={isConverting}
            className={`bg-[#F7AA01] text-[#2F2F30] px-4 py-2 rounded-lg flex items-center gap-2 hover:bg-[#F7AA01]/80 transition-colors duration-200 ${
              isConverting ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
            <FileType size={20} />
            {isConverting ? translations.convertingLabel : translations.convertButtonLabel}
          </button>
        </div>

        {showAdvanced && (
          <div className="mb-6">
            <h2 className="text-xl lg:text-2xl mb-2 font-medium text-white">{translations.advancedSettingsText}</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div>
                <label className="block mb-2 text-white">{translations.qualityLabel}</label>
                <input
                  type="range"
                  min="1"
                  max="100"
                  value={quality}
                  onChange={(e) => setQuality(e.target.value)}
                  className="w-full bg-gray-700"
                />
                <span className="text-white">{quality}%</span>
              </div>
              <div>
                <label className="block mb-2 text-white">{translations.resizeLabel}</label>
                <div className="flex gap-2">
                  <input
                    type="number"
                    placeholder={translations.widthPlaceholder}
                    value={resize.width}
                    onChange={(e) => setResize({ ...resize, width: e.target.value })}
                    className="w-full p-2 border rounded-lg bg-gray-700 text-white border-gray-600 focus:border-[#F7AA01] focus:ring-1 focus:ring-[#F7AA01] outline-none"
                  />
                  <input
                    type="number"
                    placeholder={translations.heightPlaceholder}
                    value={resize.height}
                    onChange={(e) => setResize({ ...resize, height: e.target.value })}
                    className="w-full p-2 border rounded-lg bg-gray-700 text-white border-gray-600 focus:border-[#F7AA01] focus:ring-1 focus:ring-[#F7AA01] outline-none"
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        {progress > 0 && (
          <div className="mb-6">
            <div className="relative w-full bg-gray-200 rounded-lg overflow-hidden h-4">
              <div
                className="absolute top-0 left-0 h-full bg-[#F7AA01] transition-all duration-300 ease-in-out"
                style={{ width: `${progress}%` }}
              />
            </div>
            <p className="text-center mt-2 text-white">{progress}%</p>
          </div>
        )}

        {errorMessage && (
          <div className="mb-6 bg-red-100 text-red-700 p-4 rounded-lg flex items-center gap-2">
            <AlertTriangle size={24} />
            <span>{errorMessage}</span>
          </div>
        )}

        {convertedFiles.length > 0 && (
          <div className="mb-6">
            <h2 className="text-xl lg:text-2xl mb-2 font-medium text-white">{translations.convertedFilesTitle}</h2>
            <div className="flex flex-col">
              {convertedFiles.map((file, index) => (
                <div key={index} className="flex justify-between items-center bg-gray-700 p-2 rounded-lg mb-2">
                  <span className="truncate text-white">{file.name}</span>
                  <div className="flex items-center gap-2">
                    <span className="text-gray-300 text-sm">
                      {(file.newSize / 1024).toFixed(1)} KB
                    </span>
                    <button
                      onClick={() => downloadFile(file)}
                      className="text-[#F7AA01] hover:text-[#F7AA01]/80 transition-colors duration-200"
                      title={translations.downloadLabel}
                    >
                      <Download size={20} />
                    </button>
                    <button
                      onClick={() => removeConvertedFile(index)}
                      className="text-red-500 hover:text-red-400 transition-colors duration-200"
                      title={translations.deleteLabel}
                    >
                      <Trash2 size={20} />
                    </button>
                  </div>
                </div>
              ))}
            </div>
            
            {convertedFiles.length > 1 && (
              <div className="mt-4 flex justify-end">
                <button
                  onClick={downloadAllAsZip}
                  className="bg-[#F7AA01] text-[#2F2F30] px-4 py-2 rounded-lg flex items-center gap-2 hover:bg-[#F7AA01]/80 transition-colors duration-200"
                >
                  <Archive size={20} />
                  {translations.downloadAllLabel}
                </button>
              </div>
            )}
          </div>
        )}
      </div>

      <Documentation tool="converter" />
    </>
  );
};

export default ImageConverter;