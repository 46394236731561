import React, { useState, useCallback, useEffect } from 'react';
import { 
  Upload, Download, Trash2, RotateCw, AlertTriangle, 
  Stamp, Type, Image as ImageIcon, RotateCcw 
} from 'lucide-react';
import Documentation from '../shared/Documentation';
import useTranslate from '../../hooks/useTranslate';
import Loading from '../../components/shared/Loading';

const WatermarkImage = () => {
  const { translateText, currentLanguage } = useTranslate();
  const [translations, setTranslations] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [watermarkedFiles, setWatermarkedFiles] = useState([]);
  const [watermarkType, setWatermarkType] = useState('text');
  const [watermarkImage, setWatermarkImage] = useState(null);
  const [settings, setSettings] = useState({
    text: 'Watermark',
    fontSize: 24,
    color: '#ffffff',
    opacity: 50,
    position: 'center',
    rotation: 0,
    padding: 20,
    repeat: false,
    font: 'Arial',
    size: 30
  });
  const [progress, setProgress] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);

  const fonts = [
    'Arial', 'Times New Roman', 'Helvetica', 
    'Georgia', 'Verdana', 'Courier New'
  ];

  const positions = [
    { value: 'center', label: 'Center' },
    { value: 'topLeft', label: 'Top Left' },
    { value: 'topRight', label: 'Top Right' },
    { value: 'bottomLeft', label: 'Bottom Left' },
    { value: 'bottomRight', label: 'Bottom Right' }
  ];

  const handleFileChange = useCallback((event) => {
    const files = Array.from(event.target.files);
    if (event.target.id === 'watermarkInput') {
      setWatermarkImage(files[0]);
    } else {
      setSelectedFiles(prev => [...prev, ...files]);
    }
  }, []);

  const handleDrop = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    const files = Array.from(event.dataTransfer.files);
    setSelectedFiles(prev => [...prev, ...files]);
  }, []);

  const removeFile = useCallback((index) => {
    setSelectedFiles(prev => prev.filter((_, i) => i !== index));
  }, []);

  const removeWatermarkedFile = useCallback((index) => {
    setWatermarkedFiles(prev => prev.filter((_, i) => i !== index));
  }, []);

  const getPositionCoordinates = (position, canvasWidth, canvasHeight, watermarkWidth, watermarkHeight, padding) => {
    const positions = {
      center: {
        x: (canvasWidth - watermarkWidth) / 2,
        y: (canvasHeight - watermarkHeight) / 2
      },
      topLeft: {
        x: padding,
        y: padding
      },
      topRight: {
        x: canvasWidth - watermarkWidth - padding,
        y: padding
      },
      bottomLeft: {
        x: padding,
        y: canvasHeight - watermarkHeight - padding
      },
      bottomRight: {
        x: canvasWidth - watermarkWidth - padding,
        y: canvasHeight - watermarkHeight - padding
      }
    };
    return positions[position];
  };

  const applyWatermark = async () => {
    if (selectedFiles.length === 0) {
      setErrorMessage('Please select files to watermark');
      return;
    }

    if (watermarkType === 'image' && !watermarkImage) {
      setErrorMessage('Please select a watermark image');
      return;
    }

    setProgress(0);
    setErrorMessage('');
    setIsProcessing(true);

    try {
      const watermarked = await Promise.all(selectedFiles.map(async (file, index) => {
        const img = await createImageBitmap(file);
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);

        if (watermarkType === 'text') {
          ctx.save();
          ctx.globalAlpha = settings.opacity / 100;
          
          const calculatedFontSize = (canvas.width * settings.size) / 100;
          ctx.font = `${calculatedFontSize}px ${settings.font}`;
          ctx.fillStyle = settings.color;

          const textMetrics = ctx.measureText(settings.text);
          const textWidth = textMetrics.width;
          const textHeight = calculatedFontSize;

          const paddingPixels = (canvas.width * settings.padding) / 100;

          if (settings.repeat) {
            const spacing = textHeight * 3;
            for (let y = -spacing; y < canvas.height + spacing; y += spacing * 2) {
              for (let x = -spacing; x < canvas.width + spacing; x += spacing * 2) {
                ctx.save();
                ctx.translate(x + textWidth/2, y + textHeight/2);
                ctx.rotate(settings.rotation * Math.PI / 180);
                ctx.fillText(settings.text, -textWidth/2, textHeight/2);
                ctx.restore();
              }
            }
          } else {
            const pos = getPositionCoordinates(
              settings.position,
              canvas.width,
              canvas.height,
              textWidth,
              textHeight,
              paddingPixels
            );
            ctx.translate(pos.x + textWidth/2, pos.y + textHeight/2);
            ctx.rotate(settings.rotation * Math.PI / 180);
            ctx.fillText(settings.text, -textWidth/2, textHeight/2);
          }
          ctx.restore();
        } else if (watermarkType === 'image' && watermarkImage) {
          const watermarkImg = await createImageBitmap(watermarkImage);
          const aspectRatio = watermarkImg.width / watermarkImg.height;
          const watermarkWidth = (canvas.width * settings.size) / 100;
          const watermarkHeight = watermarkWidth / aspectRatio;

          ctx.save();
          ctx.globalAlpha = settings.opacity / 100;

          const paddingPixels = (canvas.width * settings.padding) / 100;

          if (settings.repeat) {
            const spacing = Math.max(watermarkWidth, watermarkHeight) * 1.5;
            for (let y = -spacing; y < canvas.height + spacing; y += spacing * 2) {
              for (let x = -spacing; x < canvas.width + spacing; x += spacing * 2) {
                ctx.save();
                ctx.translate(x + watermarkWidth/2, y + watermarkHeight/2);
                ctx.rotate(settings.rotation * Math.PI / 180);
                ctx.drawImage(watermarkImg, -watermarkWidth/2, -watermarkHeight/2, 
                            watermarkWidth, watermarkHeight);
                ctx.restore();
              }
            }
          } else {
            const pos = getPositionCoordinates(
              settings.position,
              canvas.width,
              canvas.height,
              watermarkWidth,
              watermarkHeight,
              paddingPixels
            );
            ctx.translate(pos.x + watermarkWidth/2, pos.y + watermarkHeight/2);
            ctx.rotate(settings.rotation * Math.PI / 180);
            ctx.drawImage(watermarkImg, -watermarkWidth/2, -watermarkHeight/2, 
                        watermarkWidth, watermarkHeight);
          }
          ctx.restore();
        }

        const dataUrl = canvas.toDataURL('image/jpeg', 0.92);
        setProgress(((index + 1) / selectedFiles.length) * 100);

        return {
          name: `${file.name.split('.')[0]}_watermarked.jpg`,
          dataUrl,
          originalSize: file.size,
          newSize: Math.round((dataUrl.length * 3) / 4),
          dimensions: `${canvas.width}×${canvas.height}`
        };
      }));

      setWatermarkedFiles(watermarked);
    } catch (error) {
      console.error('Watermark error:', error);
      setErrorMessage(`Watermarking failed: ${error.message}`);
    } finally {
      setIsProcessing(false);
    }
  };

  const downloadFile = useCallback((file) => {
    const link = document.createElement('a');
    link.href = file.dataUrl;
    link.download = file.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, []);

  return (
    <div className="max-w-4xl mx-auto px-4">
      <div className="text-center mb-8">
        <h1 className="text-3xl lg:text-4xl font-bold mb-2 text-white">
          Add Watermark
        </h1>
        <p className="text-sm text-gray-300">
          Add text or image watermarks to your photos
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
        <div>
          <h2 className="text-xl font-semibold text-white mb-4">
            Upload Images
          </h2>
          <div
            className="border-2 border-dashed border-gray-600 rounded-lg p-6 text-center cursor-pointer bg-gray-700 flex flex-col justify-center items-center transition-colors duration-200 hover:border-[#F7AA01]/50"
            onDrop={handleDrop}
            onDragOver={(e) => e.preventDefault()}
            onClick={() => document.getElementById('fileInput').click()}
          >
            <Upload size={48} className="text-gray-300 mb-4" />
            <p className="text-gray-300">Drag & Drop images here</p>
            <p className="text-gray-300">or</p>
            <button className="bg-[#F7AA01] text-[#2F2F30] px-4 py-2 rounded-lg mt-2 flex items-center gap-2 hover:bg-[#F7AA01]/80 transition-colors duration-200">
              <Upload size={20} />
              Select Images
            </button>
            <input
              id="fileInput"
              type="file"
              onChange={handleFileChange}
              accept="image/*"
              multiple
              className="hidden"
            />
          </div>
        </div>

        <div>
          <h2 className="text-xl font-semibold text-white mb-4">
            Watermark Type
          </h2>
          <div className="grid grid-cols-2 gap-4">
            <button
              onClick={() => setWatermarkType('text')}
              className={`p-4 rounded-lg flex flex-col items-center gap-2 ${
                watermarkType === 'text'
                  ? 'bg-[#F7AA01] text-[#2F2F30]'
                  : 'bg-gray-700 text-gray-300'
              } hover:bg-[#F7AA01] hover:text-[#2F2F30] transition-colors duration-200`}
            >
              <Type size={24} />
              <span>Text</span>
            </button>
            <button
              onClick={() => setWatermarkType('image')}
              className={`p-4 rounded-lg flex flex-col items-center gap-2 ${
                watermarkType === 'image'
                  ? 'bg-[#F7AA01] text-[#2F2F30]'
                  : 'bg-gray-700 text-gray-300'
              } hover:bg-[#F7AA01] hover:text-[#2F2F30] transition-colors duration-200`}
            >
              <ImageIcon size={24} />
              <span>Image</span>
            </button>
          </div>
        </div>
      </div>

      <div className="mb-8">
        <h2 className="text-xl font-semibold text-white mb-4">
          Watermark Settings
        </h2>
        <div className="bg-gray-700 p-6 rounded-lg space-y-6">
          {watermarkType === 'text' ? (
            <>
              <div>
                <label className="block text-white mb-2">Watermark Text</label>
                <input
                  type="text"
                  value={settings.text}
                  onChange={(e) => setSettings(prev => ({ ...prev, text: e.target.value }))}
                  className="w-full p-2 rounded-lg bg-gray-800 text-white border border-gray-600 focus:border-[#F7AA01] focus:ring-1 focus:ring-[#F7AA01] outline-none"
                  placeholder="Enter watermark text"
                />
              </div>

              <div>
                <label className="block text-white mb-2">Font</label>
                <select
                  value={settings.font}
                  onChange={(e) => setSettings(prev => ({ ...prev, font: e.target.value }))}
                  className="w-full p-2 rounded-lg bg-gray-800 text-white border border-gray-600 focus:border-[#F7AA01] focus:ring-1 focus:ring-[#F7AA01] outline-none"
                >
                  {fonts.map(font => (
                    <option key={font} value={font}>{font}</option>
                  ))}
                </select>
              </div>

              <div>
                <label className="block text-white mb-2">Color</label>
                <input
                  type="color"
                  value={settings.color}
                  onChange={(e) => setSettings(prev => ({ ...prev, color: e.target.value }))}
                  className="w-full h-10 rounded-lg cursor-pointer"
                />
              </div>
            </>
          ) : (
            <div>
              <label className="block text-white mb-2">Watermark Image</label>
              <div className="relative border-2 border-dashed border-gray-600 rounded-lg p-6 text-center cursor-pointer bg-gray-700 hover:border-[#F7AA01]/50">
                {watermarkImage ? (
                  <div className="flex flex-col items-center">
                    <img
                      src={URL.createObjectURL(watermarkImage)}
                      alt="Watermark"
                      className="max-h-32 object-contain mb-2"
                    />
                    <button
                      onClick={() => setWatermarkImage(null)}
                      className="text-red-500 hover:text-red-400"
                    >
                      Remove Image
                    </button>
                  </div>
                ) : (
                  <div
                    onClick={() => document.getElementById('watermarkInput').click()}
                    className="flex flex-col items-center"
                  >
                    <Upload size={48} className="text-gray-300 mb-4" />
                    <p className="text-gray-300">Click to upload watermark image</p>
                  </div>
                  )}
                  <input
                    id="watermarkInput"
                    type="file"
                    onChange={handleFileChange}
                    accept="image/*"
                    className="hidden"
                  />
                </div>
              </div>
            )}
  
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-white mb-2">Position</label>
                <select
                  value={settings.position}
                  onChange={(e) => setSettings(prev => ({ ...prev, position: e.target.value }))}
                  className="w-full p-2 rounded-lg bg-gray-800 text-white border border-gray-600 focus:border-[#F7AA01] focus:ring-1 focus:ring-[#F7AA01] outline-none"
                >
                  {positions.map(pos => (
                    <option key={pos.value} value={pos.value}>{pos.label}</option>
                  ))}
                </select>
              </div>
  
              <div>
                <label className="block text-white mb-2 flex items-center justify-between">
                  <span>Rotation</span>
                  <div className="flex items-center gap-2">
                    <RotateCcw 
                      size={16} 
                      className={`${settings.rotation <= 0 ? 'text-[#F7AA01]' : 'text-gray-400'}`}
                    />
                    <span>{settings.rotation}°</span>
                    <RotateCw 
                      size={16}
                      className={`${settings.rotation >= 0 ? 'text-[#F7AA01]' : 'text-gray-400'}`}
                    />
                  </div>
                </label>
                <input
                  type="range"
                  min="-180"
                  max="180"
                  value={settings.rotation}
                  onChange={(e) => setSettings(prev => ({ ...prev, rotation: parseInt(e.target.value) }))}
                  className="w-full"
                />
                <div className="flex justify-between text-sm text-gray-300">
                  <span>-180°</span>
                  <span>0°</span>
                  <span>180°</span>
                </div>
              </div>
            </div>
  
            <div>
              <label className="block text-white mb-2">Size (% of image width)</label>
              <input
                type="range"
                min="1"
                max="100"
                value={settings.size}
                onChange={(e) => setSettings(prev => ({ ...prev, size: parseInt(e.target.value) }))}
                className="w-full"
              />
              <div className="flex justify-between text-sm text-gray-300">
                <span>1%</span>
                <span>{settings.size}%</span>
                <span>100%</span>
              </div>
            </div>
  
            <div>
              <label className="block text-white mb-2">Opacity</label>
              <input
                type="range"
                min="1"
                max="100"
                value={settings.opacity}
                onChange={(e) => setSettings(prev => ({ ...prev, opacity: parseInt(e.target.value) }))}
                className="w-full"
              />
              <div className="flex justify-between text-sm text-gray-300">
                <span>Transparent</span>
                <span>{settings.opacity}%</span>
                <span>Solid</span>
              </div>
            </div>
  
            <div>
              <label className="block text-white mb-2">Padding</label>
              <input
                type="range"
                min="0"
                max="100"
                value={settings.padding}
                onChange={(e) => setSettings(prev => ({ ...prev, padding: parseInt(e.target.value) }))}
                className="w-full"
              />
              <div className="flex justify-between text-sm text-gray-300">
                <span>0%</span>
                <span>{settings.padding}%</span>
                <span>100%</span>
              </div>
            </div>
  
            <div className="flex items-center justify-between">
              <span className="text-white">Repeat Pattern</span>
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  checked={settings.repeat}
                  onChange={(e) => setSettings(prev => ({ ...prev, repeat: e.target.checked }))}
                  className="sr-only peer"
                />
                <div className="w-11 h-6 bg-gray-600 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-[#F7AA01]/50 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#F7AA01]"></div>
              </label>
            </div>
          </div>
        </div>
  
        <div className="mb-8 flex justify-center">
          <button
            onClick={applyWatermark}
            disabled={isProcessing || selectedFiles.length === 0 || (watermarkType === 'image' && !watermarkImage)}
            className={`bg-[#F7AA01] text-[#2F2F30] px-6 py-3 rounded-lg flex items-center gap-2 hover:bg-[#F7AA01]/80 transition-colors duration-200 ${
              (isProcessing || selectedFiles.length === 0 || (watermarkType === 'image' && !watermarkImage)) 
                ? 'opacity-50 cursor-not-allowed' 
                : ''
            }`}
          >
            {isProcessing ? (
              <>
                <RotateCw className="animate-spin" size={20} />
                Processing...
              </>
            ) : (
              <>
                <Stamp size={20} />
                Apply Watermark
              </>
            )}
          </button>
        </div>
  
        {progress > 0 && progress < 100 && (
          <div className="mb-8">
            <div className="relative w-full bg-gray-200 rounded-lg overflow-hidden h-4">
              <div
                className="absolute top-0 left-0 h-full bg-[#F7AA01] transition-all duration-300"
                style={{ width: `${progress}%` }}
              />
            </div>
            <p className="text-center mt-2 text-white">{progress}%</p>
          </div>
        )}
  
        {errorMessage && (
          <div className="mb-8 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded-lg flex items-center gap-2">
            <AlertTriangle size={20} />
            <span>{errorMessage}</span>
          </div>
        )}
  
        {watermarkedFiles.length > 0 && (
          <div className="mb-8">
            <h2 className="text-xl font-semibold text-white mb-4">
              Watermarked Files
            </h2>
            <div className="space-y-4">
              {watermarkedFiles.map((file, index) => (
                <div key={index} className="bg-gray-700 p-4 rounded-lg">
                  <div className="flex justify-between items-center mb-4">
                    <span className="truncate text-white">{file.name}</span>
                    <div className="flex items-center gap-3">
                      <button
                        onClick={() => downloadFile(file)}
                        className="text-[#F7AA01] hover:text-[#F7AA01]/80 transition-colors duration-200"
                        title="Download"
                      >
                        <Download size={20} />
                      </button>
                      <button
                        onClick={() => removeWatermarkedFile(index)}
                        className="text-red-500 hover:text-red-400 transition-colors duration-200"
                        title="Delete"
                      >
                        <Trash2 size={20} />
                      </button>
                    </div>
                  </div>
  
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                    <div className="relative">
                      <img
                        src={URL.createObjectURL(selectedFiles[index])}
                        alt="Original"
                        className="w-full h-48 object-contain rounded-lg bg-gray-800"
                      />
                      <span className="absolute bottom-2 left-2 bg-gray-900/80 text-white px-2 py-1 rounded text-sm">
                        Original
                      </span>
                    </div>
                    <div className="relative">
                      <img
                        src={file.dataUrl}
                        alt="Watermarked"
                        className="w-full h-48 object-contain rounded-lg bg-gray-800"
                      />
                      <span className="absolute bottom-2 left-2 bg-gray-900/80 text-white px-2 py-1 rounded text-sm">
                        Watermarked
                      </span>
                    </div>
                  </div>
  
                  <div className="grid grid-cols-2 md:grid-cols-3 gap-2 text-sm">
                    <div className="text-gray-300">
                      Size: {file.dimensions}
                    </div>
                    <div className="text-gray-300">
                      Original: {(file.originalSize / 1024).toFixed(1)} KB
                    </div>
                    <div className="text-gray-300">
                      New: {(file.newSize / 1024).toFixed(1)} KB
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        <Documentation tool="watermark" />
      </div>
    );
  };
  
  export default WatermarkImage;
