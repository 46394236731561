import React, { useState, useCallback, useEffect } from 'react';
import { 
  Upload, 
  Download, 
  Trash2,
  RotateCw, 
  AlertTriangle, 
  Eraser,
  Image as ImageIcon,
  Edit,
  Check,
  X,
  Settings,
  ChevronDown,
  Archive 
} from 'lucide-react';
import { removeBackground } from '@imgly/background-removal';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import Documentation from '../shared/Documentation';
import useTranslate from '../../hooks/useTranslate';
import Loading from '../../components/shared/Loading';

// Constants
const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB
const ACCEPTED_TYPES = ['image/jpeg', 'image/png', 'image/webp'];
const MAX_IMAGE_DIMENSION = 1500;

// Predefined backgrounds
const predefinedBackgrounds = [
  { 
    id: 'bg1', 
    name: 'Blue Gradient', 
    color: 'linear-gradient(to right, #2193b0, #6dd5ed)'
  },
  { 
    id: 'bg2', 
    name: 'Sunset', 
    color: 'linear-gradient(to right, #ff512f, #dd2476)'
  },
  { 
    id: 'bg3', 
    name: 'Forest Green', 
    color: '#2ecc71'
  },
  { 
    id: 'bg4', 
    name: 'Royal Purple', 
    color: '#9b59b6'
  },
  { 
    id: 'bg5', 
    name: 'Ocean Blue', 
    color: '#3498db'
  },
];

// Checkerboard pattern style
const checkerboardStyle = {
  backgroundImage: `
    linear-gradient(45deg, #808080 25%, transparent 25%),
    linear-gradient(-45deg, #808080 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, #808080 75%),
    linear-gradient(-45deg, transparent 75%, #808080 75%)
  `,
  backgroundSize: '20px 20px',
  backgroundPosition: '0 0, 0 10px, 10px -10px, -10px 0px'
};

// Settings Dropdown Component
const SettingsDropdown = ({ settings, setSettings, translations }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isOpen && !event.target.closest('.settings-dropdown')) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isOpen]);

  return (
    <div className="settings-dropdown relative ml-auto">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center gap-2 px-3 py-2 sm:px-4 sm:py-2 bg-gray-700 rounded-lg text-white hover:bg-gray-600 transition-colors duration-200 text-sm sm:text-base"
      >
        <Settings size={18} className="sm:w-5 sm:h-5" />
        <span className="hidden sm:inline">{translations.staticText.settingsTitle}</span>
        <ChevronDown size={14} className={`sm:w-4 sm:h-4 transition-transform duration-200 ${isOpen ? 'rotate-180' : ''}`} />
      </button>

      {isOpen && (
        <div className="absolute top-full right-0 mt-2 w-[280px] sm:w-64 bg-gray-700 rounded-lg shadow-lg p-4 z-50">
          <div className="space-y-4">
            <div>
              <label className="block text-white text-sm mb-2">
                {translations.staticText.qualityLabel}
              </label>
              <input
                type="range"
                min="0"
                max="100"
                value={settings.quality * 100}
                onChange={(e) => setSettings(prev => ({
                  ...prev,
                  quality: parseInt(e.target.value) / 100
                }))}
                className="w-full"
              />
              <div className="flex justify-between text-xs text-gray-300">
                <span>Lower Size</span>
                <span>{Math.round(settings.quality * 100)}%</span>
              </div>
            </div>
            <div className="space-y-3">
              <div className="flex items-center justify-between">
                <span className="text-white text-sm">
                  {translations.staticText.enhanceResultLabel}
                </span>
                <label className="relative inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    checked={settings.enhanceResult}
                    onChange={(e) => setSettings(prev => ({
                      ...prev,
                      enhanceResult: e.target.checked
                    }))}
                    className="sr-only peer"
                  />
                  <div className="w-11 h-6 bg-gray-600 rounded-full peer peer-checked:bg-[#F7AA01] peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all"></div>
                </label>
              </div>

              <div className="flex items-center justify-between">
                <span className="text-white text-sm">
                  {translations.staticText.preserveEdgesLabel}
                </span>
                <label className="relative inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    checked={settings.preserveEdges}
                    onChange={(e) => setSettings(prev => ({
                      ...prev,
                      preserveEdges: e.target.checked
                    }))}
                    className="sr-only peer"
                  />
                  <div className="w-11 h-6 bg-gray-600 rounded-full peer peer-checked:bg-[#F7AA01] peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all"></div>
                </label>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

// Main Component
const RemoveBackground = () => {
  const { translateText, currentLanguage } = useTranslate();
  const [translations, setTranslations] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [processedFiles, setProcessedFiles] = useState([]);
  const [progress, setProgress] = useState({
    total: 0,
    current: 0,
    file: ''
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);
  const [selectedBackground, setSelectedBackground] = useState(null);
  const [customBackground, setCustomBackground] = useState('#ffffff');
  const [customBackgroundFile, setCustomBackgroundFile] = useState(null);
  const [settings, setSettings] = useState({
    quality: 0.8,
    enhanceResult: true,
    preserveEdges: true
  });

  // Static text translations
  const staticText = {
    title: 'Remove Background',
    subtitle: 'Automatically remove image backgrounds with AI precision',
    dragDropText: 'Drag & Drop images here',
    orText: 'or',
    selectImagesButton: 'Select Images',
    selectedFilesTitle: 'Selected Files',
    processingText: 'Processing...',
    removeBackgroundButton: 'Remove Background',
    processedFilesTitle: 'Processed Files',
    originalLabel: 'Original',
    processedLabel: 'Processed',
    sizeLabel: 'Size',
    originalSizeLabel: 'Original',
    newSizeLabel: 'New',
    downloadTitle: 'Download',
    deleteTitle: 'Delete',
    editTitle: 'Edit Background',
    errorSelectFiles: 'Please select files to process',
    backgroundOptionsTitle: 'Background Options',
    customColorLabel: 'Custom Color',
    customImageLabel: 'Custom Image',
    applyBackground: 'Apply Background',
    cancelEdit: 'Cancel Edit',
    processingFile: 'Processing file',
    of: 'of',
    customBackgroundLabel: 'Upload Custom Background',
    settingsTitle: 'Settings',
    qualityLabel: 'Output Quality',
    enhanceResultLabel: 'Enhance Result',
    preserveEdgesLabel: 'Preserve Edges',
    clearAll: 'Clear All',
    downloadAll: 'Download All',
    errorFileSize: 'File size exceeds 10MB limit',
    errorFileType: 'Unsupported file type',
    processing: 'Processing Images',
    complete: 'Complete'
  };

  // Translation effect
  useEffect(() => {
    const translateContent = async () => {
      setIsLoading(true);
      try {
        const translatedStatic = {};
        for (const [key, value] of Object.entries(staticText)) {
          translatedStatic[key] = await translateText(value, currentLanguage);
        }
        setTranslations({ staticText: translatedStatic });
      } catch (error) {
        console.error('Translation error:', error);
        setTranslations({ staticText });
      }
      setIsLoading(false);
    };

    translateContent();
  }, [currentLanguage, translateText]);

  // File validation helper
  const validateFile = (file) => {
    if (file.size > MAX_FILE_SIZE) {
      throw new Error(translations.staticText.errorFileSize);
    }
    if (!ACCEPTED_TYPES.includes(file.type)) {
      throw new Error(translations.staticText.errorFileType);
    }
    return true;
  };

  // File handling functions
  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    try {
      files.forEach(validateFile);
      setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
      setErrorMessage('');
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const handleBackgroundFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        validateFile(file);
        setCustomBackgroundFile(file);
        setSelectedBackground('custom-image');
        setErrorMessage('');
      } catch (error) {
        setErrorMessage(error.message);
      }
    }
  };

  const handleDrop = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    const files = Array.from(event.dataTransfer.files);
    try {
      files.forEach(validateFile);
      setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
      setErrorMessage('');
    } catch (error) {
      setErrorMessage(error.message);
    }
  }, []);

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const removeFile = (index) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const removeProcessedFile = (index) => {
    setProcessedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  // Create processed file helper
  const createProcessedFile = async (blob, originalFile) => {
    const dataUrl = await new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });

    const img = new Image();
    await new Promise((resolve) => {
      img.onload = resolve;
      img.src = URL.createObjectURL(blob);
    });

    return {
      name: `${originalFile.name.split('.')[0]}_nobg.png`,
      dataUrl,
      dimensions: `${img.width}×${img.height}`,
      originalSize: originalFile.size,
      newSize: blob.size,
      hasCustomBackground: false
    };
  };

  // Background processing functions
  const applyBackground = async (processedImage, background) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();

    return new Promise((resolve) => {
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;

        if (background instanceof File) {
          const bgImg = new Image();
          bgImg.onload = () => {
            const scale = Math.max(canvas.width / bgImg.width, canvas.height / bgImg.height);
            const x = (canvas.width - bgImg.width * scale) / 2;
            const y = (canvas.height - bgImg.height * scale) / 2;
            
            ctx.drawImage(bgImg, x, y, bgImg.width * scale, bgImg.height * scale);
            ctx.drawImage(img, 0, 0);
            resolve(canvas.toDataURL('image/png', settings.quality));
          };
          bgImg.src = URL.createObjectURL(background);
          return;
        }

        const bgValue = String(background);
        if (bgValue.startsWith('#') || bgValue.startsWith('linear-gradient')) {
          if (bgValue.startsWith('linear-gradient')) {
            const gradient = ctx.createLinearGradient(0, 0, canvas.width, 0);
            const colors = bgValue.match(/#[a-fA-F0-9]{6}/g);
            colors?.forEach((color, index) => {
              gradient.addColorStop(index / (colors.length - 1), color);
            });
            ctx.fillStyle = gradient;
          } else {
            ctx.fillStyle = bgValue;
          }
          ctx.fillRect(0, 0, canvas.width, canvas.height);
        }

        ctx.drawImage(img, 0, 0);
        resolve(canvas.toDataURL('image/png', settings.quality));
      };
      img.src = processedImage;
    });
  };

  const handleEditBackground = async (index) => {
    if (!selectedBackground) return;

    const file = processedFiles[index];
    let background = selectedBackground;

    if (selectedBackground === 'custom-color') {
      background = customBackground;
    } else if (selectedBackground === 'custom-image') {
      if (!customBackgroundFile) return;
      background = customBackgroundFile;
    } else {
      const predefined = predefinedBackgrounds.find(bg => bg.id === selectedBackground);
      if (!predefined) return;
      background = predefined.color;
    }

    try {
      setIsProcessing(true);
      const originalProcessedImage = file.originalProcessedImage || file.dataUrl;
      const newDataUrl = await applyBackground(originalProcessedImage, background);
      
      const updatedFiles = [...processedFiles];
      updatedFiles[index] = {
        ...file,
        dataUrl: newDataUrl,
        originalProcessedImage: originalProcessedImage,
        hasCustomBackground: true,
        currentBackground: background
      };
      setProcessedFiles(updatedFiles);
      setEditingIndex(null);
      setErrorMessage('');
    } catch (error) {
      setErrorMessage(`Failed to apply background: ${error.message}`);
    } finally {
      setIsProcessing(false);
    }
  };

  // Process images function
  const processImages = async () => {
    if (selectedFiles.length === 0) {
      setErrorMessage(translations.staticText.errorSelectFiles);
      return;
    }

    setProgress({ total: selectedFiles.length, current: 0, file: '' });
    setErrorMessage('');
    setIsProcessing(true);

    try {
      for (let i = 0; i < selectedFiles.length; i++) {
        const file = selectedFiles[i];
        setProgress(prev => ({
          ...prev,
          current: i + 1,
          file: file.name
        }));

        const blob = await removeBackground(file, {
          model: settings.enhanceResult ? 'medium' : 'fast',
          refinementSteps: settings.preserveEdges ? 2 : 1,
        });

        const processedFile = await createProcessedFile(blob, file);
        setProcessedFiles(prev => [...prev, processedFile]);
      }
    } catch (error) {
      console.error('Processing error:', error);
      setErrorMessage(`Background removal failed: ${error.message}`);
    } finally {
      setIsProcessing(false);
    }
  };

  // Download functions
  const downloadFile = (file) => {
    const link = document.createElement('a');
    link.href = file.dataUrl;
    link.download = file.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadAll = async () => {
    if (processedFiles.length === 0) return;

    const zip = new JSZip();
    processedFiles.forEach((file) => {
      const base64Data = file.dataUrl.split(',')[1];
      zip.file(file.name, base64Data, { base64: true });
    });

    const content = await zip.generateAsync({ type: 'blob' });
    saveAs(content, 'processed_images.zip');
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <div className="max-w-4xl mx-auto px-4">
        {/* Header */}
        <div className="text-center mb-8">
          <h1 className="text-3xl lg:text-4xl font-bold mb-2 text-white">
            {translations.staticText.title}
          </h1>
          <p className="text-sm text-gray-300">
            {translations.staticText.subtitle}
          </p>
        </div>

        {/* Upload Area */}
        <div className="mb-8">
          <div className="flex items-center justify-end mb-4">
            <SettingsDropdown
              settings={settings}
              setSettings={setSettings}
              translations={translations}
            />
          </div>
          
          <div
            className="border-2 border-dashed border-gray-600 rounded-lg p-6 text-center cursor-pointer bg-gray-700 flex flex-col justify-center items-center transition-colors duration-200 hover:border-[#F7AA01]/50"
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onClick={() => document.getElementById('fileInput').click()}
          >
            <Upload size={48} className="text-gray-300 mb-4" />
            <p className="text-gray-300">{translations.staticText.dragDropText}</p>
            <p className="text-gray-300">{translations.staticText.orText}</p>
            <button className="bg-[#F7AA01] text-[#2F2F30] px-4 py-2 rounded-lg mt-2 flex items-center gap-2 hover:bg-[#F7AA01]/80 transition-colors duration-200">
              <Upload size={20} />
              {translations.staticText.selectImagesButton}
            </button>
            <input
              id="fileInput"
              type="file"
              onChange={handleFileChange}
              accept="image/jpeg,image/png,image/webp"
              multiple
              className="hidden"
            />
          </div>
        </div>

        {/* Selected Files List */}
        {selectedFiles.length > 0 && (
          <div className="mb-8">
            <div className="flex items-center justify-between mb-4">
              <h2 className="text-xl font-semibold text-white">
                {translations.staticText.selectedFilesTitle}
              </h2>
              <span className="text-gray-300 text-sm">
                {selectedFiles.length} file(s)
              </span>
            </div>
            <div className="space-y-2">
              {selectedFiles.map((file, index) => (
                <div key={index} className="flex justify-between items-center bg-gray-700 p-3 rounded-lg">
                  <div className="flex items-center gap-3 min-w-0 flex-1">
                    <ImageIcon className="text-gray-400 flex-shrink-0" size={20} />
                    <span className="truncate text-white">{file.name}</span>
                  </div>
                  <div className="flex items-center gap-3 flex-shrink-0 ml-3">
                    <span className="text-gray-300 text-sm whitespace-nowrap">
                      {(file.size / 1024).toFixed(1)} KB
                    </span>
                    <button
                      onClick={() => removeFile(index)}
                      className="text-red-500 hover:text-red-400 transition-colors duration-200"
                      title={translations.staticText.deleteTitle}
                    >
                      <Trash2 size={20} />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Process Button */}
        <div className="mb-8 flex justify-center">
          <button
            onClick={processImages}
            disabled={isProcessing || selectedFiles.length === 0}
            className={`bg-[#F7AA01] text-[#2F2F30] px-6 py-3 rounded-lg flex items-center gap-2 hover:bg-[#F7AA01]/80 transition-colors duration-200 ${
              (isProcessing || selectedFiles.length === 0) ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
            {isProcessing ? (
              <>
                <RotateCw className="animate-spin" size={20} />
                {translations.staticText.processingText}
              </>
            ) : (
              <>
                <Eraser size={20} />
                {translations.staticText.removeBackgroundButton}
              </>
            )}
          </button>
        </div>

        {/* Simple Progress Display */}
        {isProcessing && (
          <div className="mb-6 text-center">
            <p className="text-gray-300">
              {translations.staticText.processingFile} {progress.current} {translations.staticText.of} {progress.total}
            </p>
          </div>
        )}

        {/* Error Message */}
        {errorMessage && (
          <div className="mb-8 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded-lg flex items-center gap-2">
            <AlertTriangle size={20} />
            <span>{errorMessage}</span>
          </div>
        )}

        {/* Processed Files */}
        {processedFiles.length > 0 && (
          <div className="mb-8">
            <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between mb-4 gap-3">
              <h2 className="text-xl font-semibold text-white">
                {translations.staticText.processedFilesTitle}
              </h2>
              <div className="flex flex-col sm:flex-row gap-2 w-full sm:w-auto">
                <button
                  onClick={downloadAll}
                  className="w-full sm:w-auto px-4 py-2 rounded-lg bg-[#F7AA01] text-[#2F2F30] flex items-center justify-center gap-2 hover:bg-[#F7AA01]/80 transition-colors duration-200"
                >
                  <Archive size={20} />
                  {translations.staticText.downloadAll}
                </button>
                <button
                  onClick={() => {
                    setProcessedFiles([]);
                    setSelectedFiles([]);
                    setProgress({ total: 0, current: 0, file: '' });
                    setErrorMessage('');
                  }}
                  className="w-full sm:w-auto px-4 py-2 rounded-lg bg-red-500 text-white flex items-center justify-center gap-2 hover:bg-red-600 transition-colors duration-200"
                >
                  <Trash2 size={20} />
                  {translations.staticText.clearAll}
                </button>
              </div>
            </div>

            {/* Processed Files Grid */}
            <div className="space-y-4">
              {processedFiles.map((file, index) => (
                <div key={index} className="bg-gray-700 p-4 rounded-lg">
                  <div className="flex justify-between items-center mb-4">
                    <span className="truncate text-white flex-1">{file.name}</span>
                    <div className="flex items-center gap-3 flex-shrink-0">
                      <button
                        onClick={() => setEditingIndex(index)}
                        className="text-blue-400 hover:text-blue-300 transition-colors duration-200"
                        title={translations.staticText.editTitle}
                      >
                        <Edit size={20} />
                      </button>
                      <button
                        onClick={() => downloadFile(file)}
                        className="text-[#F7AA01] hover:text-[#F7AA01]/80 transition-colors duration-200"
                        title={translations.staticText.downloadTitle}
                      >
                        <Download size={20} />
                      </button>
                      <button
                        onClick={() => removeProcessedFile(index)}
                        className="text-red-500 hover:text-red-400 transition-colors duration-200"
                        title={translations.staticText.deleteTitle}
                      >
                        <Trash2 size={20} />
                      </button>
                    </div>
                  </div>

                  {editingIndex === index ? (
                    <div className="mb-4 bg-gray-800 p-4 rounded-lg">
                      <h3 className="text-white font-semibold mb-3">
                        {translations.staticText.backgroundOptionsTitle}
                      </h3>
                      
                      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 gap-4 mb-4">
                        {predefinedBackgrounds.map(bg => (
                          <button
                            key={bg.id}
                            onClick={() => setSelectedBackground(bg.id)}
                            className={`h-12 rounded-lg transition-all duration-200 ${
                              selectedBackground === bg.id ? 'ring-2 ring-[#F7AA01]' : ''
                            }`}
                            style={{ background: bg.color }}
                            title={bg.name}
                          />
                        ))}
                      </div>

                      <div className="mb-4">
                        <label className="block text-white mb-2">
                          {translations.staticText.customColorLabel}
                        </label>
                        <div className="flex gap-2">
                          <input
                            type="color"
                            value={customBackground}
                            onChange={(e) => setCustomBackground(e.target.value)}
                            className="w-12 h-8"
                          />
                          <button
                            onClick={() => setSelectedBackground('custom-color')}
                            className={`px-4 py-1 rounded bg-gray-600 text-white ${
                              selectedBackground === 'custom-color' ? 'ring-2 ring-[#F7AA01]' : ''
                            }`}
                          >
                            Select
                          </button>
                        </div>
                      </div>

                      <div className="mb-4">
                        <label className="block text-white mb-2">
                          {translations.staticText.customBackgroundLabel}
                        </label>
                        <div className="flex gap-2">
                          <input
                            type="file"
                            accept="image/*"
                            onChange={handleBackgroundFileChange}
                            className="hidden"
                            id={`bgFileInput-${index}`}
                          />
                          <button
                            onClick={() => document.getElementById(`bgFileInput-${index}`).click()}
                            className={`flex items-center gap-2 px-4 py-2 rounded bg-gray-600 text-white ${
                              selectedBackground === 'custom-image' ? 'ring-2 ring-[#F7AA01]' : ''
                            }`}
                          >
                            <ImageIcon size={16} />
                            {customBackgroundFile ? customBackgroundFile.name : translations.staticText.customImageLabel}
                          </button>
                        </div>
                      </div>

                      <div className="flex justify-end gap-2">
                        <button
                          onClick={() => setEditingIndex(null)}
                          className="px-4 py-2 rounded-lg bg-gray-600 text-white flex items-center gap-2"
                        >
                          <X size={16} />
                          {translations.staticText.cancelEdit}
                        </button>
                        <button
                          onClick={() => handleEditBackground(index)}
                          className="px-4 py-2 rounded-lg bg-[#F7AA01] text-[#2F2F30] flex items-center gap-2"
                          disabled={!selectedBackground}
                        >
                          <Check size={16} />
                          {translations.staticText.applyBackground}
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="mb-4 grid grid-cols-1 sm:grid-cols-2 gap-4">
                      <div className="relative aspect-video">
                        <img 
                          src={selectedFiles[index] ? URL.createObjectURL(selectedFiles[index]) : ''} 
                          alt="Original" 
                          className="w-full h-full object-contain rounded-lg bg-gray-800"
                        />
                        <span className="absolute bottom-2 left-2 bg-gray-900/80 text-white px-2 py-1 rounded text-sm">
                          {translations.staticText.originalLabel}
                        </span>
                      </div> 
                      <div className="relative aspect-video">
                        <div className="w-full h-full rounded-lg overflow-hidden" 
                             style={file.hasCustomBackground ? {} : checkerboardStyle}>
                          <img 
                            src={file.dataUrl} 
                            alt="Processed" 
                            className="w-full h-full object-contain"
                          />
                        </div>
                        <span className="absolute bottom-2 left-2 bg-gray-900/80 text-white px-2 py-1 rounded text-sm">
                          {translations.staticText.processedLabel}
                        </span>
                      </div>
                    </div>
                  )}

                  <div className="grid grid-cols-1 sm:grid-cols-3 gap-2 text-sm">
                    <div className="text-gray-300">
                      {translations.staticText.sizeLabel}: {file.dimensions}
                    </div>
                    <div className="text-gray-300">
                      {translations.staticText.originalSizeLabel}: {(file.originalSize / 1024).toFixed(1)} KB
                    </div>
                    <div className="text-gray-300">
                      {translations.staticText.newSizeLabel}: {(file.newSize / 1024).toFixed(1)} KB
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      <Documentation tool="removebg" />
    </>
  );
};

export default RemoveBackground;
