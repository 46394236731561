import React from 'react';
import { Loader } from 'lucide-react';

const Loading = () => {
  return (
    <div className="flex items-center justify-center p-4">
      <Loader className="w-6 h-6 text-[#F7AA01] animate-spin" />
    </div>
  );
};

export default Loading;