import React from 'react';
import { Link } from 'react-router-dom';
import { Home, ArrowLeft } from 'lucide-react';

const NotFound = () => {
 return (
   <div className="min-h-screen flex items-center justify-center">
     <div className="text-center px-4">
       {/* 404 Image/Icon */}
       <div className="mb-8 flex justify-center">
         <div className="relative">
           <h1 className="text-[150px] font-bold text-[#F7AA01] leading-none">
             404
           </h1>
           <div className="absolute -bottom-8 w-full text-center">
             <span className="text-white text-xl font-medium px-4 py-2 bg-gray-800 rounded-full">
               Page Not Found
             </span>
           </div>
         </div>
       </div>

       {/* Description */}
       <div className="max-w-md mx-auto mb-8">
         <p className="text-gray-300 text-lg mb-4">
           Oops! The page you're looking for doesn't exist or has been moved.
         </p>
         <p className="text-gray-400">
           Please check the URL or try using the navigation menu.
         </p>
       </div>

       {/* Action Buttons */}
       <div className="flex flex-col sm:flex-row gap-4 justify-center">
         <Link
           to="/"
           className="inline-flex items-center justify-center gap-2 px-6 py-3 bg-[#F7AA01] text-[#2F2F30] rounded-lg font-medium hover:bg-[#F7AA01]/80 transition-colors duration-200"
         >
           <Home size={20} />
           Back to Home
         </Link>
         <button
           onClick={() => window.history.back()}
           className="inline-flex items-center justify-center gap-2 px-6 py-3 bg-gray-700 text-white rounded-lg font-medium hover:bg-gray-600 transition-colors duration-200"
         >
           <ArrowLeft size={20} />
           Go Back
         </button>
       </div>

       {/* Additional Help */}
       <div className="mt-12 text-gray-400">
         <p>Need assistance? Visit our <Link to="/contact" className="text-[#F7AA01] hover:underline">help center</Link></p>
       </div>

       {/* Search Box (Optional) */}
       <div className="mt-8 max-w-md mx-auto">
         <div className="relative">
           <input
             type="text"
             placeholder="Search our tools..."
             className="w-full px-4 py-3 bg-gray-700 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-[#F7AA01] placeholder-gray-400"
           />
           <button className="absolute right-3 top-1/2 -translate-y-1/2 px-4 py-1 bg-[#F7AA01] text-[#2F2F30] rounded-md hover:bg-[#F7AA01]/80 transition-colors duration-200">
             Search
           </button>
         </div>
       </div>

       {/* Quick Links */}
       <div className="mt-12 grid grid-cols-2 sm:grid-cols-4 gap-4 max-w-2xl mx-auto">
         {[
           { name: 'Convert Image', path: '/convert' },
           { name: 'Compress Image', path: '/compress' },
           { name: 'Resize Image', path: '/resize' },
           { name: 'Remove Background', path: '/remove-background' }
         ].map((link) => (
           <Link
             key={link.path}
             to={link.path}
             className="p-4 bg-gray-800 rounded-lg text-gray-300 hover:bg-gray-700 hover:text-[#F7AA01] transition-colors duration-200"
           >
             {link.name}
           </Link>
         ))}
       </div>
     </div>
   </div>
 );
};

// Error Boundary
export class ErrorBoundary extends React.Component {
 constructor(props) {
   super(props);
   this.state = { hasError: false };
 }

 static getDerivedStateFromError(error) {
   return { hasError: true };
 }

 componentDidCatch(error, errorInfo) {
   // You can log the error to an error reporting service here
   console.error('NotFound Error:', error, errorInfo);
 }

 render() {
   if (this.state.hasError) {
     return <NotFound />;
   }

   return this.props.children;
 }
}

export default NotFound;