import React, { createContext, useState, useContext } from 'react';
import { translateText } from '../utils/translate';
import { supportedLanguages } from '../config/languages';

const TranslationContext = createContext();

export const TranslationProvider = ({ children }) => {
  const [language, setLanguage] = useState(() => {
    const savedLang = localStorage.getItem('preferredLanguage') || 'en';
    // Validasi bahasa yang tersimpan ada dalam daftar yang didukung
    return supportedLanguages.find(lang => lang.code === savedLang) ? savedLang : 'en';
  });

  const t = async (text) => {
    if (language === 'en') return text;
    return translateText(text, language);
  };

  return (
    <TranslationContext.Provider value={{ 
      language, 
      setLanguage,
      t,
      supportedLanguages 
    }}>
      {children}
    </TranslationContext.Provider>
  );
};

export const useTranslation = () => {
  const context = useContext(TranslationContext);
  if (!context) {
    throw new Error('useTranslation must be used within a TranslationProvider');
  }
  return context;
};