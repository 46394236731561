import React, { useState, useCallback, useEffect } from 'react';
import { 
  Upload, 
  Download, 
  Trash2, 
  Settings, 
  RotateCw, 
  AlertTriangle, 
  Lock, 
  Unlock, 
  Maximize2,
  EyeIcon,
  X as CloseIcon
} from 'lucide-react';
import Documentation from '../shared/Documentation';
import useTranslate from '../../hooks/useTranslate';
import Loading from '../../components/shared/Loading';

// CSS for aspect ratio support
const styles = `
.aspect-w-16 {
  position: relative;
  padding-bottom: 75%;
}
.aspect-h-12 {
  position: relative;
}
.aspect-h-9 {
  position: relative;
}
@media (min-width: 640px) {
  .aspect-w-16 {
    padding-bottom: 56.25%;
  }
}
`;

const ImageResize = () => {
  const { translateText, currentLanguage } = useTranslate();
  const [translations, setTranslations] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [resizedFiles, setResizedFiles] = useState([]);
  const [dimensions, setDimensions] = useState({ width: '', height: '' });
  const [maintainAspectRatio, setMaintainAspectRatio] = useState(true);
  const [resizeMethod, setResizeMethod] = useState('pixels');
  const [percentage, setPercentage] = useState(100);
  const [selectedPreset, setSelectedPreset] = useState('');
  const [progress, setProgress] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [originalDimensions, setOriginalDimensions] = useState(null);

  // Preview state
  const [previewData, setPreviewData] = useState({
    original: null,
    resized: null,
    showPreview: false
  });

  // Image loading state
  const [imageLoaded, setImageLoaded] = useState({
    original: false,
    resized: false
  });

  // Static text for translations
  const staticText = {
    title: 'Resize Images',
    subtitle: 'Change image dimensions while maintaining quality',
    dragDropText: 'Drag & Drop images here',
    orText: 'or',
    selectImagesButton: 'Select Images',
    selectedFilesTitle: 'Selected Files',
    resizeMethodTitle: 'Resize Method',
    aspectRatioLocked: 'Aspect Ratio Locked',
    freeResize: 'Free Resize',
    pixels: 'pixels',
    percentage: 'percentage',
    preset: 'preset',
    widthLabel: 'Width (px)',
    heightLabel: 'Height (px)',
    scalePercentage: 'Scale percentage',
    newSize: 'New size',
    pixelsText: 'pixels',
    processingText: 'Processing...',
    resizeImagesButton: 'Resize Images',
    resizedFilesTitle: 'Resized Files',
    downloadTitle: 'Download',
    deleteTitle: 'Delete',
    originalSize: 'Original size',
    newSizeLabel: 'New size',
    fileSize: 'File size',
    errorSelectFiles: 'Please select files to resize',
    errorSpecifyDimensions: 'Please specify both width and height',
    previewButton: 'Preview',
    previewTitle: 'Image Preview',
    originalImage: 'Original Image',
    resizedPreview: 'Resized Preview',
    loadingText: 'Loading...',
    previewError: 'Failed to generate preview'
  };

  // Presets configuration
  const presetConfigs = [
    { id: 'instagram_post', width: 1080, height: 1080 },
    { id: 'instagram_story', width: 1080, height: 1920 },
    { id: 'facebook_cover', width: 851, height: 315 },
    { id: 'twitter_header', width: 1500, height: 500 },
    { id: 'youtube_thumbnail', width: 1280, height: 720 },
    { id: 'linkedin_cover', width: 1584, height: 396 }
  ];

  // Inject aspect ratio styles
  useEffect(() => {
    const styleSheet = document.createElement('style');
    styleSheet.textContent = styles;
    document.head.appendChild(styleSheet);
    return () => {
      document.head.removeChild(styleSheet);
    };
  }, []);
  // Translation effect
  useEffect(() => {
    const translateContent = async () => {
      setIsLoading(true);
      try {
        // Translate static text
        const translatedStatic = {};
        for (const [key, value] of Object.entries(staticText)) {
          translatedStatic[key] = await translateText(value, currentLanguage);
        }

        // Translate preset names
        const translatedPresets = await Promise.all(
          presetConfigs.map(async (preset) => ({
            ...preset,
            name: await translateText(preset.id.replace('_', ' ').toUpperCase(), currentLanguage)
          }))
        );

        setTranslations({
          staticText: translatedStatic,
          presets: translatedPresets
        });
      } catch (error) {
        console.error('Translation error:', error);
        // Fallback to English
        setTranslations({
          staticText,
          presets: presetConfigs.map(preset => ({
            ...preset,
            name: preset.id.replace('_', ' ').toUpperCase()
          }))
        });
      }
      setIsLoading(false);
    };

    translateContent();
  }, [currentLanguage, translateText]);

  // Cleanup object URLs on unmount or when preview changes
  useEffect(() => {
    return () => {
      if (previewData.original) URL.revokeObjectURL(previewData.original);
      if (previewData.resized) URL.revokeObjectURL(previewData.resized);
    };
  }, [previewData]);

  // Generate preview with proper image loading
  const generatePreview = async () => {
    if (!selectedFiles[0] || !dimensions.width || !dimensions.height) return;

    try {
      const file = selectedFiles[0];
      
      // Create original preview
      const originalUrl = URL.createObjectURL(file);
      
      // Create a promise to handle image loading
      const loadImage = () => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.onload = () => resolve(img);
          img.onerror = reject;
          img.src = originalUrl;
        });
      };

      // Reset loading states before starting new preview generation
      setImageLoaded({ original: false, resized: false });

      const img = await loadImage();
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      // Set canvas dimensions
      canvas.width = parseInt(dimensions.width);
      canvas.height = parseInt(dimensions.height);

      // Use high quality settings
      ctx.imageSmoothingEnabled = true;
      ctx.imageSmoothingQuality = 'high';

      // Draw resized image
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

      // Get preview URL with explicit quality setting
      const resizedUrl = canvas.toDataURL('image/png', 1.0);

      // Update preview state
      setPreviewData(prev => {
        // Cleanup old URLs if they exist
        if (prev.original) URL.revokeObjectURL(prev.original);
        if (prev.resized && prev.resized.startsWith('blob:')) URL.revokeObjectURL(prev.resized);
        
        return {
          original: originalUrl,
          resized: resizedUrl,
          showPreview: true
        };
      });
      
    } catch (error) {
      console.error('Preview generation failed:', error);
      setErrorMessage(translations.staticText.previewError);
    }
  };

  // Update preview when dimensions change
  useEffect(() => {
    if (previewData.showPreview) {
      generatePreview();
    }
  }, [dimensions.width, dimensions.height]);

  const handleFileChange = async (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles(files);
    
    if (files.length > 0) {
      try {
        const img = await createImageBitmap(files[0]);
        setOriginalDimensions({ width: img.width, height: img.height });
        setDimensions({ width: img.width, height: img.height });
        
        // Reset loading states for new file
        setImageLoaded({ original: false, resized: false });
        setErrorMessage('');
      } catch (error) {
        console.error('Error loading image:', error);
        setErrorMessage('Failed to load image. Please try another file.');
      }
    }
  };

  const handleDrop = useCallback(async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const files = Array.from(event.dataTransfer.files);
    setSelectedFiles(files);
    
    if (files.length > 0) {
      try {
        const img = await createImageBitmap(files[0]);
        setOriginalDimensions({ width: img.width, height: img.height });
        setDimensions({ width: img.width, height: img.height });
        
        // Reset loading states for new file
        setImageLoaded({ original: false, resized: false });
        setErrorMessage('');
      } catch (error) {
        console.error('Error loading image:', error);
        setErrorMessage('Failed to load image. Please try another file.');
      }
    }
  }, []);

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  const removeFile = (index) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    if (selectedFiles.length === 1) {
      setOriginalDimensions(null);
      setDimensions({ width: '', height: '' });
      setPreviewData({ original: null, resized: null, showPreview: false });
      setImageLoaded({ original: false, resized: false });
      setErrorMessage('');
    }
  };

  const removeResizedFile = (index) => {
    setResizedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleDimensionChange = (dimension, value) => {
    const numValue = parseInt(value) || '';
    
    if (maintainAspectRatio && originalDimensions && numValue) {
      const aspectRatio = originalDimensions.width / originalDimensions.height;
      if (dimension === 'width') {
        setDimensions({
          width: numValue,
          height: Math.round(numValue / aspectRatio)
        });
      } else {
        setDimensions({
          width: Math.round(numValue * aspectRatio),
          height: numValue
        });
      }
    } else {
      setDimensions(prev => ({
        ...prev,
        [dimension]: numValue
      }));
    }
  };

  const handlePercentageChange = (value) => {
    const newPercentage = Math.max(1, Math.min(500, parseInt(value) || 0));
    setPercentage(newPercentage);
    
    if (originalDimensions) {
      setDimensions({
        width: Math.round(originalDimensions.width * (newPercentage / 100)),
        height: Math.round(originalDimensions.height * (newPercentage / 100))
      });
    }
  };

  const handlePresetSelect = (preset) => {
    setSelectedPreset(preset.id);
    setDimensions({
      width: preset.width,
      height: preset.height
    });
  };

  const resizeImages = async () => {
    if (selectedFiles.length === 0) {
      setErrorMessage(translations.staticText.errorSelectFiles);
      return;
    }

    if (!dimensions.width || !dimensions.height) {
      setErrorMessage(translations.staticText.errorSpecifyDimensions);
      return;
    }

    setProgress(0);
    setErrorMessage('');
    setIsProcessing(true);

    try {
      const resized = await Promise.all(
        selectedFiles.map(async (file, index) => {
          const img = await createImageBitmap(file);
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          canvas.width = parseInt(dimensions.width);
          canvas.height = parseInt(dimensions.height);
          
          // Set high quality
          ctx.imageSmoothingEnabled = true;
          ctx.imageSmoothingQuality = 'high';
          
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

          const dataUrl = canvas.toDataURL('image/png', 1.0);
          
          // Update progress
          setProgress(((index + 1) / selectedFiles.length) * 100);
          
          return {
            name: `${file.name.split('.')[0]}_${canvas.width}x${canvas.height}.png`,
            dataUrl,
            originalSize: `${img.width}x${img.height}`,
            newSize: `${canvas.width}x${canvas.height}`,
            originalFileSize: file.size,
            newFileSize: Math.round((dataUrl.length * 3) / 4)
          };
        })
      );

      setResizedFiles(resized);
      setProgress(100);
    } catch (error) {
      console.error('Resize failed:', error);
      setErrorMessage(`Resize failed: ${error.message}`);
    } finally {
      setIsProcessing(false);
    }
  };

  const downloadFile = (file) => {
    const link = document.createElement('a');
    link.href = file.dataUrl;
    link.download = file.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Preview Modal Component with improved loading and responsiveness
  const PreviewModal = () => {
    const handleImageLoad = (type) => {
      setImageLoaded(prev => ({ ...prev, [type]: true }));
    };
  
    const handleImageError = (type) => {
      console.error(`Failed to load ${type} image`);
      setImageLoaded(prev => ({ ...prev, [type]: false }));
      setErrorMessage(translations.staticText.previewError);
    };
  
    // Lock body scroll when modal opens
    useEffect(() => {
      document.body.style.overflow = 'hidden';
      return () => {
        document.body.style.overflow = 'unset';
      };
    }, []);
  
    return (
      <div className="fixed inset-0 z-50 bg-black/75">
        {/* Main scrollable container */}
        <div className="h-full overflow-y-auto" style={{ overflowY: 'scroll' }}>
          <div className="min-h-full p-4">
            <div className="bg-gray-800 w-[95%] max-w-4xl mx-auto rounded-lg">
              {/* Header */}
              <div className="border-b border-gray-700 px-4 py-3 flex items-center justify-between">
                <h3 className="text-lg font-semibold text-white">
                  {translations.staticText.previewTitle}
                </h3>
                <button 
                  onClick={() => setPreviewData(prev => ({ ...prev, showPreview: false }))}
                  className="text-gray-400 hover:text-white p-2"
                >
                  <CloseIcon size={20} />
                </button>
              </div>
  
              {/* Content */}
              <div className="p-4">
                {/* Grid container - 1 column on mobile, 2 columns on desktop */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  {/* Original Image */}
                  <div>
                    <p className="text-gray-300 mb-2 font-medium">
                      {translations.staticText.originalImage}
                    </p>
                    <div className="bg-gray-900 border border-gray-700 rounded-lg p-2">
                      <div className="flex justify-center min-h-[200px]">
                        {!imageLoaded.original && (
                          <div className="flex items-center justify-center text-gray-400">
                            <RotateCw className="animate-spin" size={20} />
                            <span className="ml-2">{translations.staticText.loadingText}</span>
                          </div>
                        )}
                        {previewData.original && (
                          <img 
                            src={previewData.original} 
                            alt="Original"
                            className="max-w-full max-h-[60vh] object-contain"
                            onLoad={() => handleImageLoad('original')}
                            onError={() => handleImageError('original')}
                          />
                        )}
                      </div>
                    </div>
                    <p className="text-sm text-gray-400 mt-2">
                      {originalDimensions?.width}×{originalDimensions?.height} pixels
                    </p>
                  </div>
  
                  {/* Resized Preview */}
                  <div>
                    <p className="text-gray-300 mb-2 font-medium">
                      {translations.staticText.resizedPreview}
                    </p>
                    <div className="bg-gray-900 border border-gray-700 rounded-lg p-2">
                      <div className="flex justify-center min-h-[200px]">
                        {!imageLoaded.resized && (
                          <div className="flex items-center justify-center text-gray-400">
                            <RotateCw className="animate-spin" size={20} />
                            <span className="ml-2">{translations.staticText.loadingText}</span>
                          </div>
                        )}
                        {previewData.resized && (
                          <img 
                            src={previewData.resized} 
                            alt="Resized Preview"
                            className="max-w-full max-h-[60vh] object-contain"
                            onLoad={() => handleImageLoad('resized')}
                            onError={() => handleImageError('resized')}
                          />
                        )}
                      </div>
                    </div>
                    <p className="text-sm text-gray-400 mt-2">
                      {dimensions.width}×{dimensions.height} pixels
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <div className="max-w-4xl mx-auto px-4">
        {/* Title Section */}
        <div className="text-center mb-8">
          <h1 className="text-3xl lg:text-4xl font-bold mb-2 text-white">
            {translations.staticText.title}
          </h1>
          <p className="text-sm text-gray-300">
            {translations.staticText.subtitle}
          </p>
        </div>

        {/* Upload Section */}
        <div className="mb-8">
          <div
            className="border-2 border-dashed border-gray-600 rounded-lg p-6 text-center cursor-pointer bg-gray-700 flex flex-col justify-center items-center transition-colors duration-200 hover:border-[#F7AA01]/50"
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onClick={() => document.getElementById('fileInput').click()}
          >
            <Upload size={48} className="text-gray-300 mb-4" />
            <p className="text-gray-300">{translations.staticText.dragDropText}</p>
            <p className="text-gray-300">{translations.staticText.orText}</p>
            <button className="bg-[#F7AA01] text-[#2F2F30] px-4 py-2 rounded-lg mt-2 flex items-center gap-2 hover:bg-[#F7AA01]/80 transition-colors duration-200">
              <Upload size={20} />
              {translations.staticText.selectImagesButton}
            </button>
            <input
              id="fileInput"
              type="file"
              onChange={handleFileChange}
              accept="image/*"
              multiple
              className="hidden"
            />
          </div>
        </div>

        {/* Selected Files Section */}
        {selectedFiles.length > 0 && (
          <div className="mb-8">
            <h2 className="text-xl font-semibold text-white mb-4">
              {translations.staticText.selectedFilesTitle}
            </h2>
            <div className="space-y-2">
              {selectedFiles.map((file, index) => (
                <div key={index} className="flex justify-between items-center bg-gray-700 p-3 rounded-lg">
                  <span className="truncate text-white">{file.name}</span>
                  <div className="flex items-center gap-3">
                    <span className="text-gray-300 text-sm">
                      {(file.size / 1024).toFixed(1)} KB
                    </span>
                    <button
                      onClick={() => removeFile(index)}
                      className="text-red-500 hover:text-red-400 transition-colors duration-200"
                      title={translations.staticText.deleteTitle}
                    >
                      <Trash2 size={20} />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Resize Method Section */}
        <div className="mb-8">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-semibold text-white">
              {translations.staticText.resizeMethodTitle}
            </h2>
            <button
              onClick={() => setMaintainAspectRatio(!maintainAspectRatio)}
              className="text-[#F7AA01] hover:text-[#F7AA01]/80 transition-colors duration-200 flex items-center gap-2"
            >
              {maintainAspectRatio ? <Lock size={20} /> : <Unlock size={20} />}
              {maintainAspectRatio 
                ? translations.staticText.aspectRatioLocked 
                : translations.staticText.freeResize}
            </button>
          </div>

          <div className="grid grid-cols-3 gap-4 mb-6">
            {['pixels', 'percentage', 'preset'].map((method) => (
              <button
                key={method}
                onClick={() => setResizeMethod(method)}
                className={`p-4 rounded-lg flex flex-col items-center gap-2 ${
                  resizeMethod === method
                    ? 'bg-[#F7AA01] text-[#2F2F30]'
                    : 'bg-gray-700 text-gray-300'
                } hover:bg-[#F7AA01] hover:text-[#2F2F30] transition-colors duration-200`}
              >
                <Maximize2 size={24} />
                <span className="capitalize">{translations.staticText[method]}</span>
              </button>
            ))}
          </div>

          {/* Pixels Input */}
          {resizeMethod === 'pixels' && (
            <div className="bg-gray-700 p-4 rounded-lg">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-300 mb-1">
                    {translations.staticText.widthLabel}
                  </label>
                  <input
                    type="number"
                    value={dimensions.width}
                    onChange={(e) => handleDimensionChange('width', e.target.value)}
                    className="w-full p-2 rounded-lg bg-gray-800 text-white border border-gray-600 focus:border-[#F7AA01] focus:ring-1 focus:ring-[#F7AA01] outline-none"
                    placeholder={translations.staticText.widthLabel}
                    min="1"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-300 mb-1">
                    {translations.staticText.heightLabel}
                  </label>
                  <input
                    type="number"
                    value={dimensions.height}
                    onChange={(e) => handleDimensionChange('height', e.target.value)}
                    className="w-full p-2 rounded-lg bg-gray-800 text-white border border-gray-600 focus:border-[#F7AA01] focus:ring-1 focus:ring-[#F7AA01] outline-none"
                    placeholder={translations.staticText.heightLabel}
                    min="1"
                  />
                </div>
              </div>
            </div>
          )}

          {/* Percentage Input */}
          {resizeMethod === 'percentage' && (
            <div className="bg-gray-700 p-4 rounded-lg">
              <label className="block text-sm font-medium text-gray-300 mb-1">
                {translations.staticText.scalePercentage}
              </label>
              <div className="flex items-center gap-4">
                <input
                  type="range"
                  min="1"
                  max="500"
                  value={percentage}
                  onChange={(e) => handlePercentageChange(e.target.value)}
                  className="flex-grow"
                />
                <div className="w-20">
                  <input
                    type="number"
                    value={percentage}
                    onChange={(e) => handlePercentageChange(e.target.value)}
                    className="w-full p-2 rounded-lg bg-gray-800 text-white border border-gray-600 focus:border-[#F7AA01] focus:ring-1 focus:ring-[#F7AA01] outline-none"
                    min="1"
                    max="500"
                  />
                </div>
                <span className="text-white">%</span>
              </div>
              {originalDimensions && (
                <div className="text-sm text-gray-300 mt-2">
                  {translations.staticText.newSize}: {dimensions.width}×{dimensions.height} {translations.staticText.pixelsText}
                </div>
              )}
            </div>
          )}

          {/* Preset Selection */}
          {resizeMethod === 'preset' && (
            <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
              {translations.presets.map((preset) => (
                <button
                  key={preset.id}
                  onClick={() => handlePresetSelect(preset)}
                  className={`p-4 rounded-lg text-center ${
                    selectedPreset === preset.id
                      ? 'bg-[#F7AA01] text-[#2F2F30]'
                      : 'bg-gray-700 text-gray-300'
                  } hover:bg-[#F7AA01] hover:text-[#2F2F30] transition-colors duration-200`}
                >
                  <div className="font-medium mb-1">{preset.name}</div>
                  <div className="text-sm opacity-75">
                    {preset.width}×{preset.height}
                  </div>
                </button>
              ))}
            </div>
          )}
        </div>

        {/* Action Buttons */}
        <div className="mb-8 flex justify-center gap-4">
          <button
            onClick={() => {
              setImageLoaded({ original: false, resized: false });
              setPreviewData(prev => ({ ...prev, showPreview: true }));
              generatePreview();
            }}
            disabled={!selectedFiles.length || !dimensions.width || !dimensions.height}
            className="bg-gray-700 text-white px-4 py-2 rounded-lg flex items-center gap-2 hover:bg-gray-600 transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <EyeIcon size={20} />
            {translations.staticText.previewButton}
          </button>

          <button
            onClick={resizeImages}
            disabled={isProcessing || selectedFiles.length === 0}
            className={`bg-[#F7AA01] text-[#2F2F30] px-6 py-3 rounded-lg flex items-center gap-2 hover:bg-[#F7AA01]/80 transition-colors duration-200 ${
              (isProcessing || selectedFiles.length === 0) ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
            {isProcessing ? (
              <>
                <RotateCw className="animate-spin" size={20} />
                {translations.staticText.processingText}
              </>
            ) : (
              <>
                <Maximize2 size={20} />
                {translations.staticText.resizeImagesButton}
              </>
            )}
          </button>
        </div>

        {/* Progress Bar */}
        {progress > 0 && progress < 100 && (
          <div className="mb-8">
            <div className="relative w-full bg-gray-200 rounded-lg overflow-hidden h-4">
              <div
                className="absolute top-0 left-0 h-full bg-[#F7AA01] transition-all duration-300"
                style={{ width: `${progress}%` }}
              />
            </div>
            <p className="text-center mt-2 text-white">{progress}%</p>
          </div>
        )}

        {/* Error Message */}
        {errorMessage && (
          <div className="mb-8 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded-lg flex items-center gap-2">
            <AlertTriangle size={20} />
            <span>{errorMessage}</span>
          </div>
        )}

        {/* Resized Files List */}
        {resizedFiles.length > 0 && (
          <div className="mb-8">
            <h2 className="text-xl font-semibold text-white mb-4">
              {translations.staticText.resizedFilesTitle}
            </h2>
            <div className="space-y-2">
              {resizedFiles.map((file, index) => (
                <div key={index} className="bg-gray-700 p-3 rounded-lg">
                  <div className="flex justify-between items-center mb-2">
                    <span className="truncate text-white">{file.name}</span>
                    <div className="flex items-center gap-3">
                      <button
                        onClick={() => downloadFile(file)}
                        className="text-[#F7AA01] hover:text-[#F7AA01]/80 transition-colors duration-200"
                        title={translations.staticText.downloadTitle}
                      >
                        <Download size={20} />
                      </button>
                      <button
                        onClick={() => removeResizedFile(index)}
                        className="text-red-500 hover:text-red-400 transition-colors duration-200"
                        title={translations.staticText.deleteTitle}
                      >
                        <Trash2 size={20} />
                      </button>
                    </div>
                  </div>
                  <div className="grid grid-cols-2 md:grid-cols-3 gap-2 text-sm">
                    <div className="text-gray-300">
                      {translations.staticText.originalSize}: {file.originalSize}
                    </div>
                    <div className="text-gray-300">
                      {translations.staticText.newSizeLabel}: {file.newSize}
                    </div>
                    <div className="text-gray-300">
                      {translations.staticText.fileSize}: {(file.newFileSize / 1024).toFixed(1)} KB
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      {/* Preview Modal */}
      {previewData.showPreview && <PreviewModal />}
      
      {/* Documentation Component */}
      <Documentation tool="resize" />
    </>
  );
};

export default ImageResize;
