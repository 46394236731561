import React, { useState, useEffect } from 'react';
import { FileText } from 'lucide-react';
import useTranslate from '../hooks/useTranslate';

const TermsOfService = () => {
  const { translateText, currentLanguage } = useTranslate();
  const [translations, setTranslations] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const translateTermsOfService = async () => {
      try {
        const translatedContent = {
          title: await translateText('Terms of Service', currentLanguage),
          sections: [
            {
              title: await translateText('1. Terms', currentLanguage),
              content: await translateText('By accessing ImgToolLab, you agree to be bound by these terms of service and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site.', currentLanguage),
            },
            {
              title: await translateText('2. Use License', currentLanguage),
              content: await translateText('Permission is granted to temporarily use ImgToolLab for personal, non-commercial use only. This is the grant of a license, not a transfer of title. Under this license you may not: modify or copy the materials; use the materials for any commercial purpose; attempt to decompile or reverse engineer any software contained on ImgToolLab; remove any copyright or other proprietary notations from the materials; or transfer the materials to another person or "mirror" the materials on any other server.', currentLanguage),
            },
            {
              title: await translateText('3. Disclaimer', currentLanguage),
              content: await translateText('The materials on ImgToolLab are provided on an \'as is\' basis. ImgToolLab makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.', currentLanguage),
            },
            {
              title: await translateText('4. Limitations', currentLanguage),
              content: await translateText('In no event shall ImgToolLab or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use ImgToolLab, even if ImgToolLab or an authorized representative has been notified orally or in writing of the possibility of such damage.', currentLanguage),
            },
            {
              title: await translateText('5. Accuracy of Materials', currentLanguage),
              content: await translateText('The materials appearing on ImgToolLab could include technical, typographical, or photographic errors. ImgToolLab does not warrant that any of the materials on its website are accurate, complete or current. ImgToolLab may make changes to the materials contained on its website at any time without notice.', currentLanguage),
            },
            {
              title: await translateText('6. Links', currentLanguage),
              content: await translateText('ImgToolLab has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by ImgToolLab of the site. Use of any such linked website is at the user\'s own risk.', currentLanguage),
            },
            {
              title: await translateText('7. Modifications', currentLanguage),
              content: await translateText('ImgToolLab may revise these terms of service for its website at any time without notice. By using this website you are agreeing to be bound by the then current version of these terms of service.', currentLanguage),
            },
            {
              title: await translateText('8. Governing Law', currentLanguage),
              content: await translateText('These terms and conditions are governed by and construed in accordance with the laws and you irrevocably submit to the exclusive jurisdiction of the courts in that location.', currentLanguage),
            }
          ]
        };
        setTranslations(translatedContent);
        setIsLoading(false);
      } catch (error) {
        console.error('Translation error:', error);
        // Fallback to English if translation fails
        setTranslations({
          title: 'Terms of Service',
          sections: [
            {
              title: '1. Terms',
              content: 'By accessing ImgToolLab, you agree to be bound by these terms of service and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site.',
            },
            {
              title: '2. Use License',
              content: 'Permission is granted to temporarily use ImgToolLab for personal, non-commercial use only. This is the grant of a license, not a transfer of title. Under this license you may not: modify or copy the materials; use the materials for any commercial purpose; attempt to decompile or reverse engineer any software contained on ImgToolLab; remove any copyright or other proprietary notations from the materials; or transfer the materials to another person or "mirror" the materials on any other server.',
            },
            {
              title: '3. Disclaimer',
              content: 'The materials on ImgToolLab are provided on an \'as is\' basis. ImgToolLab makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.',
            },
            {
              title: '4. Limitations',
              content: 'In no event shall ImgToolLab or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use ImgToolLab, even if ImgToolLab or an authorized representative has been notified orally or in writing of the possibility of such damage.',
            },
            {
              title: '5. Accuracy of Materials',
              content: 'The materials appearing on ImgToolLab could include technical, typographical, or photographic errors. ImgToolLab does not warrant that any of the materials on its website are accurate, complete or current. ImgToolLab may make changes to the materials contained on its website at any time without notice.',
            },
            {
              title: '6. Links',
              content: 'ImgToolLab has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by ImgToolLab of the site. Use of any such linked website is at the user\'s own risk.',
            },
            {
              title: '7. Modifications',
              content: 'ImgToolLab may revise these terms of service for its website at any time without notice. By using this website you are agreeing to be bound by the then current version of these terms of service.',
            },
            {
              title: '8. Governing Law',
              content: 'These terms and conditions are governed by and construed in accordance with the laws and you irrevocably submit to the exclusive jurisdiction of the courts in that location.',
            }
          ]
        });
        setIsLoading(false);
      }
    };

    translateTermsOfService();
  }, [translateText, currentLanguage]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="max-w-4xl mx-auto px-4 py-12">
      <div className="flex items-center justify-center gap-4 mb-8">
        <FileText className="w-12 h-12 text-[#F7AA01]" />
        <h1 className="text-4xl font-bold text-white">{translations.title}</h1>
      </div>

      <div className="space-y-8">
        {translations.sections.map((section, index) => (
          <section key={index} className="bg-gray-800 rounded-lg p-6">
            <h2 className="text-2xl font-semibold text-white mb-4">{section.title}</h2>
            <div className="space-y-4">
              <p className="text-gray-300 leading-relaxed">
                {section.content}
              </p>
            </div>
          </section>
        ))}

        <section className="bg-gray-800 rounded-lg p-6">
          <h2 className="text-2xl font-semibold text-white mb-4">Contact</h2>
          <p className="text-gray-300 leading-relaxed">
            If you have any questions about these Terms of Service, please contact us at:
          </p>
          <a 
            href="mailto:support@imgtoollab.com" 
            className="text-[#F7AA01] hover:text-[#F7AA01]/80 inline-block mt-2"
          >
            support@imgtoollab.com
          </a>
        </section>
      </div>
    </div>
  );
};

export default TermsOfService;