import React, { useState, useCallback, useRef, useEffect } from 'react';
import { 
  Upload, 
  Download, 
  Trash2, 
  RotateCw, 
  AlertTriangle, 
  Crop as CropIcon, 
  Square,
  Maximize2,
  Monitor,
  Facebook,
  Instagram,
  Twitter,
  StretchVertical,
  StretchHorizontal
} from 'lucide-react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Documentation from '../shared/Documentation';
import useTranslate from '../../hooks/useTranslate';
import Loading from '../../components/shared/Loading';

const ImageCrop = () => {
  const { translateText, currentLanguage } = useTranslate();
  const [translations, setTranslations] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [croppedFiles, setCroppedFiles] = useState([]);
  const [currentImage, setCurrentImage] = useState(null);
  const [currentFileIndex, setCurrentFileIndex] = useState(0);
  const [crop, setCrop] = useState();
  const [aspect, setAspect] = useState(undefined);
  const [progress, setProgress] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const imageRef = useRef(null);

  // Static text that needs translation
  const staticText = {
    title: 'Crop Images',
    subtitle: 'Crop your images with precision',
    dragDropText: 'Drag & Drop images here',
    orText: 'or',
    selectImagesButton: 'Select Images',
    selectedFilesTitle: 'Selected Files',
    currentText: 'Current',
    aspectRatioTitle: 'Aspect Ratio',
    cropAreaTitle: 'Crop Area',
    sizeText: 'Size',
    pixelsText: 'pixels',
    cropImageButton: 'Crop Image',
    processingText: 'Processing...',
    croppedFilesTitle: 'Cropped Files',
    fromText: 'From',
    errorCropArea: 'Please select an area to crop',
    downloadTitle: 'Download',
    deleteTitle: 'Delete',
    sizeLabel: 'Size',
    cropButton: 'Crop Image'
  };

  const aspectRatios = [
    { name: 'Free', value: undefined, icon: Maximize2 },
    { name: 'Square (1:1)', value: 1, icon: Square },
    { name: 'Portrait (3:4)', value: 3/4, icon: StretchVertical },
    { name: 'Landscape (4:3)', value: 4/3, icon: StretchHorizontal },
    { name: 'Wide (16:9)', value: 16/9, icon: Monitor },
    { name: 'Facebook', value: 1.91, icon: Facebook },
    { name: 'Instagram', value: 1, icon: Instagram },
    { name: 'Twitter', value: 2, icon: Twitter },
  ];

  useEffect(() => {
    const translateContent = async () => {
      setIsLoading(true);
      try {
        // Translate static text
        const translatedStatic = {};
        for (const [key, value] of Object.entries(staticText)) {
          translatedStatic[key] = await translateText(value, currentLanguage);
        }

        // Translate aspect ratios
        const translatedAspectRatios = await Promise.all(
          aspectRatios.map(async (ratio) => ({
            ...ratio,
            name: await translateText(ratio.name, currentLanguage)
          }))
        );

        setTranslations({
          staticText: translatedStatic,
          aspectRatios: translatedAspectRatios
        });
      } catch (error) {
        console.error('Translation error:', error);
        setTranslations({
          staticText,
          aspectRatios
        });
      }
      setIsLoading(false);
    };

    translateContent();
  }, [currentLanguage, translateText]);

  useEffect(() => {
    if (selectedFiles.length > 0 && currentFileIndex < selectedFiles.length) {
      const reader = new FileReader();
      reader.onload = () => setCurrentImage(reader.result);
      reader.readAsDataURL(selectedFiles[currentFileIndex]);
    }
  }, [selectedFiles, currentFileIndex]);

  const onImageLoad = useCallback((e) => {
    const { naturalWidth, naturalHeight, width, height } = e.currentTarget;
    imageRef.current = e.currentTarget;
    
    let cropWidth = width * 0.9;
    let cropHeight = height * 0.9;
    
    if (aspect) {
      if (width / height > aspect) {
        cropHeight = cropWidth / aspect;
      } else {
        cropWidth = cropHeight * aspect;
      }
    }
    
    const x = (width - cropWidth) / 2;
    const y = (height - cropHeight) / 2;
    
    const initialCrop = {
      unit: 'px',
      x,
      y,
      width: cropWidth,
      height: cropHeight
    };
    
    setCrop(initialCrop);
  }, [aspect]);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles(files);
    setCurrentFileIndex(0);
    setCroppedFiles([]);
  };

  const handleDrop = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    const files = Array.from(event.dataTransfer.files);
    setSelectedFiles(files);
    setCurrentFileIndex(0);
    setCroppedFiles([]);
  }, []);

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const removeFile = (index) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    if (currentFileIndex >= index) {
      setCurrentFileIndex(Math.max(0, currentFileIndex - 1));
    }
  };

  const removeCroppedFile = (index) => {
    setCroppedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const cropImage = async () => {
    if (!crop || !imageRef.current) {
      setErrorMessage(translations.staticText.errorCropArea);
      return;
    }

    // Validate crop dimensions
    if (crop.width <= 0 || crop.height <= 0 || isNaN(crop.width) || isNaN(crop.height)) {
      setErrorMessage('Invalid crop area. Please select a valid area.');
      return;
    }

    setIsProcessing(true);
    setProgress(0);

    try {
      const image = imageRef.current;
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      // Calculate actual dimensions in the original image scale
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      
      const naturalX = Math.round(crop.x * scaleX);
      const naturalY = Math.round(crop.y * scaleY);
      const naturalWidth = Math.round(crop.width * scaleX);
      const naturalHeight = Math.round(crop.height * scaleY);

      // Validate calculated dimensions
      if (naturalWidth <= 0 || naturalHeight <= 0 || 
          isNaN(naturalWidth) || isNaN(naturalHeight)) {
        throw new Error('Invalid crop dimensions');
      }

      // Set canvas dimensions
      canvas.width = naturalWidth;
      canvas.height = naturalHeight;

      // Enable image smoothing for better quality
      ctx.imageSmoothingEnabled = true;
      ctx.imageSmoothingQuality = 'high';

      // Draw the cropped image
      ctx.drawImage(
        image,
        naturalX,
        naturalY,
        naturalWidth,
        naturalHeight,
        0,
        0,
        naturalWidth,
        naturalHeight
      );

      // Convert to PNG with maximum quality
      const dataUrl = canvas.toDataURL('image/png', 1.0);
      
      // Validate the generated dataUrl
      if (!dataUrl || dataUrl === 'data:,') {
        throw new Error('Failed to generate image data');
      }

      const currentFile = selectedFiles[currentFileIndex];
      const newCroppedFile = {
        name: `${currentFile.name.split('.')[0]}_cropped.png`,
        dataUrl,
        dimensions: `${naturalWidth}×${naturalHeight}`,
        originalName: currentFile.name,
        // Calculate actual file size from base64 string
        size: Math.round((dataUrl.length - dataUrl.indexOf(',') - 1) * 0.75)
      };

      // Validate the cropped file object
      if (!newCroppedFile.size || newCroppedFile.size <= 0) {
        throw new Error('Invalid file size');
      }

      setCroppedFiles(prev => [...prev, newCroppedFile]);
      setProgress(100);

      if (currentFileIndex < selectedFiles.length - 1) {
        setCurrentFileIndex(prev => prev + 1);
      }
    } catch (error) {
      console.error('Cropping error:', error);
      setErrorMessage(`Cropping failed: ${error.message}`);
    } finally {
      setIsProcessing(false);
    }
  };

  const downloadFile = (file) => {
    if (!file.dataUrl || !file.size || file.size <= 0) {
      setErrorMessage('Invalid file data. Cannot download.');
      return;
    }
    
    const link = document.createElement('a');
    link.href = file.dataUrl;
    link.download = file.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleAspectRatioChange = (newAspect) => {
    setAspect(newAspect);
    if (imageRef.current) {
      const { width, height } = imageRef.current;
      let cropWidth = width * 0.9;
      let cropHeight = height * 0.9;
      
      if (newAspect) {
        if (width / height > newAspect) {
          cropHeight = cropWidth / newAspect;
        } else {
          cropWidth = cropHeight * newAspect;
        }
      }
      
      const x = (width - cropWidth) / 2;
      const y = (height - cropHeight) / 2;
      
      setCrop({
        unit: 'px',
        x,
        y,
        width: cropWidth,
        height: cropHeight
      });
    }
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="max-w-4xl mx-auto px-4">
          <div className="text-center mb-8">
            <h1 className="text-3xl lg:text-4xl font-bold mb-2 text-white">
              {translations.staticText.title}
            </h1>
            <p className="text-sm text-gray-300">
              {translations.staticText.subtitle}
            </p>
          </div>

          <div className="mb-8">
            <div
              className="border-2 border-dashed border-gray-600 rounded-lg p-6 text-center cursor-pointer bg-gray-700 flex flex-col justify-center items-center transition-colors duration-200 hover:border-[#F7AA01]/50"
              onDrop={handleDrop}
              onDragOver={handleDragOver}
              onClick={() => document.getElementById('fileInput').click()}
            >
              <Upload size={48} className="text-gray-300 mb-4" />
              <p className="text-gray-300">{translations.staticText.dragDropText}</p>
              <p className="text-gray-300">{translations.staticText.orText}</p>
              <button className="bg-[#F7AA01] text-[#2F2F30] px-4 py-2 rounded-lg mt-2 flex items-center gap-2 hover:bg-[#F7AA01]/80 transition-colors duration-200">
                <Upload size={20} />
                {translations.staticText.selectImagesButton}
              </button>
              <input
                id="fileInput"
                type="file"
                onChange={handleFileChange}
                accept="image/*"
                multiple
                className="hidden"
              />
            </div>
          </div>

          {selectedFiles.length > 0 && (
            <div className="mb-8">
              <h2 className="text-xl font-semibold text-white mb-4">
                {translations.staticText.selectedFilesTitle}
              </h2>
              <div className="space-y-2">
                {selectedFiles.map((file, index) => (
                  <div key={index} className="flex justify-between items-center bg-gray-700 p-3 rounded-lg">
                    <span className={`truncate text-white ${index === currentFileIndex ? 'font-bold' : ''}`}>
                      {file.name} {index === currentFileIndex && `(${translations.staticText.currentText})`}
                    </span>
                    <div className="flex items-center gap-3">
                      <span className="text-gray-300 text-sm">
                        {(file.size / 1024).toFixed(1)} KB
                      </span>
                      <button
                        onClick={() => removeFile(index)}
                        className="text-red-500 hover:text-red-400 transition-colors duration-200"
                        title={translations.staticText.deleteTitle}
                      >
                        <Trash2 size={20} />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {currentImage && (
            <>
              <div className="mb-8">
                <h2 className="text-xl font-semibold text-white mb-4">
                  {translations.staticText.aspectRatioTitle}
                </h2>
                <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
                  {translations.aspectRatios.map((ratio) => {
                    const IconComponent = ratio.icon;
                    return (
                      <button
                        key={ratio.name}
                        onClick={() => handleAspectRatioChange(ratio.value)}
                        className={`p-3 rounded-lg flex flex-col items-center gap-2 ${
                          aspect === ratio.value
                            ? 'bg-[#F7AA01] text-[#2F2F30]'
                            : 'bg-gray-700 text-gray-300'
                        } hover:bg-[#F7AA01] hover:text-[#2F2F30] transition-colors duration-200`}
                      >
                        <IconComponent size={20} />
                        <span>{ratio.name}</span>
                      </button>
                    );
                  })}
                </div>
                </div>

              <div className="mb-8">
                <h2 className="text-xl font-semibold text-white mb-4">
                  {translations.staticText.cropAreaTitle}
                </h2>
                <div className="bg-gray-800 rounded-lg p-4">
                  <ReactCrop
                    crop={crop}
                    onChange={setCrop}
                    onComplete={setCrop}
                    aspect={aspect}
                  >
                    <img
                      src={currentImage}
                      alt="Crop"
                      onLoad={onImageLoad}
                      style={{ maxWidth: '100%' }}
                    />
                  </ReactCrop>
                </div>
                {crop && (
                  <div className="mt-2 text-sm text-gray-300">
                    {translations.staticText.sizeText}: {Math.round(crop.width)} × {Math.round(crop.height)} {translations.staticText.pixelsText}
                  </div>
                )}
              </div>

              <div className="mb-8 flex justify-center">
                <button
                  onClick={cropImage}
                  disabled={isProcessing}
                  className={`bg-[#F7AA01] text-[#2F2F30] px-6 py-3 rounded-lg flex items-center gap-2 hover:bg-[#F7AA01]/80 transition-colors duration-200 ${
                    isProcessing ? 'opacity-50 cursor-not-allowed' : ''
                  }`}
                >
                  {isProcessing ? (
                    <>
                      <RotateCw className="animate-spin" size={20} />
                      {translations.staticText.processingText}
                    </>
                  ) : (
                    <>
                      <CropIcon size={20} />
                      {translations.staticText.cropButton}
                    </>
                  )}
                </button>
              </div>
            </>
          )}

          {progress > 0 && progress < 100 && (
            <div className="mb-8">
              <div className="relative w-full bg-gray-200 rounded-lg overflow-hidden h-4">
                <div
                  className="absolute top-0 left-0 h-full bg-[#F7AA01] transition-all duration-300"
                  style={{ width: `${progress}%` }}
                />
              </div>
              <p className="text-center mt-2 text-white">{progress}%</p>
            </div>
          )}

          {errorMessage && (
            <div className="mb-8 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded-lg flex items-center gap-2">
              <AlertTriangle size={20} />
              <span>{errorMessage}</span>
            </div>
          )}

          {croppedFiles.length > 0 && (
            <div className="mb-8">
              <h2 className="text-xl font-semibold text-white mb-4">
                {translations.staticText.croppedFilesTitle}
              </h2>
              <div className="space-y-2">
                {croppedFiles.map((file, index) => (
                  <div key={index} className="bg-gray-700 p-3 rounded-lg">
                    <div className="flex justify-between items-center mb-2">
                      <span className="truncate text-white">{file.name}</span>
                      <div className="flex items-center gap-3">
                        <button
                          onClick={() => downloadFile(file)}
                          className="text-[#F7AA01] hover:text-[#F7AA01]/80 transition-colors duration-200"
                          title={translations.staticText.downloadTitle}
                          disabled={!file.size || file.size <= 0}
                        >
                          <Download size={20} />
                        </button>
                        <button
                          onClick={() => removeCroppedFile(index)}
                          className="text-red-500 hover:text-red-400 transition-colors duration-200"
                          title={translations.staticText.deleteTitle}
                        >
                          <Trash2 size={20} />
                        </button>
                      </div>
                    </div>
                    <div className="grid grid-cols-2 gap-2 text-sm">
                      <div className="text-gray-300">
                        {translations.staticText.fromText}: {file.originalName}
                      </div>
                      <div className="text-gray-300">
                        {translations.staticText.sizeText}: {file.dimensions}
                      </div>
                      <div className="text-gray-300">
                        {(file.size / 1024).toFixed(1)} KB
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
      <Documentation tool="crop" />
    </>
  );
};

export default ImageCrop;
