import React, { useState, useEffect } from 'react';
import { Cookie } from 'lucide-react';
import useTranslate from '../hooks/useTranslate';

const Cookies = () => {
  const { translateText, currentLanguage } = useTranslate();
  const [translations, setTranslations] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const translateCookiesPage = async () => {
      try {
        const translatedContent = {
          title: await translateText('Cookie Policy', currentLanguage),
          whatAreCookiesTitle: await translateText('What Are Cookies', currentLanguage),
          whatAreCookiesDescription: await translateText('Cookies are small text files that are stored on your computer or mobile device when you visit our website. They help us make your experience better by remembering your preferences and understanding how you use our tools.', currentLanguage),
          howWeUseCookiesTitle: await translateText('How We Use Cookies', currentLanguage),
          howWeUseCookiesItems: [
            await translateText('To remember your preferences', currentLanguage),
            await translateText('To understand how you use our tools', currentLanguage),
            await translateText('To improve our services', currentLanguage),
            await translateText('To provide a better user experience', currentLanguage)
          ],
          cookieTypesTitle: await translateText('Types of Cookies We Use', currentLanguage),
          essentialCookiesTitle: await translateText('Essential Cookies', currentLanguage),
          essentialCookiesDescription: await translateText('Required for the website to function properly.', currentLanguage),
          preferenceCookiesTitle: await translateText('Preference Cookies', currentLanguage),
          preferenceCookiesDescription: await translateText('Remember your settings and preferences.', currentLanguage),
          analyticsCookiesTitle: await translateText('Analytics Cookies', currentLanguage),
          analyticsCookiesDescription: await translateText('Help us understand how visitors use our website.', currentLanguage),
          managingCookiesTitle: await translateText('Managing Cookies', currentLanguage),
          managingCookiesDescription: await translateText('You can control and/or delete cookies as you wish. You can delete all cookies that are already on your computer and you can set most browsers to prevent them from being placed. However, if you do this, you may have to manually adjust some preferences every time you visit our website.', currentLanguage)
        };
        setTranslations(translatedContent);
        setIsLoading(false);
      } catch (error) {
        console.error('Translation error:', error);
        // Fallback to English if translation fails
        setTranslations({
          title: 'Cookie Policy',
          whatAreCookiesTitle: 'What Are Cookies',
          whatAreCookiesDescription: 'Cookies are small text files that are stored on your computer or mobile device when you visit our website. They help us make your experience better by remembering your preferences and understanding how you use our tools.',
          howWeUseCookiesTitle: 'How We Use Cookies',
          howWeUseCookiesItems: [
            'To remember your preferences',
            'To understand how you use our tools',
            'To improve our services',
            'To provide a better user experience'
          ],
          cookieTypesTitle: 'Types of Cookies We Use',
          essentialCookiesTitle: 'Essential Cookies',
          essentialCookiesDescription: 'Required for the website to function properly.',
          preferenceCookiesTitle: 'Preference Cookies',
          preferenceCookiesDescription: 'Remember your settings and preferences.',
          analyticsCookiesTitle: 'Analytics Cookies',
          analyticsCookiesDescription: 'Help us understand how visitors use our website.',
          managingCookiesTitle: 'Managing Cookies',
          managingCookiesDescription: 'You can control and/or delete cookies as you wish. You can delete all cookies that are already on your computer and you can set most browsers to prevent them from being placed. However, if you do this, you may have to manually adjust some preferences every time you visit our website.'
        });
        setIsLoading(false);
      }
    };

    translateCookiesPage();
  }, [translateText, currentLanguage]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="max-w-4xl mx-auto px-4 py-12">
      <div className="flex items-center justify-center gap-4 mb-8">
        <Cookie className="w-12 h-12 text-[#F7AA01]" />
        <h1 className="text-4xl font-bold text-white">{translations.title}</h1>
      </div>

      <div className="bg-gray-800 rounded-lg p-6 space-y-6">
        <section>
          <h2 className="text-2xl font-semibold text-white mb-4">{translations.whatAreCookiesTitle}</h2>
          <p className="text-gray-300 leading-relaxed">
            {translations.whatAreCookiesDescription}
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold text-white mb-4">{translations.howWeUseCookiesTitle}</h2>
          {translations.howWeUseCookiesItems && (
            <ul className="list-disc list-inside text-gray-300 space-y-2">
              {translations.howWeUseCookiesItems.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          )}
        </section>

        <section>
          <h2 className="text-2xl font-semibold text-white mb-4">{translations.cookieTypesTitle}</h2>
          <div className="space-y-4">
            <div>
              <h3 className="text-lg font-semibold text-[#F7AA01] mb-2">{translations.essentialCookiesTitle}</h3>
              <p className="text-gray-300">{translations.essentialCookiesDescription}</p>
            </div>
            <div>
              <h3 className="text-lg font-semibold text-[#F7AA01] mb-2">{translations.preferenceCookiesTitle}</h3>
              <p className="text-gray-300">{translations.preferenceCookiesDescription}</p>
            </div>
            <div>
              <h3 className="text-lg font-semibold text-[#F7AA01] mb-2">{translations.analyticsCookiesTitle}</h3>
              <p className="text-gray-300">{translations.analyticsCookiesDescription}</p>
            </div>
          </div>
        </section>

        <section>
          <h2 className="text-2xl font-semibold text-white mb-4">{translations.managingCookiesTitle}</h2>
          <p className="text-gray-300 leading-relaxed">
            {translations.managingCookiesDescription}
          </p>
        </section>
      </div>
    </div>
  );
};

export default Cookies;