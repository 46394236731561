import React, { useState, useEffect } from 'react';
import { FileImage, Heart, Coffee, Instagram, Facebook, Linkedin } from 'lucide-react';
import useTranslate from '../hooks/useTranslate';

const AboutUs = () => {
  const { translateText, currentLanguage } = useTranslate();
  const [translations, setTranslations] = useState({});

  useEffect(() => {
    const translateAboutUs = async () => {
      try {
        const translatedContent = {
          title: await translateText('About ImgToolLab', currentLanguage),
          missionTitle: await translateText('Our Mission', currentLanguage),
          missionDescription: await translateText('ImgToolLab was created with a simple goal: to provide free, powerful, and easy-to-use image editing tools for everyone. We believe that professional-grade image editing capabilities should be accessible to all, without the need for expensive software or technical expertise.', currentLanguage),
          developerTitle: await translateText('Meet the Developer', currentLanguage),
          developerName: await translateText('M.S.HASBI', currentLanguage),
          developerDescription: await translateText('A passionate developer focused on creating tools that make image editing accessible to everyone. With years of experience in web development and image processing, I\'m committed to delivering high-quality tools that are both powerful and user-friendly.', currentLanguage),
          portfolioLink: await translateText('Portfolio →', currentLanguage),
          featuresTitle: await translateText('Features & Benefits', currentLanguage),
          freeToUseTitle: await translateText('Free to Use', currentLanguage),
          freeToUseDescription: await translateText('All our tools are completely free with no hidden costs or limitations.', currentLanguage),
          privacyFirstTitle: await translateText('Privacy First', currentLanguage),
          privacyFirstDescription: await translateText('Your files are processed locally. We never store your images.', currentLanguage),
          userFriendlyTitle: await translateText('User-Friendly', currentLanguage),
          userFriendlyDescription: await translateText('Simple interface designed for users of all skill levels.', currentLanguage),
          powerfulToolsTitle: await translateText('Powerful Tools', currentLanguage),
          powerfulToolsDescription: await translateText('Professional-grade features for all your image editing needs.', currentLanguage),
          supportTitle: await translateText('Support the Project', currentLanguage),
          supportDescription: await translateText('ImgToolLab is maintained and improved through the support of users like you. If you find our tools helpful, consider buying us a coffee to help keep the project running.', currentLanguage),
          supportButtonLabel: await translateText('Support ImgToolLab', currentLanguage),
          socialMediaLabel: await translateText('Follow me on:', currentLanguage)
        };
        setTranslations(translatedContent);
      } catch (error) {
        console.error('Translation error:', error);
        // Fallback to English if translation fails
        setTranslations({
          title: 'About ImgToolLab',
          missionTitle: 'Our Mission',
          missionDescription: 'ImgToolLab was created with a simple goal: to provide free, powerful, and easy-to-use image editing tools for everyone. We believe that professional-grade image editing capabilities should be accessible to all, without the need for expensive software or technical expertise.',
          developerTitle: 'Meet the Developer',
          developerName: 'M.S.HASBI',
          developerDescription: 'A passionate developer focused on creating tools that make image editing accessible to everyone. With years of experience in web development and image processing, I\'m committed to delivering high-quality tools that are both powerful and user-friendly.',
          portfolioLink: 'Portfolio →',
          featuresTitle: 'Features & Benefits',
          freeToUseTitle: 'Free to Use',
          freeToUseDescription: 'All our tools are completely free with no hidden costs or limitations.',
          privacyFirstTitle: 'Privacy First',
          privacyFirstDescription: 'Your files are processed locally. We never store your images.',
          userFriendlyTitle: 'User-Friendly',
          userFriendlyDescription: 'Simple interface designed for users of all skill levels.',
          powerfulToolsTitle: 'Powerful Tools',
          powerfulToolsDescription: 'Professional-grade features for all your image editing needs.',
          supportTitle: 'Support the Project',
          supportDescription: 'ImgToolLab is maintained and improved through the support of users like you. If you find our tools helpful, consider buying us a coffee to help keep the project running.',
          supportButtonLabel: 'Support ImgToolLab',
          socialMediaLabel: 'Follow me on:'
        });
      }
    };

    translateAboutUs();
  }, [translateText, currentLanguage]);

  return (
    <div className="max-w-4xl mx-auto px-4 py-12">
      <h1 className="text-4xl font-bold text-white mb-8 text-center">{translations.title}</h1>

      <div className="space-y-8">
        <section className="bg-gray-800 rounded-lg p-6">
          <h2 className="text-2xl font-semibold text-white mb-4">{translations.missionTitle}</h2>
          <p className="text-gray-300 leading-relaxed">
            {translations.missionDescription}
          </p>
        </section>

        <section className="bg-gray-800 rounded-lg p-6">
          <h2 className="text-2xl font-semibold text-white mb-4">{translations.developerTitle}</h2>
          <div className="flex flex-col md:flex-row items-center gap-6">
            <div className="w-32 h-32 rounded-full bg-[#F7AA01] flex items-center justify-center">
              <FileImage size={48} className="text-[#2F2F30]" />
            </div>
            <div className="flex-1">
              <h3 className="text-xl font-semibold text-white mb-2">{translations.developerName}</h3>
              <p className="text-gray-300 leading-relaxed mb-4">
                {translations.developerDescription}
              </p>
              <div className="space-y-4">
                <div className="flex items-center gap-4">
                  <a 
                    href="http://xhsprojects.com/myportofolio"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-[#F7AA01] hover:text-[#F7AA01]/80"
                  >
                    {translations.portfolioLink}
                  </a>
                </div>
                
                <div className="flex items-center gap-2">
                  <span className="text-gray-300">{translations.socialMediaLabel}</span>
                  <div className="flex gap-4">
                    <a
                      href="https://www.instagram.com/hasby054/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-gray-300 hover:text-[#F7AA01] transition-colors duration-200"
                      aria-label="Instagram"
                    >
                      <Instagram className="w-5 h-5" />
                    </a>
                    <a
                      href="https://www.facebook.com/muhsaepul.hasby/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-gray-300 hover:text-[#F7AA01] transition-colors duration-200"
                      aria-label="Facebook"
                    >
                      <Facebook className="w-5 h-5" />
                    </a>
                    <a
                      href="https://www.linkedin.com/in/muhammad-saepul-hasbi-b36bbb214"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-gray-300 hover:text-[#F7AA01] transition-colors duration-200"
                      aria-label="LinkedIn"
                    >
                      <Linkedin className="w-5 h-5" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-gray-800 rounded-lg p-6">
          <h2 className="text-2xl font-semibold text-white mb-4">{translations.featuresTitle}</h2>
          <div className="grid md:grid-cols-2 gap-6">
            <div>
              <h3 className="text-lg font-semibold text-[#F7AA01] mb-2">{translations.freeToUseTitle}</h3>
              <p className="text-gray-300">{translations.freeToUseDescription}</p>
            </div>
            <div>
              <h3 className="text-lg font-semibold text-[#F7AA01] mb-2">{translations.privacyFirstTitle}</h3>
              <p className="text-gray-300">{translations.privacyFirstDescription}</p>
            </div>
            <div>
              <h3 className="text-lg font-semibold text-[#F7AA01] mb-2">{translations.userFriendlyTitle}</h3>
              <p className="text-gray-300">{translations.userFriendlyDescription}</p>
            </div>
            <div>
              <h3 className="text-lg font-semibold text-[#F7AA01] mb-2">{translations.powerfulToolsTitle}</h3>
              <p className="text-gray-300">{translations.powerfulToolsDescription}</p>
            </div>
          </div>
        </section>

        <section className="bg-gray-800 rounded-lg p-6">
          <h2 className="text-2xl font-semibold text-white mb-4">{translations.supportTitle}</h2>
          <p className="text-gray-300 leading-relaxed mb-4">
            {translations.supportDescription}
          </p>
          <a 
            href="https://trakteer.id/mshasbi/tip"
            target="_blank"
            rel="noopener noreferrer"
            className="bg-[#F7AA01] text-[#2F2F30] px-6 py-3 rounded-lg inline-flex items-center gap-2 hover:bg-[#F7AA01]/80 transition-colors duration-200"
          >
            <Coffee className="w-5 h-5" />
            {translations.supportButtonLabel}
          </a>
        </section>
      </div>
    </div>
  );
};

export default AboutUs;
