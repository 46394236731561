import React, { useState, useCallback, useEffect } from 'react';
import { 
  Upload, 
  Download, 
  Trash2, 
  RotateCw, 
  RotateCcw, 
  AlertTriangle, 
  Flip, 
  FlipHorizontal,
  FlipVertical
} from 'lucide-react';
import Documentation from '../shared/Documentation';
import useTranslate from '../../hooks/useTranslate';
import Loading from '../../components/shared/Loading';

const ImageRotate = () => {
  const { translateText, currentLanguage } = useTranslate();
  const [translations, setTranslations] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [rotatedFiles, setRotatedFiles] = useState([]);
  const [settings, setSettings] = useState({
    angle: 0,
    flipHorizontal: false,
    flipVertical: false,
    maintainSize: true,
    autoRotate: false
  });
  const [progress, setProgress] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [previews, setPreviews] = useState({});

  // Static text that needs translation
  const staticText = {
    title: 'Rotate Images',
    subtitle: 'Rotate and flip your images with precision',
    dragDropText: 'Drag & Drop images here',
    orText: 'or',
    selectImagesButton: 'Select Images',
    quickActionsTitle: 'Quick Actions',
    rotateLeftButton: 'Rotate Left',
    rotateRightButton: 'Rotate Right',
    flipHorizontalButton: 'Flip Horizontal',
    flipVerticalButton: 'Flip Vertical',
    advancedSettingsTitle: 'Advanced Settings',
    customAngleLabel: 'Custom Angle',
    maintainSizeLabel: 'Maintain Original Size',
    autoRotateLabel: 'Auto-detect Orientation',
    selectedFilesTitle: 'Selected Files',
    processingText: 'Processing...',
    applyRotationButton: 'Apply Rotation',
    rotatedFilesTitle: 'Rotated Files',
    originalLabel: 'Original',
    rotatedLabel: 'Rotated',
    rotationLabel: 'Rotation',
    flippedLabel: 'Flipped',
    downloadTitle: 'Download',
    deleteTitle: 'Delete',
    errorSelectFiles: 'Please select files to rotate',
    newLabel: 'New',
    originalSizeLabel: 'Original'
  };

  // Translation effect
  useEffect(() => {
    const translateContent = async () => {
      setIsLoading(true);
      try {
        // Translate static text
        const translatedStatic = {};
        for (const [key, value] of Object.entries(staticText)) {
          translatedStatic[key] = await translateText(value, currentLanguage);
        }

        setTranslations({
          staticText: translatedStatic
        });
      } catch (error) {
        console.error('Translation error:', error);
        // Fallback to English
        setTranslations({
          staticText
        });
      }
      setIsLoading(false);
    };

    translateContent();
  }, [currentLanguage, translateText]);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
    generatePreviews(files);
  };

  const handleDrop = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    const files = Array.from(event.dataTransfer.files);
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
    generatePreviews(files);
  }, []);

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const generatePreviews = (files) => {
    files.forEach(file => {
      const reader = new FileReader();
      reader.onload = (e) => {
        setPreviews(prev => ({
          ...prev,
          [file.name]: e.target.result
        }));
      };
      reader.readAsDataURL(file);
    });
  };

  const removeFile = (index) => {
    const fileToRemove = selectedFiles[index];
    setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    setPreviews(prev => {
      const newPreviews = { ...prev };
      delete newPreviews[fileToRemove.name];
      return newPreviews;
    });
  };

  const removeRotatedFile = (index) => {
    setRotatedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };
  const rotateImage = async () => {
    if (selectedFiles.length === 0) {
      setErrorMessage(translations.staticText.errorSelectFiles);
      return;
    }

    setProgress(0);
    setErrorMessage('');
    setIsProcessing(true);

    try {
      const rotated = await Promise.all(
        selectedFiles.map(async (file, index) => {
          const img = await createImageBitmap(file);
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          let width = img.width;
          let height = img.height;

          if (!settings.maintainSize && (settings.angle === 90 || settings.angle === 270)) {
            [width, height] = [height, width];
          }

          canvas.width = width;
          canvas.height = height;

          ctx.clearRect(0, 0, canvas.width, canvas.height);
          ctx.translate(canvas.width / 2, canvas.height / 2);
          ctx.rotate((settings.angle * Math.PI) / 180);

          const scaleX = settings.flipHorizontal ? -1 : 1;
          const scaleY = settings.flipVertical ? -1 : 1;
          ctx.scale(scaleX, scaleY);

          ctx.drawImage(
            img,
            -img.width / 2,
            -img.height / 2,
            img.width,
            img.height
          );

          const dataUrl = canvas.toDataURL('image/png', 1.0);
          
          setProgress(((index + 1) / selectedFiles.length) * 100);

          return {
            name: `${file.name.split('.')[0]}_rotated.png`,
            dataUrl,
            originalSize: `${img.width}×${img.height}`,
            newSize: `${canvas.width}×${canvas.height}`,
            rotation: settings.angle,
            flipped: settings.flipHorizontal || settings.flipVertical
          };
        })
      );

      setRotatedFiles(rotated);
      setProgress(100);
    } catch (error) {
      console.error('Rotation error:', error);
      setErrorMessage(`Rotation failed: ${error.message}`);
    } finally {
      setIsProcessing(false);
    }
  };

  const downloadFile = (file) => {
    const link = document.createElement('a');
    link.href = file.dataUrl;
    link.download = file.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleQuickRotate = (degrees) => {
    setSettings(prev => ({
      ...prev,
      angle: (prev.angle + degrees) % 360
    }));
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <div className="max-w-4xl mx-auto px-4">
        <div className="text-center mb-8">
          <h1 className="text-3xl lg:text-4xl font-bold mb-2 text-white">
            {translations.staticText.title}
          </h1>
          <p className="text-sm text-gray-300">
            {translations.staticText.subtitle}
          </p>
        </div>

        <div className="mb-8">
          <div
            className="border-2 border-dashed border-gray-600 rounded-lg p-6 text-center cursor-pointer bg-gray-700 flex flex-col justify-center items-center transition-colors duration-200 hover:border-[#F7AA01]/50"
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onClick={() => document.getElementById('fileInput').click()}
          >
            <Upload size={48} className="text-gray-300 mb-4" />
            <p className="text-gray-300">{translations.staticText.dragDropText}</p>
            <p className="text-gray-300">{translations.staticText.orText}</p>
            <button className="bg-[#F7AA01] text-[#2F2F30] px-4 py-2 rounded-lg mt-2 flex items-center gap-2 hover:bg-[#F7AA01]/80 transition-colors duration-200">
              <Upload size={20} />
              {translations.staticText.selectImagesButton}
            </button>
            <input
              id="fileInput"
              type="file"
              onChange={handleFileChange}
              accept="image/*"
              multiple
              className="hidden"
            />
          </div>
        </div>

        {selectedFiles.length > 0 && (
          <div className="mb-8">
            <h2 className="text-xl font-semibold text-white mb-4">
              {translations.staticText.quickActionsTitle}
            </h2>
            <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
              <button
                onClick={() => handleQuickRotate(-90)}
                className="flex flex-col items-center gap-2 p-4 bg-gray-700 rounded-lg hover:bg-gray-600 transition-colors duration-200"
              >
                <RotateCcw size={24} className="text-[#F7AA01]" />
                <span className="text-white">{translations.staticText.rotateLeftButton}</span>
              </button>
              <button
                onClick={() => handleQuickRotate(90)}
                className="flex flex-col items-center gap-2 p-4 bg-gray-700 rounded-lg hover:bg-gray-600 transition-colors duration-200"
              >
                <RotateCw size={24} className="text-[#F7AA01]" />
                <span className="text-white">{translations.staticText.rotateRightButton}</span>
              </button>
              <button
                onClick={() => setSettings(prev => ({ ...prev, flipHorizontal: !prev.flipHorizontal }))}
                className={`flex flex-col items-center gap-2 p-4 rounded-lg transition-colors duration-200 ${
                  settings.flipHorizontal ? 'bg-[#F7AA01] text-[#2F2F30]' : 'bg-gray-700 text-white hover:bg-gray-600'
                }`}
              >
                <FlipHorizontal size={24} />
                <span>{translations.staticText.flipHorizontalButton}</span>
              </button>
              <button
                onClick={() => setSettings(prev => ({ ...prev, flipVertical: !prev.flipVertical }))}
                className={`flex flex-col items-center gap-2 p-4 rounded-lg transition-colors duration-200 ${
                  settings.flipVertical ? 'bg-[#F7AA01] text-[#2F2F30]' : 'bg-gray-700 text-white hover:bg-gray-600'
                }`}
              >
                <FlipVertical size={24} />
                <span>{translations.staticText.flipVerticalButton}</span>
              </button>
            </div>
          </div>
        )}
        <div className="mb-8">
          <h2 className="text-xl font-semibold text-white mb-4">
            {translations.staticText.advancedSettingsTitle}
          </h2>
          <div className="space-y-6 bg-gray-700 p-6 rounded-lg">
            <div>
              <label className="block text-white mb-2">
                {translations.staticText.customAngleLabel}
              </label>
              <div className="flex items-center gap-4">
                <input
                  type="range"
                  min="0"
                  max="360"
                  value={settings.angle}
                  onChange={(e) => setSettings({ ...settings, angle: parseInt(e.target.value) })}
                  className="flex-grow"
                />
                <input
                  type="number"
                  min="0"
                  max="360"
                  value={settings.angle}
                  onChange={(e) => setSettings({ ...settings, angle: parseInt(e.target.value) || 0 })}
                  className="w-20 p-2 bg-gray-600 text-white rounded-lg"
                />
              </div>
            </div>

            <div className="flex items-center justify-between">
              <span className="text-white">{translations.staticText.maintainSizeLabel}</span>
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  checked={settings.maintainSize}
                  onChange={(e) => setSettings({ ...settings, maintainSize: e.target.checked })}
                  className="sr-only peer"
                />
                <div className="w-11 h-6 bg-gray-600 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-[#F7AA01]/50 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#F7AA01]"></div>
              </label>
            </div>

            <div className="flex items-center justify-between">
              <span className="text-white">{translations.staticText.autoRotateLabel}</span>
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  checked={settings.autoRotate}
                  onChange={(e) => setSettings({ ...settings, autoRotate: e.target.checked })}
                  className="sr-only peer"
                />
                <div className="w-11 h-6 bg-gray-600 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-[#F7AA01]/50 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#F7AA01]"></div>
              </label>
            </div>
          </div>
        </div>

        {selectedFiles.length > 0 && (
          <div className="mb-8">
            <h2 className="text-xl font-semibold text-white mb-4">
              {translations.staticText.selectedFilesTitle}
            </h2>
            <div className="space-y-4">
              {selectedFiles.map((file, index) => (
                <div key={index} className="bg-gray-700 p-4 rounded-lg">
                  <div className="flex justify-between items-center mb-4">
                    <span className="truncate text-white">{file.name}</span>
                    <div className="flex items-center gap-3">
                      <span className="text-gray-300 text-sm">
                        {(file.size / 1024).toFixed(1)} KB
                      </span>
                      <button
                        onClick={() => removeFile(index)}
                        className="text-red-500 hover:text-red-400 transition-colors duration-200"
                        title={translations.staticText.deleteTitle}
                      >
                        <Trash2 size={20} />
                      </button>
                    </div>
                  </div>
                  {previews[file.name] && (
                    <div className="relative w-full h-48 bg-gray-800 rounded-lg overflow-hidden">
                      <img
                        src={previews[file.name]}
                        alt={file.name}
                        className="w-full h-full object-contain"
                        style={{
                          transform: `
                            rotate(${settings.angle}deg)
                            scaleX(${settings.flipHorizontal ? -1 : 1})
                            scaleY(${settings.flipVertical ? -1 : 1})
                          `
                        }}
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}

        <div className="mb-8 flex justify-center">
          <button
            onClick={rotateImage}
            disabled={isProcessing || selectedFiles.length === 0}
            className={`bg-[#F7AA01] text-[#2F2F30] px-6 py-3 rounded-lg flex items-center gap-2 hover:bg-[#F7AA01]/80 transition-colors duration-200 ${
              (isProcessing || selectedFiles.length === 0) ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
            {isProcessing ? (
              <>
                <RotateCw className="animate-spin" size={20} />
                {translations.staticText.processingText}
              </>
            ) : (
              <>
                <RotateCw size={20} />
                {translations.staticText.applyRotationButton}
              </>
            )}
          </button>
        </div>

        {progress > 0 && progress < 100 && (
          <div className="mb-8">
            <div className="relative w-full bg-gray-200 rounded-lg overflow-hidden h-4">
              <div
                className="absolute top-0 left-0 h-full bg-[#F7AA01] transition-all duration-300"
                style={{ width: `${progress}%` }}
              />
            </div>
            <p className="text-center mt-2 text-white">{progress}%</p>
          </div>
        )}

        {errorMessage && (
          <div className="mb-8 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded-lg flex items-center gap-2">
            <AlertTriangle size={20} />
            <span>{errorMessage}</span>
          </div>
        )}

        {rotatedFiles.length > 0 && (
          <div className="mb-8">
            <h2 className="text-xl font-semibold text-white mb-4">
              {translations.staticText.rotatedFilesTitle}
            </h2>
            <div className="space-y-4">
              {rotatedFiles.map((file, index) => (
                <div key={index} className="bg-gray-700 p-4 rounded-lg">
                  <div className="flex justify-between items-center mb-4">
                    <span className="truncate text-white">{file.name}</span>
                    <div className="flex items-center gap-3">
                      <button
                        onClick={() => downloadFile(file)}
                        className="text-[#F7AA01] hover:text-[#F7AA01]/80 transition-colors duration-200"
                        title={translations.staticText.downloadTitle}
                      >
                        <Download size={20} />
                      </button>
                      <button
                        onClick={() => removeRotatedFile(index)}
                        className="text-red-500 hover:text-red-400 transition-colors duration-200"
                        title={translations.staticText.deleteTitle}
                      >
                        <Trash2 size={20} />
                      </button>
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-4 mb-4">
                    <div className="relative">
                      <img 
                        src={previews[selectedFiles[index]?.name]} 
                        alt="Original" 
                        className="w-full h-48 object-contain rounded-lg bg-gray-800"
                      />
                      <span className="absolute bottom-2 left-2 bg-gray-900/80 text-white px-2 py-1 rounded text-sm">
                        {translations.staticText.originalLabel}
                      </span>
                    </div>
                    <div className="relative">
                      <img 
                        src={file.dataUrl} 
                        alt="Rotated" 
                        className="w-full h-48 object-contain rounded-lg bg-gray-800"
                      />
                      <span className="absolute bottom-2 left-2 bg-gray-900/80 text-white px-2 py-1 rounded text-sm">
                        {translations.staticText.rotatedLabel}
                      </span>
                    </div>
                  </div>
                  <div className="grid grid-cols-2 md:grid-cols-4 gap-2 text-sm">
                    <div className="text-gray-300">
                      {translations.staticText.originalSizeLabel}: {file.originalSize}
                    </div>
                    <div className="text-gray-300">
                      {translations.staticText.newLabel}: {file.newSize}
                    </div>
                    <div className="text-gray-300">
                      {translations.staticText.rotationLabel}: {file.rotation}°
                    </div>
                    {file.flipped && (
                      <div className="text-gray-300">
                        {translations.staticText.flippedLabel}
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      <Documentation tool="rotate" />
    </>
  );
};

export default ImageRotate;