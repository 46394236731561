// src/components/analytics/BreadcrumbsAnalytics.js
import React, { useState, useEffect } from 'react';
import { BarChart, Bar, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const BreadcrumbsAnalytics = () => {
  const [analyticsData, setAnalyticsData] = useState({
    navigationTimes: [],
    clickCounts: {},
    performance: []
  });

  useEffect(() => {
    // Get performance data
    const observer = new PerformanceObserver((list) => {
      list.getEntries().forEach((entry) => {
        if (entry.name.startsWith('Navigation to')) {
          setAnalyticsData(prev => ({
            ...prev,
            navigationTimes: [...prev.navigationTimes, {
              page: entry.name.replace('Navigation to ', ''),
              time: entry.duration
            }],
            performance: [...prev.performance, {
              metric: entry.name,
              value: entry.duration
            }]
          }));
        }
      });
    });

    observer.observe({ entryTypes: ['measure'] });

    return () => observer.disconnect();
  }, []);

  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      <h2 className="text-2xl font-bold text-white mb-6">Breadcrumbs Analytics</h2>
      
      {/* Navigation Times Chart */}
      <div className="bg-gray-800 p-6 rounded-lg mb-8">
        <h3 className="text-lg font-semibold text-white mb-4">Navigation Times</h3>
        <div className="h-64">
          <BarChart
            width={800}
            height={240}
            data={analyticsData.navigationTimes}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="page" />
            <YAxis />
            <Tooltip contentStyle={{ backgroundColor: '#1f2937' }} />
            <Legend />
            <Bar dataKey="time" fill="#F7AA01" name="Navigation Time (ms)" />
          </BarChart>
        </div>
      </div>

      {/* Performance Metrics */}
      <div className="bg-gray-800 p-6 rounded-lg mb-8">
        <h3 className="text-lg font-semibold text-white mb-4">Performance Metrics</h3>
        <div className="h-64">
          <LineChart
            width={800}
            height={240}
            data={analyticsData.performance}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="metric" />
            <YAxis />
            <Tooltip contentStyle={{ backgroundColor: '#1f2937' }} />
            <Legend />
            <Line type="monotone" dataKey="value" stroke="#F7AA01" name="Duration (ms)" />
          </LineChart>
        </div>
      </div>

      {/* Detailed Stats */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="bg-gray-800 p-6 rounded-lg">
          <h3 className="text-lg font-semibold text-white mb-4">Navigation Statistics</h3>
          <div className="space-y-2">
            <p className="text-gray-300">
              Average Navigation Time: {' '}
              <span className="text-[#F7AA01] font-medium">
                {(analyticsData.navigationTimes.reduce((acc, curr) => acc + curr.time, 0) / 
                  analyticsData.navigationTimes.length || 0).toFixed(2)}ms
              </span>
            </p>
            <p className="text-gray-300">
              Total Navigations: {' '}
              <span className="text-[#F7AA01] font-medium">
                {analyticsData.navigationTimes.length}
              </span>
            </p>
          </div>
        </div>

        <div className="bg-gray-800 p-6 rounded-lg">
          <h3 className="text-lg font-semibold text-white mb-4">Performance Summary</h3>
          <div className="space-y-2">
            <p className="text-gray-300">
              Average Processing Time: {' '}
              <span className="text-[#F7AA01] font-medium">
                {(analyticsData.performance.reduce((acc, curr) => acc + curr.value, 0) / 
                  analyticsData.performance.length || 0).toFixed(2)}ms
              </span>
            </p>
            <p className="text-gray-300">
              Total Metrics Collected: {' '}
              <span className="text-[#F7AA01] font-medium">
                {analyticsData.performance.length}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BreadcrumbsAnalytics;