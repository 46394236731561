export const supportedLanguages = [
    {
      code: 'en',
      name: 'English',
      flag: '🇺🇸'
    },
    {
      code: 'id',
      name: 'Indonesia',
      flag: '🇮🇩'
    },
    {
      code: 'es',
      name: 'Español',
      flag: '🇪🇸'
    },
    {
      code: 'fr',
      name: 'Français',
      flag: '🇫🇷'
    },
    {
      code: 'de',
      name: 'Deutsch',
      flag: '🇩🇪'
    },
    {
      code: 'ja',
      name: '日本語',
      flag: '🇯🇵'
    },
    {
      code: 'ko',
      name: '한국어',
      flag: '🇰🇷'
    },
    {
      code: 'zh',
      name: '中文',
      flag: '🇨🇳'
    },
    {
      code: 'ar',
      name: 'العربية',
      flag: '🇸🇦'
    },
    {
      code: 'hi',
      name: 'हिन्दी',
      flag: '🇮🇳'
    },
    {
      code: 'pt',
      name: 'Português',
      flag: '🇧🇷'
    },
    {
      code: 'ru',
      name: 'Русский',
      flag: '🇷🇺'
    }
  ];
  
  export const getLanguageName = (code) => {
    const language = supportedLanguages.find(lang => lang.code === code);
    return language ? language.name : 'English';
  };
  
  export const getLanguageFlag = (code) => {
    const language = supportedLanguages.find(lang => lang.code === code);
    return language ? language.flag : '🌐';
  };