import React, { useState, useEffect } from 'react';
import { Mail, ExternalLink, AlertTriangle } from 'lucide-react';
import useTranslate from '../hooks/useTranslate';

const ContactUs = () => {
  const { translateText, currentLanguage } = useTranslate();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  const [status, setStatus] = useState(null);
  const [translations, setTranslations] = useState({});

  useEffect(() => {
    const translateContactUs = async () => {
      try {
        const translatedContent = {
          title: await translateText('Contact Us', currentLanguage),
          getInTouchTitle: await translateText('Get in Touch', currentLanguage),
          getInTouchDescription: await translateText('Have a question or feedback? We\'d love to hear from you. Fill out the form and we\'ll get back to you as soon as possible.', currentLanguage),
          emailLabel: await translateText('Email', currentLanguage),
          emailAddress: await translateText('contact@imgtoollab.com', currentLanguage),
          socialMediaLabel: await translateText('Social Media', currentLanguage),
          socialMediaDescription: await translateText('Follow us on Instagram: @imgtoollab', currentLanguage),
          nameLabel: await translateText('Name', currentLanguage),
          emailLabel: await translateText('Email', currentLanguage),
          subjectLabel: await translateText('Subject', currentLanguage),
          messageLabel: await translateText('Message', currentLanguage),
          sendButtonLabel: await translateText('Send Message', currentLanguage),
          sendingLabel: await translateText('Sending...', currentLanguage),
          successMessage: await translateText('Message sent successfully!', currentLanguage),
          errorMessage: await translateText('Failed to send message. Please try again.', currentLanguage)
        };
        setTranslations(translatedContent);
      } catch (error) {
        console.error('Translation error:', error);
        // Fallback to English if translation fails
        setTranslations({
          title: 'Contact Us',
          getInTouchTitle: 'Get in Touch',
          getInTouchDescription: 'Have a question or feedback? We\'d love to hear from you. Fill out the form and we\'ll get back to you as soon as possible.',
          emailLabel: 'Email',
          emailAddress: 'contact@imgtoollab.com',
          socialMediaLabel: 'Social Media',
          socialMediaDescription: 'Follow us on Instagram: @imgtoollab',
          nameLabel: 'Name',
          emailLabel: 'Email',
          subjectLabel: 'Subject',
          messageLabel: 'Message',
          sendButtonLabel: 'Send Message',
          sendingLabel: 'Sending...',
          successMessage: 'Message sent successfully!',
          errorMessage: 'Failed to send message. Please try again.'
        });
      }
    };

    translateContactUs();
  }, [translateText, currentLanguage]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus('sending');

    try {
      const whatsappMessage = encodeURIComponent(`Hallo ImgToolLab.com\nName: ${formData.name}\nEmail: ${formData.email}\nSubject: ${formData.subject}\nMessage: ${formData.message}`);
      const whatsappLink = `https://wa.me/6287761606176?text=${whatsappMessage}`;
      window.open(whatsappLink, '_blank');
      
      setStatus('success');
      setFormData({ name: '', email: '', subject: '', message: '' });
    } catch (error) {
      setStatus('error');
    }
  };

  return (
    <div className="max-w-4xl mx-auto px-4 py-12">
      <div className="flex items-center justify-center gap-4 mb-8">
        <Mail className="w-12 h-12 text-[#F7AA01]" />
        <h1 className="text-4xl font-bold text-white">{translations.title}</h1>
      </div>

      <div className="grid md:grid-cols-2 gap-8">
        <div className="bg-gray-800 rounded-lg p-6">
          <h2 className="text-2xl font-semibold text-white mb-4">{translations.getInTouchTitle}</h2>
          <p className="text-gray-300 leading-relaxed mb-6">
            {translations.getInTouchDescription}
          </p>
          
          <div className="space-y-4">
            <div>
              <h3 className="text-lg font-semibold text-white mb-2">{translations.emailLabel}</h3>
              <p className="text-gray-300">{translations.emailAddress}</p>
            </div>
            <div>
              <h3 className="text-lg font-semibold text-white mb-2">{translations.socialMediaLabel}</h3>
              <p className="text-gray-300">{translations.socialMediaDescription}</p>
            </div>
          </div>
        </div>

        <div className="bg-gray-800 rounded-lg p-6">
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label className="block text-white mb-2" htmlFor="name">{translations.nameLabel}</label>
              <input
                type="text"
                id="name"
                value={formData.name}
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                className="w-full p-2 rounded-lg bg-gray-700 text-white border border-gray-600 focus:border-[#F7AA01] focus:ring-1 focus:ring-[#F7AA01] outline-none"
                required
              />
            </div>
            
            <div>
              <label className="block text-white mb-2" htmlFor="email">{translations.emailLabel}</label>
              <input
                type="email"
                id="email"
                value={formData.email}
                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                className="w-full p-2 rounded-lg bg-gray-700 text-white border border-gray-600 focus:border-[#F7AA01] focus:ring-1 focus:ring-[#F7AA01] outline-none"
                required
              />
            </div>
            
            <div>
              <label className="block text-white mb-2" htmlFor="subject">{translations.subjectLabel}</label>
              <input
                type="text"
                id="subject"
                value={formData.subject}
                onChange={(e) => setFormData({ ...formData, subject: e.target.value })}
                className="w-full p-2 rounded-lg bg-gray-700 text-white border border-gray-600 focus:border-[#F7AA01] focus:ring-1 focus:ring-[#F7AA01] outline-none"
                required
              />
            </div>
            
            <div>
              <label className="block text-white mb-2" htmlFor="message">{translations.messageLabel}</label>
              <textarea
                id="message"
                rows="5"
                value={formData.message}
                onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                className="w-full p-2 rounded-lg bg-gray-700 text-white border border-gray-600 focus:border-[#F7AA01] focus:ring-1 focus:ring-[#F7AA01] outline-none"
                required
              ></textarea>
            </div>

            <button
              type="submit"
              disabled={status === 'sending'}
              className={`w-full bg-[#F7AA01] text-[#2F2F30] py-3 rounded-lg flex items-center justify-center gap-2 hover:bg-[#F7AA01]/80 transition-colors duration-200 ${
                status === 'sending' ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            >
              {status === 'sending' ? (
                <>
                  <ExternalLink className="w-5 h-5 animate-spin" />
                  {translations.sendingLabel}
                </>
              ) : (
                <>
                  <ExternalLink className="w-5 h-5" />
                  {translations.sendButtonLabel}
                </>
              )}
            </button>

            {status === 'success' && (
              <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded-lg flex items-center gap-2">
                {translations.successMessage}
              </div>
            )}

            {status === 'error' && (
              <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded-lg flex items-center gap-2">
                <AlertTriangle size={20} />
                {translations.errorMessage}
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;