import React, { useState, useEffect, useCallback } from 'react';
import { ChevronDown, ChevronUp, HelpCircle, CheckCircle2 } from 'lucide-react';
import useTranslate from '../../hooks/useTranslate';

const Documentation = ({ tool }) => {
  const { translateText, currentLanguage } = useTranslate();
  const [openSection, setOpenSection] = useState('overview');
  const [translations, setTranslations] = useState({
    title: 'Documentation',
    overview: 'Overview',
    howToUse: 'How to Use',
    tipsAndBestPractices: 'Tips & Best Practices',
    limitations: 'Limitations'
  });
  const [documentationContent, setDocumentationContent] = useState({});

  // Helper function to translate content
  const translateContent = useCallback(async (content) => {
    const translated = {};
    for (const key in content) {
      if (typeof content[key] === 'string') {
        translated[key] = await translateText(content[key], currentLanguage);
      } else if (Array.isArray(content[key])) {
        translated[key] = await Promise.all(
          content[key].map(item => translateText(item, currentLanguage))
        );
      } else if (typeof content[key] === 'object') {
        translated[key] = await translateContent(content[key]);
      } else {
        translated[key] = content[key];
      }
    }
    return translated;
  }, [translateText, currentLanguage]);

  // Load and translate documentation content
  useEffect(() => {
    const loadDocumentation = async () => {
      try {
        // Translate section titles
        const translatedTitles = await translateContent({
          title: 'Documentation',
          overview: 'Overview',
          howToUse: 'How to Use',
          tipsAndBestPractices: 'Tips & Best Practices',
          limitations: 'Limitations'
        });
        setTranslations(translatedTitles);

        // Define base content structure
        const baseContent = {
          converter: {
            title: 'Image Converter',
            overview: {
              description: 'Convert images between different formats with ease. Support for PNG, JPEG, WEBP, PDF, and SVG formats.',
              features: [
                'Multiple file conversion at once',
                'High-quality output formats',
                'Preserve image quality during conversion',
                'Batch processing capabilities',
                'Support for various input formats'
              ]
            },
            howTo: [
              'Drag and drop your images or click to select files',
              'Choose your desired output format',
              'Adjust quality settings if needed',
              'Click "Convert" to process your images',
              'Download your converted files'
            ],
            tips: [
              'Use PNG for images with transparency',
              'Choose JPEG for photographs',
              'WEBP offers good compression with quality',
              'PDF is ideal for document sharing',
              'SVG is perfect for logos and icons'
            ],
            limitations: [
              'Maximum file size: 50MB per image',
              'Up to 10 files can be converted simultaneously',
              'Some format conversions may affect quality',
              'SVG conversion works best with simple images'
            ]
          },
          compress: {
            title: await translateText('Image Compressor', currentLanguage),
            overview: {
              description: await translateText('Reduce image file sizes while maintaining optimal quality. Perfect for web use and sharing.', currentLanguage),
              features: [
                await translateText('Adjustable compression levels', currentLanguage),
                await translateText('Batch compression support', currentLanguage),
                await translateText('Size reduction preview', currentLanguage),
                await translateText('Quality control slider', currentLanguage),
                await translateText('Original vs compressed comparison', currentLanguage)
              ]
            },
            howTo: [
              await translateText('Upload your images through drag & drop or file selection', currentLanguage),
              await translateText('Adjust the quality slider (higher = better quality but larger file)', currentLanguage),
              await translateText('Click "Compress" to start processing', currentLanguage),
              await translateText('Review the compression results', currentLanguage),
              await translateText('Download your compressed images', currentLanguage)
            ],
            tips: [
              await translateText('Use 80-90% quality for best balance', currentLanguage),
              await translateText('Lower quality is suitable for thumbnails', currentLanguage),
              await translateText('Consider the image\'s end-use when selecting quality', currentLanguage),
              await translateText('Compare file sizes before downloading', currentLanguage),
              await translateText('Batch process similar images together', currentLanguage)
            ],
            limitations: [
              await translateText('Maximum input file size: 50MB', currentLanguage),
              await translateText('Some images may not compress significantly', currentLanguage),
              await translateText('Very low quality settings may cause visible artifacts', currentLanguage),
              await translateText('Compression results vary by image type', currentLanguage)
            ]
          },
          rotate: {
            title: await translateText('Image Rotator', currentLanguage),
            overview: {
              description: await translateText('Rotate and flip images with precision. Perfect for fixing image orientation and creating variations.', currentLanguage),
              features: [
                await translateText('Custom rotation angles', currentLanguage),
                await translateText('Quick 90° rotations', currentLanguage),
                await translateText('Horizontal and vertical flipping', currentLanguage),
                await translateText('Batch processing support', currentLanguage),
                await translateText('Preview before applying', currentLanguage)
              ]
            },
            howTo: [
              await translateText('Upload your image(s)', currentLanguage),
              await translateText('Use quick actions or set custom angle', currentLanguage),
              await translateText('Toggle flip options if needed', currentLanguage),
              await translateText('Adjust advanced settings', currentLanguage),
              await translateText('Preview changes and apply rotation', currentLanguage),
              await translateText('Download rotated images', currentLanguage)
            ],
            tips: [
              await translateText('Use quick actions for common rotations', currentLanguage),
              await translateText('Enable auto-detect for automatic orientation', currentLanguage),
              await translateText('Maintain original size for consistent dimensions', currentLanguage),
              await translateText('Preview changes before processing', currentLanguage),
              await translateText('Batch process similar rotations', currentLanguage)
            ],
            limitations: [
              await translateText('Maximum file size: 50MB', currentLanguage),
              await translateText('Some quality loss may occur with multiple rotations', currentLanguage),
              await translateText('Auto-detect may not work for all images', currentLanguage),
              await translateText('Processing time increases with larger files', currentLanguage)
            ]
          },
          watermark: {
            title: await translateText('Add Watermark', currentLanguage),
            overview: {
              description: await translateText('Add text or image watermarks to your photos with full customization options.', currentLanguage),
              features: [
                await translateText('Text and image watermark support', currentLanguage),
                await translateText('Multiple positioning options', currentLanguage),
                await translateText('Opacity and rotation control', currentLanguage),
                await translateText('Pattern repeat option', currentLanguage),
                await translateText('Custom fonts and colors', currentLanguage)
              ]
            },
            howTo: [
              await translateText('Upload your image(s)', currentLanguage),
              await translateText('Choose watermark type (text or image)', currentLanguage),
              await translateText('Customize watermark appearance', currentLanguage),
              await translateText('Set position and style options', currentLanguage),
              await translateText('Preview and download results', currentLanguage)
            ],
            tips: [
              await translateText('Use semi-transparent watermarks', currentLanguage),
              await translateText('Position away from key image elements', currentLanguage),
              await translateText('Consider using pattern repeat for security', currentLanguage),
              await translateText('Test different fonts for text watermarks', currentLanguage),
              await translateText('Adjust size and rotation for best fit', currentLanguage)
            ],
            limitations: [
              await translateText('Maximum file size: 50MB', currentLanguage),
              await translateText('Supported formats: JPG, PNG', currentLanguage),
              await translateText('Text watermark maximum length: 50 characters', currentLanguage),
              await translateText('Some fonts may not be available', currentLanguage)
            ]
          },
          removebg: {
            title: await translateText('Background Remover', currentLanguage),
            overview: {
              description: await translateText('Automatically remove backgrounds from images with advanced AI detection.', currentLanguage),
              features: [
                await translateText('Smart background detection', currentLanguage),
                await translateText('Edge refinement technology', currentLanguage),
                await translateText('Adjustable tolerance levels', currentLanguage),
                await translateText('Batch processing support', currentLanguage),
                await translateText('Preview with transparency grid', currentLanguage)
              ]
            },
            howTo: [
              await translateText('Upload your image(s)', currentLanguage),
              await translateText('Adjust tolerance level if needed', currentLanguage),
              await translateText('Toggle additional settings like feathering', currentLanguage),
              await translateText('Click "Remove Background" to process', currentLanguage),
              await translateText('Preview and download results', currentLanguage)
            ],
            tips: [
              await translateText('Use higher tolerance for complex backgrounds', currentLanguage),
              await translateText('Enable edge feathering for natural results', currentLanguage),
              await translateText('Preserve details for important edges', currentLanguage),
              await translateText('Enhance results for final touches', currentLanguage),
              await translateText('Preview results before downloading', currentLanguage)
            ],
            limitations: [
              await translateText('Maximum file size: 50MB', currentLanguage),
              await translateText('Complex backgrounds may need manual adjustment', currentLanguage),
              await translateText('Processing time varies with image size', currentLanguage),
              await translateText('Some fine details may be affected', currentLanguage)
            ]
          },
          upscale: {
            title: await translateText('Image Upscaler', currentLanguage),
            overview: {
              description: await translateText('Enlarge images while maintaining visual quality. Perfect for increasing image resolution without losing details.', currentLanguage),
              features: [
                await translateText('Multiple upscaling factors (2x, 3x, 4x, custom)', currentLanguage),
                await translateText('Detail preservation technology', currentLanguage),
                await translateText('Noise reduction options', currentLanguage),
                await translateText('Batch processing support', currentLanguage),
                await translateText('High-quality upscaling algorithms', currentLanguage)
              ]
            },
            howTo: [
              await translateText('Upload your image(s) through drag & drop or file selection', currentLanguage),
              await translateText('Choose upscaling factor (2x, 3x, 4x, or custom)', currentLanguage),
              await translateText('Enable detail preservation if needed', currentLanguage),
              await translateText('Adjust denoise strength if required', currentLanguage),
              await translateText('Click "Upscale Images" to process', currentLanguage),
              await translateText('Download your upscaled images', currentLanguage)
            ],
            tips: [
              await translateText('Use detail preservation for photos and complex images', currentLanguage),
              await translateText('Apply denoising for smoother results', currentLanguage),
              await translateText('Consider file size limits when choosing scale factor', currentLanguage),
              await translateText('Preview results before downloading', currentLanguage),
              await translateText('Use batch processing for multiple similar images', currentLanguage)
            ],
            limitations: [
              await translateText('Maximum input file size: 50MB', currentLanguage),
              await translateText('Maximum upscale factor: 8x', currentLanguage),
              await translateText('Processing time increases with larger images', currentLanguage),
              await translateText('Higher upscaling may affect image quality', currentLanguage),
              await translateText('Memory usage increases with scale factor', currentLanguage)
            ]
          },
          resize: {
            title: await translateText('Image Resizer', currentLanguage),
            overview: {
              description: await translateText('Resize your images with precision. Support for custom dimensions, percentage scaling, and common social media presets.', currentLanguage),
              features: [
                await translateText('Multiple resize methods (pixels, percentage, presets)', currentLanguage),
                await translateText('Maintain aspect ratio option', currentLanguage),
                await translateText('Common social media size presets', currentLanguage),
                await translateText('Batch processing support', currentLanguage),
                await translateText('High-quality resizing algorithm', currentLanguage)
              ]
            },
            howTo: [
              await translateText('Upload your images by dragging & dropping or selecting files', currentLanguage),
              await translateText('Choose your preferred resize method (pixels, percentage, or preset)', currentLanguage),
              await translateText('Set your desired dimensions or scale', currentLanguage),
              await translateText('Toggle aspect ratio lock if needed', currentLanguage),
              await translateText('Click "Resize Images" to process', currentLanguage),
              await translateText('Download your resized images', currentLanguage)
            ],
            tips: [
              await translateText('Keep aspect ratio locked to prevent image distortion', currentLanguage),
              await translateText('Use percentage scaling for proportional resizing', currentLanguage),
              await translateText('Social media presets ensure perfect dimensions for each platform', currentLanguage),
              await translateText('Batch process multiple images with the same settings', currentLanguage),
              await translateText('Preview dimensions before processing', currentLanguage)
            ],
            limitations: [
              await translateText('Maximum input file size: 50MB', currentLanguage),
              await translateText('Maximum output dimensions: 8000x8000 pixels', currentLanguage),
              await translateText('Some image quality loss may occur when resizing significantly', currentLanguage),
              await translateText('Processing time increases with larger images', currentLanguage)
            ]
          },
          crop: {
            title: await translateText('Image Cropper', currentLanguage),
            overview: {
              description: await translateText('Crop your images with precision using our interactive visual editor. Perfect for creating custom image sections and social media posts.', currentLanguage),
              features: [
                await translateText('Visual crop editor with live preview', currentLanguage),
                await translateText('Multiple aspect ratio presets', currentLanguage),
                await translateText('Custom free-form cropping', currentLanguage),
                await translateText('Batch processing support', currentLanguage),
                await translateText('Social media optimized presets', currentLanguage)
              ]
            },
            howTo: [
              await translateText('Upload your image(s) through drag & drop or file selection', currentLanguage),
              await translateText('Select an aspect ratio preset or use free-form cropping', currentLanguage),
              await translateText('Adjust the crop area visually', currentLanguage),
              await translateText('Click "Crop Image" to process', currentLanguage),
              await translateText('Download your cropped image', currentLanguage),
              await translateText('Continue with next image if batch processing', currentLanguage)
            ],
            tips: [
              await translateText('Use aspect ratio presets for consistent results', currentLanguage),
              await translateText('Drag corners for precise adjustments', currentLanguage),
              await translateText('Center important subjects in the crop area', currentLanguage),
              await translateText('Preview before confirming crop', currentLanguage),
              await translateText('Use keyboard arrows for fine-tuning', currentLanguage)
            ],
            limitations: [
              await translateText('Maximum input file size: 50MB', currentLanguage),
              await translateText('One image processed at a time', currentLanguage),
              await translateText('Some formats may have cropping restrictions', currentLanguage),
              await translateText('Minimum crop size: 10x10 pixels', currentLanguage)
            ]
          }
        };

        const translatedContent = await translateContent(baseContent);
        setDocumentationContent(translatedContent);
      } catch (error) {
        console.error('Documentation translation error:', error);
        // Define fallback content
        const fallbackContent = {
          converter: {
            title: 'Image Converter',
            overview: {
              description: 'Convert images between different formats with ease. Support for PNG, JPEG, WEBP, PDF, and SVG formats.',
              features: [
                'Multiple file conversion at once',
                'High-quality output formats',
                'Preserve image quality during conversion',
                'Batch processing capabilities',
                'Support for various input formats'
              ]
            },
            howTo: [
              'Drag and drop your images or click to select files',
              'Choose your desired output format',
              'Adjust quality settings if needed',
              'Click "Convert" to process your images',
              'Download your converted files'
            ],
            tips: [
              'Use PNG for images with transparency',
              'Choose JPEG for photographs',
              'WEBP offers good compression with quality',
              'PDF is ideal for document sharing',
              'SVG is perfect for logos and icons'
            ],
            limitations: [
              'Maximum file size: 50MB per image',
              'Up to 10 files can be converted simultaneously',
              'Some format conversions may affect quality',
              'SVG conversion works best with simple images'
            ]
          }
        };
        setDocumentationContent(fallbackContent);
      }
    };

    loadDocumentation();
  }, [translateContent]);

  const DocSection = ({ title, content, isOpen, onClick }) => (
    <div className="mb-4">
      <button
        onClick={onClick}
        className="w-full flex justify-between items-center p-4 md:p-5 bg-gray-700 rounded-lg text-white hover:bg-gray-600 transition-colors duration-200"
      >
        <span className="font-medium text-sm md:text-base">{title}</span>
        {isOpen ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
      </button>
      {isOpen && (
        <div className="mt-2 p-4 md:p-6 bg-gray-800 rounded-lg">
          {Array.isArray(content) ? (
            <ul className="space-y-3 md:space-y-4">
              {content.map((item, index) => (
                <li key={index} className="flex items-start gap-3 text-gray-300">
                  <CheckCircle2 size={20} className="text-green-500 mt-1 flex-shrink-0" />
                  <span className="text-sm md:text-base">{item}</span>
                </li>
              ))}
            </ul>
          ) : typeof content === 'object' ? (
            <>
              <p className="text-gray-300 mb-4 text-sm md:text-base leading-relaxed">
                {content.description}
              </p>
              <ul className="space-y-3 md:space-y-4">
                {content.features.map((feature, index) => (
                  <li key={index} className="flex items-start gap-3 text-gray-300">
                    <CheckCircle2 size={20} className="text-green-500 mt-1 flex-shrink-0" />
                    <span className="text-sm md:text-base">{feature}</span>
                  </li>
                ))}
              </ul>
            </>
          ) : (
            <p className="text-gray-300 text-sm md:text-base">{content}</p>
          )}
        </div>
      )}
    </div>
  );

  const content = documentationContent[tool];
  if (!content) return null;

  return (
    <div className="mt-12 mb-6 px-4 md:px-6 lg:px-8 max-w-[1400px] mx-auto">
      <div className="bg-[#2F2F30] rounded-lg p-4 md:p-6 lg:p-8 shadow-xl">
        <div className="flex items-center gap-3 mb-6 border-b border-gray-700 pb-4">
          <HelpCircle size={24} className="text-[#F7AA01]" />
          <h2 className="text-xl md:text-2xl lg:text-3xl font-bold text-white">
            {translations.title}
          </h2>
        </div>
        
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-6">
          <div className="space-y-4">
            <DocSection
              title={translations.overview}
              content={content.overview}
              isOpen={openSection === 'overview'}
              onClick={() => setOpenSection(openSection === 'overview' ? '' : 'overview')}
            />
            
            <DocSection
              title={translations.howToUse}
              content={content.howTo}
              isOpen={openSection === 'howTo'}
              onClick={() => setOpenSection(openSection === 'howTo' ? '' : 'howTo')}
            />
          </div>
          
          <div className="space-y-4">
            <DocSection
              title={translations.tipsAndBestPractices}
              content={content.tips}
              isOpen={openSection === 'tips'}
              onClick={() => setOpenSection(openSection === 'tips' ? '' : 'tips')}
            />
            
            <DocSection
              title={translations.limitations}
              content={content.limitations}
              isOpen={openSection === 'limitations'}
              onClick={() => setOpenSection(openSection === 'limitations' ? '' : 'limitations')}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Documentation;