import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { 
  Instagram, Coffee, Mail, Heart,
  FileType, Globe
} from 'lucide-react';
import useTranslate from '../../hooks/useTranslate';

const Footer = () => {
  const { translateText, currentLanguage } = useTranslate();
  const [translations, setTranslations] = useState({});
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    const translateFooter = async () => {
      try {
        const translatedContent = {
          appName: await translateText('ImgToolLab', currentLanguage),
          description: await translateText('Free online tools for all your image editing needs. Simple, fast, and powerful.', currentLanguage),
          tools: await translateText('Tools', currentLanguage),
          convertImages: await translateText('Convert Images', currentLanguage),
          compressImages: await translateText('Compress Images', currentLanguage),
          resizeImages: await translateText('Resize Images', currentLanguage),
          cropImages: await translateText('Crop Images', currentLanguage),
          removeBackground: await translateText('Remove Background', currentLanguage),
          company: await translateText('Company', currentLanguage),
          aboutUs: await translateText('About Us', currentLanguage),
          privacyPolicy: await translateText('Privacy Policy', currentLanguage),
          termsOfService: await translateText('Terms of Service', currentLanguage),
          contactUs: await translateText('Contact Us', currentLanguage),
          supportUs: await translateText('Support Us', currentLanguage),
          supportDescription: await translateText('Help us keep our tools free and maintain the servers.', currentLanguage),
          buyMeCoffee: await translateText('Buy me a coffee', currentLanguage),
          madeWithLove: await translateText('Made with ❤️ by M.S.HASBI', currentLanguage),
          privacy: await translateText('Privacy', currentLanguage),
          terms: await translateText('Terms', currentLanguage),
          cookies: await translateText('Cookies', currentLanguage)
        };
        setTranslations(translatedContent);
      } catch (error) {
        console.error('Translation error:', error);
        // Fallback to English if translation fails
        setTranslations({
          appName: 'ImgToolLab',
          description: 'Free online tools for all your image editing needs. Simple, fast, and powerful.',
          tools: 'Tools',
          convertImages: 'Convert Images',
          compressImages: 'Compress Images',
          resizeImages: 'Resize Images',
          cropImages: 'Crop Images', 
          removeBackground: 'Remove Background',
          company: 'Company',
          aboutUs: 'About Us',
          privacyPolicy: 'Privacy Policy',
          termsOfService: 'Terms of Service',
          contactUs: 'Contact Us',
          supportUs: 'Support Us',
          supportDescription: 'Help us keep our tools free and maintain the servers.',
          buyMeCoffee: 'Buy me a coffee',
          madeWithLove: 'Made with ❤️ by M.S.HASBI',
          privacy: 'Privacy',
          terms: 'Terms',
          cookies: 'Cookies'
        });
      }
    };

    translateFooter();
  }, [translateText, currentLanguage]);

  return (
    <footer className="bg-[#2F2F30] mt-auto">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="py-12 grid grid-cols-1 md:grid-cols-4 gap-8">
          <div>
            <div className="flex items-center gap-2 mb-4">
              <FileType className="w-8 h-8 text-[#F7AA01]" />
              <span className="text-xl font-bold text-white">{translations.appName}</span>
            </div>
            <p className="text-gray-300 mb-4">
              {translations.description}
            </p>
            <div className="flex space-x-4">
              <a 
                href="https://www.instagram.com/imgtoollab/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-[#F7AA01]"
              >
                <Instagram className="w-6 h-6" />
              </a>
              <a 
                href="#"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-[#F7AA01]"
              >
                <Globe className="w-6 h-6" />
              </a>
              <a 
                href="mailto:contact@imgtoollab.com"
                className="text-gray-400 hover:text-[#F7AA01]"
              >
                <Mail className="w-6 h-6" />
              </a>
            </div>
          </div>

          <div>
            <h3 className="text-white font-bold mb-4">{translations.tools}</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/convert" className="text-gray-300 hover:text-[#F7AA01]">
                  {translations.convertImages}
                </Link>
              </li>
              <li>
                <Link to="/compress" className="text-gray-300 hover:text-[#F7AA01]">
                  {translations.compressImages}
                </Link>
              </li>
              <li>
                <Link to="/resize" className="text-gray-300 hover:text-[#F7AA01]">
                  {translations.resizeImages}
                </Link>
              </li>
              <li>
                <Link to="/crop" className="text-gray-300 hover:text-[#F7AA01]">
                  {translations.cropImages}
                </Link>
              </li>
              <li>
                <Link to="/remove-background" className="text-gray-300 hover:text-[#F7AA01]">
                  {translations.removeBackground}
                </Link>
              </li>
            </ul>
          </div>

          <div>
            <h3 className="text-white font-bold mb-4">{translations.company}</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/about" className="text-gray-300 hover:text-[#F7AA01]">
                  {translations.aboutUs}
                </Link>
              </li>
              <li>
                <Link to="/contact" className="text-gray-300 hover:text-[#F7AA01]">
                  {translations.contactUs}
                </Link>
              </li>
              <li>
                <Link to="/privacy" className="text-gray-300 hover:text-[#F7AA01]">
                  {translations.privacyPolicy}
                </Link>
              </li>
              <li>
                <Link to="/terms" className="text-gray-300 hover:text-[#F7AA01]">
                  {translations.termsOfService}
                </Link>
              </li>
              <li>
                <Link to={"/cookies"} className="text-gray-300 hover:text-[#F7AA01] text-sm">
                  {translations.cookies}
                </Link>
              </li>
            </ul>
          </div>

          <div>
            <h3 className="text-white font-bold mb-4">{translations.supportUs}</h3>
            <p className="text-gray-300 mb-4">
              {translations.supportDescription}
            </p>
            <a 
              href="https://trakteer.id/mshasbi/tip"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-[#F7AA01] text-[#2F2F30] px-4 py-2 rounded-lg inline-flex items-center gap-2 hover:bg-[#F7AA01]/80 transition-colors duration-200"
            >
              <Coffee className="w-4 h-4" />
              {translations.buyMeCoffee}
            </a>
          </div>
        </div>

        <div className="py-6 border-t border-gray-700">
          <div className="flex flex-col md:flex-row justify-between items-center gap-4">
            <div className="text-gray-300 text-sm">
              © {currentYear} {translations.appName}. {translations.madeWithLove}
            </div>
            <div className="flex items-center gap-4">
              <Link to="/privacy" className="text-gray-300 hover:text-[#F7AA01] text-sm">
                {translations.privacy}
              </Link>
              <Link to="/terms" className="text-gray-300 hover:text-[#F7AA01] text-sm">
                {translations.terms}
              </Link>
              <Link to={"/contact"} className="text-gray-300 hover:text-[#F7AA01] text-sm">
                {translations.contact}
              </Link>
              <Link to={"/cookies"} className="text-gray-300 hover:text-[#F7AA01] text-sm">
                {translations.cookies}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;