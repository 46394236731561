import React, { useState, useCallback, useEffect } from 'react';
import { 
  Upload, 
  Download, 
  Trash2, 
  RotateCw, 
  AlertTriangle,
  Settings,
  ChevronDown,
  Archive,
  ImageIcon,
  Check,
  X
} from 'lucide-react';
import * as tf from '@tensorflow/tfjs';
import Documentation from '../shared/Documentation';
import useTranslate from '../../hooks/useTranslate';
import Loading from '../../components/shared/Loading';
import JSZip from 'jszip';

const MAX_FILES = 10;
const SUPPORTED_TYPES = ['image/jpeg', 'image/png', 'image/webp'];
const MAX_FILE_SIZE = 20 * 1024 * 1024; // 20MB

const SettingsDropdown = ({ settings, setSettings, translations }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isOpen && !event.target.closest('.settings-dropdown')) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isOpen]);

  return (
    <div className="settings-dropdown relative ml-auto">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center gap-2 px-3 py-2 sm:px-4 sm:py-2 bg-gray-700 rounded-lg text-white hover:bg-gray-600 transition-colors duration-200 text-sm sm:text-base"
      >
        <Settings size={18} className="sm:w-5 sm:h-5" />
        <span className="hidden sm:inline">{translations.staticText.settingsTitle}</span>
        <ChevronDown size={14} className={`sm:w-4 sm:h-4 transition-transform duration-200 ${isOpen ? 'rotate-180' : ''}`} />
      </button>

      {isOpen && (
        <div className="absolute top-full right-0 mt-2 w-[280px] sm:w-64 bg-gray-700 rounded-lg shadow-lg p-4 z-50">
          <div className="space-y-4">
            <div>
              <label className="block text-white text-sm mb-2">
                {translations.staticText.scaleFactorLabel}
              </label>
              <select
                value={settings.scale}
                onChange={(e) => setSettings(prev => ({
                  ...prev,
                  scale: Number(e.target.value)
                }))}
                className="w-full p-2 bg-gray-600 text-white rounded-lg border-gray-500 focus:ring-2 focus:ring-[#F7AA01]"
              >
                <option value={2}>2x</option>
                <option value={3}>3x</option>
                <option value={4}>4x</option>
              </select>
            </div>

            <div className="space-y-3">
              <div className="flex items-center justify-between">
                <span className="text-white text-sm">
                  {translations.staticText.enhanceDetailsLabel}
                </span>
                <label className="relative inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    checked={settings.enhanceDetails}
                    onChange={(e) => setSettings(prev => ({
                      ...prev,
                      enhanceDetails: e.target.checked
                    }))}
                    className="sr-only peer"
                  />
                  <div className="w-11 h-6 bg-gray-600 rounded-full peer peer-checked:bg-[#F7AA01] peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all"></div>
                </label>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const ImageUpscaler = () => {
  const { translateText, currentLanguage } = useTranslate();
  const [translations, setTranslations] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [upscaledFiles, setUpscaledFiles] = useState([]);
  const [progress, setProgress] = useState({
    total: 0,
    current: 0,
    file: '',
    percent: 0
  });
  const [smoothProgress, setSmoothProgress] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [settings, setSettings] = useState({
    scale: 2,
    enhanceDetails: true
  });

  const staticText = {
    title: 'Image Upscaler',
    subtitle: 'Enhance image resolution with AI technology',
    dragDropText: 'Drag & Drop images here',
    orText: 'or',
    selectImagesButton: 'Select Images',
    selectedFilesTitle: 'Selected Files',
    processingText: 'Processing...',
    upscaleButton: 'Upscale Images',
    processedFilesTitle: 'Processed Files',
    settingsTitle: 'Settings',
    scaleFactorLabel: 'Scale Factor',
    enhanceDetailsLabel: 'Enhance Details',
    clearAll: 'Clear All',
    downloadAll: 'Download All',
    downloadTitle: 'Download',
    deleteTitle: 'Delete',
    originalLabel: 'Original',
    upscaledLabel: 'Upscaled',
    processingFile: 'Processing file',
    of: 'of',
    errorFileSize: 'File size exceeds 20MB limit',
    errorFileType: 'Unsupported file type'
  };

  useEffect(() => {
    const translateContent = async () => {
      setIsLoading(true);
      try {
        const translatedStatic = {};
        for (const [key, value] of Object.entries(staticText)) {
          translatedStatic[key] = await translateText(value, currentLanguage);
        }
        setTranslations({ staticText: translatedStatic });
      } catch (error) {
        console.error('Translation error:', error);
        setTranslations({ staticText });
      }
      setIsLoading(false);
    };

    translateContent();
  }, [currentLanguage, translateText]);

  useEffect(() => {
    const targetProgress = isProcessing && progress.total > 0
      ? ((progress.current - 1) / progress.total * 100) + (progress.percent / progress.total)
      : 0;

    const animationFrame = requestAnimationFrame(() => {
      setSmoothProgress(prev => prev + (targetProgress - prev) * 0.1);
    });

    return () => cancelAnimationFrame(animationFrame);
  }, [isProcessing, progress]);

  const validateFile = (file) => {
    if (file.size > MAX_FILE_SIZE) {
      throw new Error(translations.staticText.errorFileSize);
    }
    if (!SUPPORTED_TYPES.includes(file.type)) {
      throw new Error(translations.staticText.errorFileType);
    }
    return true;
  };

  const handleFileSelect = useCallback((event) => {
    const files = Array.from(event.target.files);
    try {
      files.forEach(validateFile);
      setSelectedFiles(prev => [...prev, ...files].slice(0, MAX_FILES));
      setErrorMessage('');
    } catch (error) {
      setErrorMessage(error.message);
    }
  }, [translations.staticText]);

  const handleDrop = useCallback((event) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    try {
      files.forEach(validateFile);
      setSelectedFiles(prev => [...prev, ...files].slice(0, MAX_FILES));
      setErrorMessage('');
    } catch (error) {
      setErrorMessage(error.message);
    }
  }, [translations.staticText]);

  const removeFile = useCallback((index) => {
    setSelectedFiles(prev => prev.filter((_, i) => i !== index));
  }, []);

  const removeUpscaledFile = useCallback((index) => {
    setUpscaledFiles(prev => {
      const newFiles = [...prev];
      if (newFiles[index]?.url) {
        URL.revokeObjectURL(newFiles[index].url);
      }
      newFiles.splice(index, 1);
      return newFiles;
    });
  }, []);

  const processImage = async (file) => {
    return new Promise(async (resolve, reject) => {
      try {
        const img = new Image();
        const objectUrl = URL.createObjectURL(file);

        img.onload = async () => {
          try {
            URL.revokeObjectURL(objectUrl);
            const MAX_DIM = 4096;
            let processWidth = img.width;
            let processHeight = img.height;

            if (processWidth > MAX_DIM || processHeight > MAX_DIM) {
              const ratio = Math.min(MAX_DIM / processWidth, MAX_DIM / processHeight);
              processWidth = Math.round(processWidth * ratio);
              processHeight = Math.round(processHeight * ratio);
            }

            const canvas = document.createElement('canvas');
            canvas.width = processWidth;
            canvas.height = processHeight;
            const ctx = canvas.getContext('2d');
            ctx.imageSmoothingEnabled = true;
            ctx.imageSmoothingQuality = 'high';
            ctx.drawImage(img, 0, 0, processWidth, processHeight);

            const [upscaledWidth, upscaledHeight] = [
              processWidth * settings.scale,
              processHeight * settings.scale
            ];

            const upscaledData = await tf.tidy(() => {
              const imageTensor = tf.browser.fromPixels(canvas);
              const normalizedTensor = imageTensor.toFloat().div(255);
              const upscaled = tf.image.resizeBilinear(
                normalizedTensor.expandDims(),
                [upscaledHeight, upscaledWidth],
                true
              );

              if (settings.enhanceDetails) {
                return upscaled.squeeze().mul(tf.scalar(1.1)).clipByValue(0, 1);
              }
              return upscaled.squeeze();
            });

            const pixels = await tf.browser.toPixels(upscaledData);
            upscaledData.dispose();

            const upscaledCanvas = document.createElement('canvas');
            upscaledCanvas.width = upscaledWidth;
            upscaledCanvas.height = upscaledHeight;
            const upscaledCtx = upscaledCanvas.getContext('2d');
            
            upscaledCtx.putImageData(new ImageData(pixels, upscaledWidth, upscaledHeight), 0, 0);

            if (settings.enhanceDetails) {
              upscaledCtx.filter = 'contrast(1.1) saturate(1.1) brightness(1.05)';
              upscaledCtx.drawImage(upscaledCanvas, 0, 0);
            }

            const blob = await new Promise(resolve => 
              upscaledCanvas.toBlob(resolve, 'image/jpeg', 0.9)
            );

            canvas.remove();
            upscaledCanvas.remove();

            resolve({
              url: URL.createObjectURL(blob),
              width: upscaledWidth,
              height: upscaledHeight,
              originalWidth: img.width,
              originalHeight: img.height,
              size: blob.size
            });
          } catch (error) {
            reject(error);
          }
        };

        img.onerror = () => {
          URL.revokeObjectURL(objectUrl);
          reject(new Error('Failed to load image'));
        };

        img.src = objectUrl;
      } catch (error) {
        reject(error);
      }
    });
  };

  const upscaleImages = async () => {
    if (selectedFiles.length === 0) return;

    setProgress({ total: selectedFiles.length, current: 0, file: '', percent: 0 });
    setSmoothProgress(0);
    setErrorMessage('');
    setIsProcessing(true);

    try {
      for (let i = 0; i < selectedFiles.length; i++) {
        const file = selectedFiles[i];
        setProgress(prev => ({
          ...prev,
          current: i + 1,
          file: file.name,
          percent: 0
        }));

        const result = await processImage(file);
        
        setUpscaledFiles(prev => [...prev, {
          id: `${file.name}-${Date.now()}`,
          name: `${file.name.split('.')[0]}_upscaled.jpg`,
          originalSize: file.size,
          newSize: result.size,
          url: result.url,
          dimensions: {
            original: {
              width: result.originalWidth,
              height: result.originalHeight
            },
            upscaled: {
              width: result.width,
              height: result.height
            }
          }
        }]);

        setProgress(prev => ({ ...prev, percent: 100 }));
      }
    } catch (error) {
      setErrorMessage(`Processing error: ${error.message}`);
    } finally {
      setIsProcessing(false);
      setProgress({ total: 0, current: 0, file: '', percent: 0 });
    }
  };

  const downloadFile = useCallback((file) => {
    const link = document.createElement('a');
    link.href = file.url;
    link.download = file.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, []);

  const downloadAll = useCallback(async () => {
    if (upscaledFiles.length === 0) return;

    // Using JSZip from the original code
    const zip = new JSZip();
    const fetchPromises = upscaledFiles.map(async file => {
      const response = await fetch(file.url);
      const blob = await response.blob();
      zip.file(file.name, blob);
    });

    await Promise.all(fetchPromises);
    const content = await zip.generateAsync({ type: 'blob' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(content);
    link.download = 'upscaled_images.zip';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, [upscaledFiles]);

  useEffect(() => {
    return () => {
      upscaledFiles.forEach(file => {
        if (file.url) URL.revokeObjectURL(file.url);
      });
    };
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <div className="max-w-4xl mx-auto px-4">
        {/* Header */}
        <div className="text-center mb-8">
          <h1 className="text-3xl lg:text-4xl font-bold mb-2 text-white">
            {translations.staticText.title}
          </h1>
          <p className="text-sm text-gray-300">
            {translations.staticText.subtitle}
          </p>
        </div>

        {/* Upload Area */}
        <div className="mb-8">
          <div className="flex items-center justify-end mb-4">
            <SettingsDropdown
              settings={settings}
              setSettings={setSettings}
              translations={translations}
            />
          </div>
          
          <div
            className="border-2 border-dashed border-gray-600 rounded-lg p-6 text-center cursor-pointer bg-gray-700 flex flex-col justify-center items-center transition-colors duration-200 hover:border-[#F7AA01]/50"
            onDrop={handleDrop}
            onDragOver={(e) => e.preventDefault()}
            onClick={() => document.getElementById('fileInput').click()}
          >
            <Upload size={48} className="text-gray-300 mb-4" />
            <p className="text-gray-300">{translations.staticText.dragDropText}</p>
            <p className="text-gray-300">{translations.staticText.orText}</p>
            <button className="bg-[#F7AA01] text-[#2F2F30] px-4 py-2 rounded-lg mt-2 flex items-center gap-2 hover:bg-[#F7AA01]/80 transition-colors duration-200">
              <Upload size={20} />
              {translations.staticText.selectImagesButton}
            </button>
            <input
              id="fileInput"
              type="file"
              onChange={handleFileSelect}
              accept={SUPPORTED_TYPES.join(',')}
              multiple
              className="hidden"
            />
          </div>
        </div>

        {/* Selected Files List */}
        {selectedFiles.length > 0 && (
          <div className="mb-8">
            <div className="flex items-center justify-between mb-4">
              <h2 className="text-xl font-semibold text-white">
                {translations.staticText.selectedFilesTitle}
              </h2>
              <span className="text-gray-300 text-sm">
                {selectedFiles.length} file(s)
              </span>
            </div>
            <div className="space-y-2">
              {selectedFiles.map((file, index) => (
                <div key={index} className="flex justify-between items-center bg-gray-700 p-3 rounded-lg">
                  <div className="flex items-center gap-3 min-w-0 flex-1">
                    <ImageIcon className="text-gray-400 flex-shrink-0" size={20} />
                    <span className="truncate text-white">{file.name}</span>
                  </div>
                  <div className="flex items-center gap-3 flex-shrink-0 ml-3">
                    <span className="text-gray-300 text-sm whitespace-nowrap">
                      {(file.size / (1024 * 1024)).toFixed(1)} MB
                    </span>
                    <button
                      onClick={() => removeFile(index)}
                      className="text-red-500 hover:text-red-400 transition-colors duration-200"
                      title={translations.staticText.deleteTitle}
                    >
                      <Trash2 size={20} />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Process Button */}
        <div className="mb-8 flex justify-center">
          <button
            onClick={upscaleImages}
            disabled={isProcessing || selectedFiles.length === 0}
            className={`bg-[#F7AA01] text-[#2F2F30] px-6 py-3 rounded-lg flex items-center gap-2 hover:bg-[#F7AA01]/80 transition-colors duration-200 ${
              (isProcessing || selectedFiles.length === 0) ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
            {isProcessing ? (
              <>
                <RotateCw className="animate-spin" size={20} />
                {translations.staticText.processingText}
              </>
            ) : (
              <>
                <ImageIcon size={20} />
                {translations.staticText.upscaleButton}
              </>
            )}
          </button>
        </div>

        {/* Progress Bar */}
        {isProcessing && (
          <div className="mb-6">
            <div className="relative w-full bg-gray-200 rounded-lg overflow-hidden h-4">
              <div
                className="absolute top-0 left-0 h-full bg-[#F7AA01] transition-all duration-300 ease-in-out"
                style={{ width: `${smoothProgress}%` }}
              />
            </div>
            <div className="flex justify-between items-center mt-2">
              <span className="text-sm text-gray-300">
                {translations.staticText.processingFile} {progress.current} {translations.staticText.of} {progress.total}
              </span>
              <span className="text-sm text-gray-300">
                {Math.round(smoothProgress)}%
              </span>
            </div>
            <p className="text-sm text-gray-300 mt-1 truncate">{progress.file}</p>
          </div>
        )}

        {/* Error Message */}
        {errorMessage && (
          <div className="mb-8 bg-red-900/20 border border-red-500 text-red-400 px-4 py-3 rounded-lg flex items-center gap-2">
            <AlertTriangle size={20} />
            <span>{errorMessage}</span>
          </div>
        )}

        {/* Processed Files */}
        {upscaledFiles.length > 0 && (
          <div className="mb-8">
            <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between mb-4 gap-3">
              <h2 className="text-xl font-semibold text-white">
                {translations.staticText.processedFilesTitle}
              </h2>
              <div className="flex flex-col sm:flex-row gap-2 w-full sm:w-auto">
                <button
                  onClick={downloadAll}
                  className="w-full sm:w-auto px-4 py-2 rounded-lg bg-[#F7AA01] text-[#2F2F30] flex items-center justify-center gap-2 hover:bg-[#F7AA01]/80 transition-colors duration-200"
                >
                  <Archive size={20} />
                  {translations.staticText.downloadAll}
                </button>
                <button
                  onClick={() => {
                    setUpscaledFiles([]);
                    setSelectedFiles([]);
                    setProgress({ total: 0, current: 0, file: '', percent: 0 });
                    setErrorMessage('');
                  }}
                  className="w-full sm:w-auto px-4 py-2 rounded-lg bg-red-500 text-white flex items-center justify-center gap-2 hover:bg-red-600 transition-colors duration-200"
                >
                  <Trash2 size={20} />
                  {translations.staticText.clearAll}
                </button>
              </div>
            </div>

            {/* Results Grid */}
            <div className="space-y-4">
              {upscaledFiles.map((file, index) => (
                <div key={file.id} className="bg-gray-700 p-4 rounded-lg">
                  <div className="flex justify-between items-center mb-4">
                    <span className="truncate text-white flex-1">{file.name}</span>
                    <div className="flex items-center gap-3 flex-shrink-0">
                      <button
                        onClick={() => downloadFile(file)}
                        className="text-[#F7AA01] hover:text-[#F7AA01]/80 transition-colors duration-200"
                        title={translations.staticText.downloadTitle}
                      >
                        <Download size={20} />
                      </button>
                      <button
                        onClick={() => removeUpscaledFile(index)}
                        className="text-red-500 hover:text-red-400 transition-colors duration-200"
                        title={translations.staticText.deleteTitle}
                      >
                        <Trash2 size={20} />
                      </button>
                    </div>
                  </div>

                  
<div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
  <div className="relative aspect-video">
    <img 
      src={selectedFiles[index] ? URL.createObjectURL(selectedFiles[index]) : ''}
      alt="Original" 
      className="w-full h-full object-contain rounded-lg bg-gray-800"
    />
    <span className="absolute bottom-2 left-2 bg-gray-900/80 text-white px-2 py-1 rounded text-sm">
      {translations.staticText.originalLabel}
    </span>
  </div>
  <div className="relative aspect-video">
    <div className="w-full h-full rounded-lg overflow-hidden bg-gray-800">
      <img 
        src={file.url} 
        alt="Upscaled" 
        className="w-full h-full object-contain"
      />
    </div>
    <span className="absolute bottom-2 left-2 bg-gray-900/80 text-white px-2 py-1 rounded text-sm">
      {translations.staticText.upscaledLabel}
    </span>
  </div>
</div>
                  <div className="grid grid-cols-1 sm:grid-cols-3 gap-2 text-sm text-gray-300">
                    <div>
                      Original: {file.dimensions.original.width} × {file.dimensions.original.height}
                    </div>
                    <div>
                      Upscaled: {file.dimensions.upscaled.width} × {file.dimensions.upscaled.height}
                    </div>
                    <div>
                      Size: {(file.newSize / (1024 * 1024)).toFixed(1)}MB
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      <Documentation tool="upscale" />
    </>
  );
};

export default ImageUpscaler;
