// src/hooks/useTranslate.js
import { useState, useCallback } from 'react';

const useTranslate = () => {
  const [currentLanguage, setCurrentLanguage] = useState(() => {
    return localStorage.getItem('preferredLanguage') || 'en';
  });

  const translateText = useCallback(async (text, targetLang) => {
    if (targetLang === 'en') return text;
    
    try {
      const url = `https://translate.googleapis.com/translate_a/single?client=gtx&sl=en&tl=${targetLang}&dt=t&q=${encodeURIComponent(text)}`;
      const response = await fetch(url);
      const data = await response.json();
      return data[0][0][0];
    } catch (error) {
      console.error('Translation error:', error);
      return text;
    }
  }, []);

  // Update fungsi changeLanguage dengan reload
  const changeLanguage = useCallback((newLang) => {
    setCurrentLanguage(newLang);
    localStorage.setItem('preferredLanguage', newLang);
    // Tambahkan reload window setelah mengganti bahasa
    window.location.reload();
  }, []);

  return {
    currentLanguage,
    translateText,
    changeLanguage
  };
};

export default useTranslate;