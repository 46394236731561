import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { 
  Menu, 
  X, 
  ChevronDown, 
  Instagram, 
  Coffee,
  Info,
  Image, 
  FileImage, 
  Minimize2, 
  ArrowUpRight, 
  Crop, 
  Maximize2, 
  Eraser, 
  Stamp, 
  RotateCw,
  Globe 
} from 'lucide-react';
import useTranslate from '../../hooks/useTranslate';
import { supportedLanguages, getLanguageFlag } from '../../config/languages';
import Loading from '../shared/Loading';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeSubmenu, setActiveSubmenu] = useState(null);
  const [isLanguageOpen, setIsLanguageOpen] = useState(false);
  const { translateText, currentLanguage, changeLanguage } = useTranslate();
  const [translations, setTranslations] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const languages = supportedLanguages;

  // Default English menu items
  const menuItems = {
    basicTools: {
      title: 'Basic Tools',
      items: [
        { 
          name: 'Convert Image', 
          description: 'Convert between formats', 
          path: '/convert', 
          icon: FileImage 
        },
        { 
          name: 'Compress Image', 
          description: 'Reduce file size', 
          path: '/compress', 
          icon: Minimize2 
        },
        { 
          name: 'Resize Image', 
          description: 'Change dimensions', 
          path: '/resize', 
          icon: ArrowUpRight 
        }
      ]
    },
    advancedTools: {
      title: 'Advanced Tools',
      items: [
        { 
          name: 'Crop Image', 
          description: 'Crop and adjust', 
          path: '/crop', 
          icon: Crop 
        },
        { 
          name: 'Upscale Image', 
          description: 'Increase resolution', 
          path: '/upscale', 
          icon: Maximize2 
        },
        { 
          name: 'Remove Background', 
          description: 'Remove backgrounds', 
          path: '/remove-background', 
          icon: Eraser 
        }
      ]
    },
    otherTools: {
      title: 'Other Tools',
      items: [
        { 
          name: 'Add Watermark', 
          description: 'Add watermarks', 
          path: '/watermark', 
          icon: Stamp 
        },
        { 
          name: 'Rotate Image', 
          description: 'Rotate and flip', 
          path: '/rotate', 
          icon: RotateCw 
        }
      ]
    }
  };

  const staticText = {
    about: 'About',
    buyMeCoffee: 'Buy me a coffee'
  };

  useEffect(() => {
    const translateMenu = async () => {
      setIsLoading(true);
      try {
        if (currentLanguage === 'en') {
          setTranslations({
            ...menuItems,
            staticText
          });
          
        } else {
          // Translate menu items
          const translatedMenu = {};
          for (const [section, content] of Object.entries(menuItems)) {
            translatedMenu[section] = {
              title: await translateText(content.title, currentLanguage),
              items: await Promise.all(
                content.items.map(async (item) => ({
                  ...item,
                  name: await translateText(item.name, currentLanguage),
                  description: await translateText(item.description, currentLanguage)
                }))
              )
            };
          }

          // Translate static text
          const translatedStatic = {};
          for (const [key, value] of Object.entries(staticText)) {
            translatedStatic[key] = await translateText(value, currentLanguage);
          }

          setTranslations({
            ...translatedMenu,
            staticText: translatedStatic
          });
        }
      } catch (error) {
        console.error('Translation error:', error);
        // Fallback to English if translation fails
        setTranslations({
          ...menuItems,
          staticText
        });
      }
      setIsLoading(false);
    };

    translateMenu();
  }, [currentLanguage]);

  const renderDesktopMenu = () => (
    <div className="hidden md:flex items-center space-x-8">
      <div className="relative group">
        <button 
          className="text-gray-300 hover:text-[#F7AA01] flex items-center gap-1 py-5"
          onMouseEnter={() => setActiveSubmenu('tools')}
          onClick={() => setActiveSubmenu(activeSubmenu === 'tools' ? null : 'tools')}
        >
          Tools <ChevronDown size={16} />
        </button>
        
        {/* Mega Menu */}
        {activeSubmenu === 'tools' && translations.basicTools && (
          <div 
            className="fixed left-1/2 transform -translate-x-1/2 top-16 w-max max-w-7xl bg-gray-800 rounded-lg shadow-xl z-30"
            onMouseLeave={() => setActiveSubmenu(null)}
            style={{ maxWidth: 'calc(100vw - 2rem)' }}
          >
            <div className="grid grid-cols-3 gap-8 p-6">
              {Object.entries(translations)
                .filter(([key]) => key !== 'staticText')
                .map(([key, section]) => (
                  <div key={key} className="min-w-[200px]">
                    <h3 className="text-white font-semibold mb-4">{section.title}</h3>
                    <ul className="space-y-4">
                      {section.items.map((item, index) => (
                        <li key={index}>
                          <Link
                            to={item.path}
                            className="flex items-start gap-3 text-gray-300 hover:text-[#F7AA01] transition-colors"
                            onClick={() => setActiveSubmenu(null)}
                          >
                            <item.icon className="w-5 h-5 mt-0.5" />
                            <div>
                              <div className="font-medium">{item.name}</div>
                              <div className="text-sm text-gray-400">{item.description}</div>
                            </div>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>

      <Link to="/about" className="text-gray-300 hover:text-[#F7AA01] transition-colors">
        {translations.staticText?.about || 'About'}
      </Link>
      
      {/* Desktop Language Switcher */}
      <div className="relative">
        <button
          onClick={() => setIsLanguageOpen(!isLanguageOpen)}
          className="flex items-center gap-2 text-gray-300 hover:text-[#F7AA01] transition-colors"
          title="Change Language"
        >
          <Globe className="w-5 h-5" />
          <span className="text-sm font-medium flex items-center gap-1">
            {getLanguageFlag(currentLanguage)}
            {languages.find(lang => lang.code === currentLanguage)?.name || 'English'}
          </span>
        </button>

        {isLanguageOpen && (
          <div className="absolute right-0 mt-2 py-2 w-48 bg-gray-800 rounded-lg shadow-xl z-50">
            {languages.map((lang) => (
              <button
                key={lang.code}
                onClick={() => {
                  changeLanguage(lang.code);
                  setIsLanguageOpen(false);
                }}
                className={`w-full px-4 py-2 text-left hover:bg-gray-700 transition-colors flex items-center gap-2 ${
                  currentLanguage === lang.code ? 'text-[#F7AA01]' : 'text-gray-300'
                }`}
              >
                {lang.flag}
                {lang.name}
              </button>
            ))}
          </div>
        )}
      </div>
      
      <a 
        href="https://www.instagram.com/imgtoollab/" 
        target="_blank" 
        rel="noopener noreferrer"
        className="text-gray-300 hover:text-[#F7AA01] transition-colors"
      >
        <Instagram className="w-5 h-5" />
      </a>
      
      <a 
        href="https://trakteer.id/mshasbi/tip" 
        target="_blank" 
        rel="noopener noreferrer"
        className="bg-[#F7AA01] text-[#2F2F30] px-4 py-2 rounded-lg flex items-center gap-2 hover:bg-[#F7AA01]/80 transition-colors duration-200"
      >
        <Coffee className="w-4 h-4" />
        {translations.staticText?.buyMeCoffee || 'Buy me a coffee'}
      </a>
    </div>
  );

  return (
    <header className="fixed top-0 left-0 right-0 bg-[#2F2F30] shadow-md z-40">
      <nav className="max-w-7xl mx-auto">
        <div className="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8">
          {/* Logo */}
          <Link to="/" className="flex items-center space-x-2">
            <Image className="w-8 h-8 text-[#F7AA01]" />
            <span className="text-xl font-bold text-white">ImgToolLab</span>
          </Link>

          {/* Navigation */}
          {!isLoading && (
            <>
              {/* Desktop Menu */}
              {renderDesktopMenu()}

              {/* Mobile Header Right Section */}
              <div className="md:hidden flex items-center gap-4">
                {/* Mobile Language Switcher */}
                <div className="relative">
                  <button
                    onClick={() => setIsLanguageOpen(!isLanguageOpen)}
                    className="flex items-center gap-1 text-gray-300 hover:text-[#F7AA01] transition-colors p-2"
                  >
                    <Globe className="w-5 h-5" />
                    <span className="text-sm font-medium flex items-center gap-1">
                      {getLanguageFlag(currentLanguage)}
                      {currentLanguage.toUpperCase()}
                    </span>
                  </button>

                  {isLanguageOpen && (
                    <div className="absolute right-0 mt-2 py-2 w-40 bg-gray-800 rounded-lg shadow-xl z-50">
                      {languages.map((lang) => (
                        <button
                          key={lang.code}
                          onClick={() => {
                            changeLanguage(lang.code);
                            setIsLanguageOpen(false);
                          }}
                          className={`w-full px-4 py-2 text-left hover:bg-gray-700 transition-colors flex items-center gap-2 ${
                            currentLanguage === lang.code ? 'text-[#F7AA01]' : 'text-gray-300'
                          }`}
                        >
                          {lang.flag}
                          {lang.name}
                        </button>
                      ))}
                    </div>
                  )}
                </div>

                {/* Hamburger Menu */}
                <button 
                  className="text-gray-300 hover:text-white p-2"
                  onClick={() => setIsOpen(!isOpen)}
                >
                  {isOpen ? <X size={24} /> : <Menu size={24} />}
                </button>
              </div>
            </>
          )}
        </div>

        {/* Mobile Navigation Menu */}
        {isOpen && !isLoading && (
          <div className="md:hidden bg-gray-800">
            <div className="px-4 py-4 space-y-4">
              {Object.entries(translations)
                .filter(([key]) => key !== 'staticText')
                .map(([key, section]) => (
                  <div key={key}>
                    <h3 className="text-white font-semibold mb-2">{section.title}</h3>
                    <ul className="space-y-2">
                      {section.items.map((item, index) => (
                        <li key={index}>
                          <Link
                            to={item.path}
                            className="flex items-center gap-2 text-gray-300 hover:text-[#F7AA01] transition-colors py-2"
                            onClick={() => setIsOpen(false)}
                          >
                            <item.icon className="w-5 h-5" />
                            {item.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              
              <div className="border-t border-gray-700 pt-4">
                <Link 
                   to="/about" 
                   className="flex items-center gap-2 text-gray-300 hover:text-[#F7AA01] transition-colors py-2"
                   onClick={() => setIsOpen(false)}
                 >
                   <Info className="w-5 h-5" /> {/* Added Info icon */}
                   {translations.staticText?.about || 'About'}
                 </Link>
                
                <a 
                  href="https://www.instagram.com/imgtoollab/" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="flex items-center gap-2 text-gray-300 hover:text-[#F7AA01] transition-colors py-2"
                >
                  <Instagram className="w-5 h-5" />
                  Instagram
                </a>
                
                <a 
                  href="https://trakteer.id/mshasbi/tip" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="flex items-center gap-2 text-[#F7AA01] hover:text-[#F7AA01]/80 transition-colors py-2"
                >
                  <Coffee className="w-5 h-5" />
                  {translations.staticText?.buyMeCoffee || 'Buy me a coffee'}
                </a>
              </div>
            </div>
          </div>
        )}
      </nav>
    </header>
  );
};

export default Header;