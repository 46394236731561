import React from 'react';
import { Navigate } from 'react-router-dom';
import BreadcrumbsAnalytics from './BreadcrumbsAnalytics';

const ProtectedAnalytics = () => {
  const isAdmin = localStorage.getItem('isAdmin') === 'true';

  if (!isAdmin) {
    return <Navigate to="/login" replace />;
  }

  return <BreadcrumbsAnalytics />;
};

export default ProtectedAnalytics;