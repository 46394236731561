import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  FileType, 
  Minimize2, 
  Image as ImageIcon, 
  Crop, 
  Maximize2, 
  Eraser, 
  Stamp, 
  RotateCw 
} from 'lucide-react';
import useTranslate from '../hooks/useTranslate';
import Loading from '../components/shared/Loading';

const Home = () => {
  const navigate = useNavigate();
  const { translateText, currentLanguage } = useTranslate();
  const [translations, setTranslations] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  

  const tools = [
    {
      id: 'convert',
      name: 'Convert Image',
      description: 'Convert images to JPG, PNG, WebP, SVG, PDF formats with ease.',
      icon: FileType,
      path: '/convert',
      color: 'blue'
    },
    {
      id: 'compress',
      name: 'Compress Image',
      description: 'Compress JPG, PNG, SVG, and GIFs while maintaining quality.',
      icon: Minimize2,
      path: '/compress',
      color: 'green'
    },
    {
      id: 'resize',
      name: 'Resize Image',
      description: 'Define dimensions by percent or pixel for any image format.',
      icon: ImageIcon,
      path: '/resize',
      color: 'purple'
    },
    {
      id: 'crop',
      name: 'Crop Image',
      description: 'Crop images with precision using pixels or visual editor.',
      icon: Crop,
      path: '/crop',
      color: 'orange'
    },
    {
      id: 'upscale',
      name: 'Upscale Image',
      description: 'Enlarge images while maintaining high resolution quality.',
      icon: Maximize2,
      path: '/upscale',
      color: 'pink'
    },
    {
      id: 'remove-bg',
      name: 'Remove Background',
      description: 'Remove image backgrounds with high accuracy instantly.',
      icon: Eraser,
      path: '/remove-background',
      color: 'red'
    },
    {
      id: 'watermark',
      name: 'Add Watermark',
      description: 'Add text or image watermarks with custom positioning.',
      icon: Stamp,
      path: '/watermark',
      color: 'indigo'
    },
    {
      id: 'rotate',
      name: 'Rotate Image',
      description: 'Rotate multiple images at once with precise control.',
      icon: RotateCw,
      path: '/rotate',
      color: 'yellow'
    }
  ];

  const staticText = {
    title: 'All-in-One Image Tools',
    description: 'Professional image tools to edit, convert, and optimize your images. Fast, easy, and free!',
    whyChooseUs: 'Why Choose Our Tools?',
    freeToUse: 'Free to Use',
    freeToUseDescription: 'All tools are completely free with no hidden costs or limits.',
    privacyFirst: 'Privacy First',
    privacyFirstDescription: 'Your images are processed locally. We never store your files.',
    highQuality: 'High Quality',
    highQualityDescription: 'Maintain image quality while optimizing for your needs.'
  };
  

  useEffect(() => {
    const translateContent = async () => {
      setIsLoading(true);
      try {
        // Translate tool names and descriptions
        const translatedTools = await Promise.all(
          tools.map(async (tool) => ({
            ...tool,
            name: await translateText(tool.name, currentLanguage),
            description: await translateText(tool.description, currentLanguage)
          }))
        );
        // Translate static text
        const translatedStatic = {
          title: await translateText(staticText.title, currentLanguage),
          description: await translateText(staticText.description, currentLanguage),
          whyChooseUs: await translateText(staticText.whyChooseUs, currentLanguage),
          freeToUse: await translateText(staticText.freeToUse, currentLanguage),
          freeToUseDescription: await translateText(staticText.freeToUseDescription, currentLanguage),
          privacyFirst: await translateText(staticText.privacyFirst, currentLanguage),
          privacyFirstDescription: await translateText(staticText.privacyFirstDescription, currentLanguage),
          highQuality: await translateText(staticText.highQuality, currentLanguage),
          highQualityDescription: await translateText(staticText.highQualityDescription, currentLanguage)
        };

        setTranslations({
          tools: translatedTools,
          staticText: translatedStatic
        });
      } catch (error) {
        console.error('Translation error:', error);
        // Fallback to English if translation fails
        setTranslations({
          tools,
          staticText
        });
      }
      setIsLoading(false);
    };

    translateContent();
  }, [currentLanguage, translateText]);

  const getGradientClass = (color) => {
    const gradients = {
      blue: 'from-blue-500 to-blue-700',
      green: 'from-green-500 to-green-700',
      purple: 'from-purple-500 to-purple-700',
      orange: 'from-orange-500 to-orange-700',
      pink: 'from-pink-500 to-pink-700',
      red: 'from-red-500 to-red-700',
      indigo: 'from-indigo-500 to-indigo-700',
      yellow: 'from-yellow-500 to-yellow-700'
    };
    return gradients[color] || 'from-blue-500 to-blue-700';
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="text-center mb-16">
            <h1 className="text-4xl md:text-6xl font-bold text-white mb-6">
              {translations.staticText.title}
            </h1>
            <p className="text-xl text-gray-300 max-w-3xl mx-auto">
              {translations.staticText.description}
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
            {translations.tools.map((tool) => {
              const IconComponent = tool.icon;
              return (
                <button
                  key={tool.id}
                  onClick={() => navigate(tool.path)}
                  className="bg-[#2F2F30] rounded-lg p-6 text-left transition-transform hover:scale-105 hover:shadow-xl cursor-pointer border border-gray-700"
                >
                  <div className={`w-12 h-12 rounded-lg bg-gradient-to-br ${getGradientClass(tool.color)} flex items-center justify-center mb-4`}>
                    <IconComponent className="w-6 h-6 text-white" />
                  </div>
                  <h3 className="text-xl font-bold text-white mb-2">{tool.name}</h3>
                  <p className="text-gray-300 text-sm">{tool.description}</p>
                </button>
              );
            })}
          </div>

          <div className="mt-16 text-center">
            <h2 className="text-2xl md:text-3xl font-bold text-white mb-4">
              {translations.staticText.whyChooseUs}
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-4xl mx-auto">
              <div className="bg-[#2F2F30] p-6 rounded-lg">
                <h3 className="text-xl font-bold text-white mb-2">{translations.staticText.freeToUse}</h3>
                <p className="text-gray-300">{translations.staticText.freeToUseDescription}</p>
              </div>
              <div className="bg-[#2F2F30] p-6 rounded-lg">
                <h3 className="text-xl font-bold text-white mb-2">{translations.staticText.privacyFirst}</h3>
                <p className="text-gray-300">{translations.staticText.privacyFirstDescription}</p>
              </div>
              <div className="bg-[#2F2F30] p-6 rounded-lg">
                <h3 className="text-xl font-bold text-white mb-2">{translations.staticText.highQuality}</h3>
                <p className="text-gray-300">{translations.staticText.highQualityDescription}</p>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Home;