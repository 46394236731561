import React, { useState, useCallback, useEffect } from 'react';
import { Upload, Download, Trash2, Settings, RotateCw, AlertTriangle, Image as ImageIcon } from 'lucide-react';
import Documentation from '../shared/Documentation';
import useTranslate from '../../hooks/useTranslate';
import Loading from '../../components/shared/Loading';
const ImageCompress = () => {
  const { translateText, currentLanguage } = useTranslate();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [compressedFiles, setCompressedFiles] = useState([]);
  const [quality, setQuality] = useState(80);
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [progress, setProgress] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [optimizeFor, setOptimizeFor] = useState('balanced'); // 'web', 'balanced', 'print'
  const [translations, setTranslations] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const translateComponents = async () => {
      setIsLoading(true);
      try {
        const translatedContent = {
          title: await translateText('Compress Images', currentLanguage),
          description: await translateText('Reduce file size while maintaining image quality', currentLanguage),
          dropFilesText: await translateText('Drag & Drop images here', currentLanguage),
          selectFilesText: await translateText('Select Images', currentLanguage),
          selectedFilesTitle: await translateText('Selected Files', currentLanguage),
          compressionSettingsTitle: await translateText('Compression Settings', currentLanguage),
          simpleMode: await translateText('Simple Mode', currentLanguage),
          advancedMode: await translateText('Advanced Settings', currentLanguage),
          webOptimize: await translateText('Web', currentLanguage),
          webOptimizeHint: await translateText('Smallest file size', currentLanguage),
          balancedOptimize: await translateText('Balanced', currentLanguage),
          balancedOptimizeHint: await translateText('Best for most uses', currentLanguage),
          printOptimize: await translateText('Print', currentLanguage),
          printOptimizeHint: await translateText('Highest quality', currentLanguage),
          qualityLabel: await translateText('Quality', currentLanguage),
          qualityLessLabel: await translateText('Smaller file', currentLanguage),
          qualityMoreLabel: await translateText('Better quality', currentLanguage),
          compressButtonLabel: await translateText('Compress Images', currentLanguage),
          processingLabel: await translateText('Processing...', currentLanguage),
          compressedFilesTitle: await translateText('Compressed Files', currentLanguage),
          downloadLabel: await translateText('Download', currentLanguage),
          deleteLabel: await translateText('Delete', currentLanguage),
          originalSizeLabel: await translateText('Original:', currentLanguage),
          compressedSizeLabel: await translateText('Compressed:', currentLanguage),
          saveRatioLabel: await translateText('Saved:', currentLanguage),
          imageSizeLabel: await translateText('Size:', currentLanguage),
          errorMessage: await translateText('Compression failed:', currentLanguage)
        };
        setTranslations(translatedContent);
      } catch (error) {
        console.error('Translation error:', error);
        // Fallback to English if translation fails
        setTranslations({
          title: 'Compress Images',
          description: 'Reduce file size while maintaining image quality',
          dropFilesText: 'Drag & Drop images here',
          selectFilesText: 'Select Images',
          selectedFilesTitle: 'Selected Files',
          compressionSettingsTitle: 'Compression Settings',
          simpleMode: 'Simple Mode',
          advancedMode: 'Advanced Settings',
          webOptimize: 'Web',
          webOptimizeHint: 'Smallest file size',
          balancedOptimize: 'Balanced',
          balancedOptimizeHint: 'Best for most uses',
          printOptimize: 'Print',
          printOptimizeHint: 'Highest quality',
          qualityLabel: 'Quality',
          qualityLessLabel: 'Smaller file',
          qualityMoreLabel: 'Better quality',
          compressButtonLabel: 'Compress Images',
          processingLabel: 'Processing...',
          compressedFilesTitle: 'Compressed Files',
          downloadLabel: 'Download',
          deleteLabel: 'Delete',
          originalSizeLabel: 'Original:',
          compressedSizeLabel: 'Compressed:',
          saveRatioLabel: 'Saved:',
          imageSizeLabel: 'Size:',
          errorMessage: 'Compression failed:'
        });
      } finally {
        setIsLoading(false);
      }
    };

    translateComponents();
  }, [translateText, currentLanguage]);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
  };

  const handleDrop = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    const files = Array.from(event.dataTransfer.files);
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
  }, []);

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const removeFile = (index) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const removeCompressedFile = (index) => {
    setCompressedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const getOptimizeSettings = (setting) => {
    const settings = {
      web: { quality: 60, format: 'webp' },
      balanced: { quality: 80, format: 'jpeg' },
      print: { quality: 100, format: 'png' }
    };
    return settings[setting];
  };
  const compressImages = async () => {
    if (selectedFiles.length === 0) {
      setErrorMessage(translations.errorMessage);
      return;
    }

    setProgress(0);
    setErrorMessage('');
    setIsProcessing(true);

    try {
      const compressed = await Promise.all(
        selectedFiles.map(async (file) => {
          const img = await createImageBitmap(file);
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0);

          const settings = showAdvanced 
            ? { quality: quality / 100, format: 'jpeg' }
            : getOptimizeSettings(optimizeFor);

          const dataUrl = canvas.toDataURL(
            `image/${settings.format}`, 
            settings.quality
          );

          return {
            name: `${file.name.split('.')[0]}_compressed.${settings.format}`,
            dataUrl,
            originalSize: file.size,
            compressedSize: Math.round((dataUrl.length * 3) / 4),
            width: img.width,
            height: img.height
          };
        })
      );

      setCompressedFiles(compressed);
      setProgress(100);
    } catch (error) {
      setErrorMessage(`${translations.errorMessage} ${error.message}`);
    } finally {
      setIsProcessing(false);
    }
  };

  const downloadFile = (file) => {
    const link = document.createElement('a');
    link.href = file.dataUrl;
    link.download = file.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const getCompressionRatio = (original, compressed) => {
    const ratio = ((original - compressed) / original * 100).toFixed(1);
    return ratio;
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <div className="max-w-4xl mx-auto px-4">
        <div className="text-center mb-8">
          <h1 className="text-3xl lg:text-4xl font-bold mb-2 text-white">{translations.title}</h1>
          <p className="text-sm text-gray-300">{translations.description}</p>
        </div>

        <div className="mb-8">
          <div
            className="border-2 border-dashed border-gray-600 rounded-lg p-6 text-center cursor-pointer bg-gray-700 flex flex-col justify-center items-center transition-colors duration-200 hover:border-[#F7AA01]/50"
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onClick={() => document.getElementById('fileInput').click()}
          >
            <Upload size={48} className="text-gray-300 mb-4" />
            <p className="text-gray-300">{translations.dropFilesText}</p>
            <p className="text-gray-300">or</p>
            <button className="bg-[#F7AA01] text-[#2F2F30] px-4 py-2 rounded-lg mt-2 flex items-center gap-2 hover:bg-[#F7AA01]/80 transition-colors duration-200">
              <Upload size={20} />
              {translations.selectFilesText}
            </button>
            <input
              id="fileInput"
              type="file"
              onChange={handleFileChange}
              accept="image/*"
              multiple
              className="hidden"
            />
          </div>
        </div>

        {selectedFiles.length > 0 && (
          <div className="mb-8">
            <h2 className="text-xl font-semibold text-white mb-4">{translations.selectedFilesTitle}</h2>
            <div className="space-y-2">
              {selectedFiles.map((file, index) => (
                <div key={index} className="flex justify-between items-center bg-gray-700 p-3 rounded-lg">
                  <span className="truncate text-white">{file.name}</span>
                  <div className="flex items-center gap-3">
                    <span className="text-gray-300 text-sm">
                      {(file.size / 1024).toFixed(1)} KB
                    </span>
                    <button
                      onClick={() => removeFile(index)}
                      className="text-red-500 hover:text-red-400 transition-colors duration-200"
                      title={translations.deleteLabel}
                    >
                      <Trash2 size={20} />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        <div className="mb-8">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-semibold text-white">{translations.compressionSettingsTitle}</h2>
            <button
              onClick={() => setShowAdvanced(!showAdvanced)}
              className="text-[#F7AA01] hover:text-[#F7AA01]/80 transition-colors duration-200 flex items-center gap-2"
            >
              <Settings size={20} />
              {showAdvanced ? translations.simpleMode : translations.advancedMode}
            </button>
          </div>

          {!showAdvanced ? (
            <div className="grid grid-cols-3 gap-4">
              {['web', 'balanced', 'print'].map((mode) => (
                <button
                  key={mode}
                  onClick={() => setOptimizeFor(mode)}
                  className={`p-4 rounded-lg flex flex-col items-center gap-2 ${
                    optimizeFor === mode
                      ? 'bg-[#F7AA01] text-[#2F2F30]'
                      : 'bg-gray-700 text-gray-300'
                  } hover:bg-[#F7AA01] hover:text-[#2F2F30] transition-colors duration-200`}
                >
                  <ImageIcon size={24} />
                  <span className="capitalize">{translations[`${mode}Optimize`]}</span>
                  <span className="text-xs opacity-75">
                    {mode === 'web' && translations.webOptimizeHint}
                    {mode === 'balanced' && translations.balancedOptimizeHint}
                    {mode === 'print' && translations.printOptimizeHint}
                  </span>
                </button>
              ))}
            </div>
          ) : (
            <div className="bg-gray-700 p-4 rounded-lg">
              <label className="block mb-2 text-white">{translations.qualityLabel}</label>
              <div className="flex items-center gap-4">
                <input
                  type="range"
                  min="1"
                  max="100"
                  value={quality}
                  onChange={(e) => setQuality(parseInt(e.target.value))}
                  className="flex-grow"
                />
                <span className="text-white w-16 text-center">{quality}%</span>
              </div>
              <div className="flex justify-between text-sm text-gray-300 mt-1">
                <span>{translations.qualityLessLabel}</span>
                <span>{translations.qualityMoreLabel}</span>
              </div>
            </div>
          )}
        </div>

        <div className="mb-8 flex justify-center">
          <button
            onClick={compressImages}
            disabled={isProcessing || selectedFiles.length === 0}
            className={`bg-[#F7AA01] text-[#2F2F30] px-6 py-3 rounded-lg flex items-center gap-2 hover:bg-[#F7AA01]/80 transition-colors duration-200 ${
              (isProcessing || selectedFiles.length === 0) ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
            {isProcessing ? (
              <>
                <RotateCw className="animate-spin" size={20} />
                {translations.processingLabel}
              </>
            ) : (
              <>
                <ImageIcon size={20} />
                {translations.compressButtonLabel}
              </>
            )}
          </button>
        </div>

        {progress > 0 && progress < 100 && (
          <div className="mb-8">
            <div className="relative w-full bg-gray-200 rounded-lg overflow-hidden h-4">
              <div
                className="absolute top-0 left-0 h-full bg-[#F7AA01] transition-all duration-300"
                style={{ width: `${progress}%` }}
              />
            </div>
            <p className="text-center mt-2 text-white">{progress}%</p>
          </div>
        )}

        {errorMessage && (
          <div className="mb-8 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded-lg flex items-center gap-2">
            <AlertTriangle size={20} />
            <span>{errorMessage}</span>
          </div>
        )}

        {compressedFiles.length > 0 && (
          <div className="mb-8">
            <h2 className="text-xl font-semibold text-white mb-4">{translations.compressedFilesTitle}</h2>
            <div className="space-y-2">
              {compressedFiles.map((file, index) => (
                <div key={index} className="bg-gray-700 p-3 rounded-lg">
                  <div className="flex justify-between items-center mb-2">
                    <span className="truncate text-white">{file.name}</span>
                    <div className="flex items-center gap-3">
                      <button
                        onClick={() => downloadFile(file)}
                        className="text-[#F7AA01] hover:text-[#F7AA01]/80 transition-colors duration-200"
                        title={translations.downloadLabel}
                      >
                        <Download size={20} />
                      </button>
                      <button
                        onClick={() => removeCompressedFile(index)}
                        className="text-red-500 hover:text-red-400 transition-colors duration-200"
                        title={translations.deleteLabel}
                      >
                        <Trash2 size={20} />
                      </button>
                    </div>
                  </div>
                  <div className="grid grid-cols-2 md:grid-cols-4 gap-2 text-sm">
                    <div className="text-gray-300">
                      {translations.originalSizeLabel} {(file.originalSize / 1024).toFixed(1)} KB
                    </div>
                    <div className="text-gray-300">
                      {translations.compressedSizeLabel} {(file.compressedSize / 1024).toFixed(1)} KB
                    </div>
                    <div className="text-green-400">
                      {translations.saveRatioLabel} {getCompressionRatio(file.originalSize, file.compressedSize)}%
                    </div>
                    <div className="text-gray-300">
                      {translations.imageSizeLabel} {file.width}×{file.height}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      <Documentation tool="compress" />
    </>
  );
};

export default ImageCompress;