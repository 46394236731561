import React, { useState, useEffect } from 'react';
import { X, Cookie } from 'lucide-react';
import { Link } from 'react-router-dom';
import useTranslate from '../hooks/useTranslate';

const CookiePopup = () => {
  const { translateText, currentLanguage } = useTranslate();
  const [isVisible, setIsVisible] = useState(false);
  const [translations, setTranslations] = useState({});

  useEffect(() => {
    const translateCookiePopup = async () => {
      try {
        const translatedContent = {
          title: await translateText('We use cookies', currentLanguage),
          description: await translateText('We use cookies to improve your experience on our website. By continuing to use this site, you agree to our cookie policy.', currentLanguage),
          learnMoreLabel: await translateText('Learn More', currentLanguage),
          declineLabel: await translateText('Decline', currentLanguage),
          acceptLabel: await translateText('Accept', currentLanguage)
        };
        setTranslations(translatedContent);
      } catch (error) {
        console.error('Translation error:', error);
        // Fallback to English if translation fails
        setTranslations({
          title: 'We use cookies',
          description: 'We use cookies to improve your experience on our website. By continuing to use this site, you agree to our cookie policy.',
          learnMoreLabel: 'Learn More',
          declineLabel: 'Decline',
          acceptLabel: 'Accept'
        });
      }
    };

    const hasAcceptedCookies = localStorage.getItem('acceptedCookies');
    if (!hasAcceptedCookies) {
      translateCookiePopup();
      const timer = setTimeout(() => {
        setIsVisible(true);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [translateText, currentLanguage]);

  const acceptCookies = () => {
    localStorage.setItem('acceptedCookies', 'true');
    setIsVisible(false);
  };

  const declineCookies = () => {
    localStorage.setItem('acceptedCookies', 'false');
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-gray-800 shadow-lg z-50">
      <div className="max-w-7xl mx-auto p-4 md:p-6">
        <div className="flex flex-col md:flex-row items-center justify-between gap-4">
          <div className="flex items-start gap-4">
            <Cookie className="w-6 h-6 text-[#F7AA01] flex-shrink-0 mt-1" />
            <div>
              <h3 className="text-white font-semibold mb-2">{translations.title}</h3>
              <p className="text-gray-300 text-sm">
                {translations.description}
              </p>
            </div>
          </div>
          
          <div className="flex items-center gap-4">
            <Link
              to="/cookies"
              className="text-[#F7AA01] hover:text-[#F7AA01]/80 text-sm"
              onClick={() => setIsVisible(false)}
            >
              {translations.learnMoreLabel}
            </Link>
            <button
              onClick={declineCookies}
              className="px-4 py-2 text-gray-300 hover:text-white transition-colors"
            >
              {translations.declineLabel}
            </button>
            <button
              onClick={acceptCookies}
              className="bg-[#F7AA01] text-[#2F2F30] px-4 py-2 rounded-lg hover:bg-[#F7AA01]/80 transition-colors"
            >
              {translations.acceptLabel}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookiePopup;