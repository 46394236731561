// src/App.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Layout from './components/layout/Layout';
import ImageConverter from './components/tools/ImageConverter';
import ImageCompress from './components/tools/ImageCompress';
import ImageResize from './components/tools/ImageResize';
import ImageCrop from './components/tools/ImageCrop';
import ImageUpscale from './components/tools/ImageUpscale';
import RemoveBackground from './components/tools/RemoveBackground';
import Watermark from './components/tools/Watermark';
import ImageRotate from './components/tools/ImageRotate';
import AboutUs from './pages/AboutUs';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';
import ContactUs from './pages/ContactUs';
import Cookies from './pages/Cookies';
import Home from './pages/Home';
import NotFound from './pages/NotFound';
import { TranslationProvider } from './context/TranslationContext';
import BreadcrumbsAnalytics from './components/analytics/BreadcrumbsAnalytics';
import ProtectedAnalytics from './components/analytics/ProtectedAnalytics';
import { HelmetProvider } from 'react-helmet-async';

function App() {
  return (
    
    <TranslationProvider>
      <Layout>
        <Routes>
          {/* Main Routes */}
          <Route path="/" element={<Home />} />
          <Route path="/admin/analytics" element={<BreadcrumbsAnalytics />} />
          <Route path="/admin/analytics" element={<ProtectedAnalytics />} />
          
          {/* Tool Routes */}
          <Route path="/convert" element={<ImageConverter />} />
          <Route path="/compress" element={<ImageCompress />} />
          <Route path="/resize" element={<ImageResize />} />
          <Route path="/crop" element={<ImageCrop />} />
          <Route path="/upscale" element={<ImageUpscale />} />
          <Route path="/remove-background" element={<RemoveBackground />} />
          <Route path="/watermark" element={<Watermark />} />
          <Route path="/rotate" element={<ImageRotate />} />
          
          {/* Page Routes */}
          <Route path="/about" element={<AboutUs />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<TermsOfService />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/cookies" element={<Cookies />} />
          
          {/* 404 Route */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Layout>
    </TranslationProvider>
  );
}

export default App;