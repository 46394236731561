import React, { useState, useEffect } from 'react';
import { Shield } from 'lucide-react';
import useTranslate from '../hooks/useTranslate';

const PrivacyPolicy = () => {
  const { translateText, currentLanguage } = useTranslate();
  const [translations, setTranslations] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const translatePrivacyPolicy = async () => {
      try {
        const translatedContent = {
          title: await translateText('Privacy Policy', currentLanguage),
          introduction: await translateText('At ImgToolLab, we take your privacy seriously. This policy explains how we collect, use, and protect your personal information when you use our services.', currentLanguage),
          infoCollectedTitle: await translateText('Information We Collect', currentLanguage),
          infoCollectedItems: [
            {
              title: await translateText('No Image Storage', currentLanguage),
              description: await translateText('We do not store any uploaded images on our servers', currentLanguage),
            },
            {
              title: await translateText('Local Processing', currentLanguage),
              description: await translateText('All image processing is done locally in your browser', currentLanguage),
            },
            {
              title: await translateText('Usage Analytics', currentLanguage),
              description: await translateText('Basic, non-personal usage statistics to improve our service', currentLanguage),
            },
            {
              title: await translateText('User Preferences', currentLanguage),
              description: await translateText('Your preferred settings and cookie choices', currentLanguage),
            }
          ],
          howWeUseTitle: await translateText('How We Use Your Information', currentLanguage),
          howWeUseItems: [
            {
              title: await translateText('Service Improvement', currentLanguage),
              description: await translateText('To enhance and optimize our tools and features', currentLanguage),
            },
            {
              title: await translateText('Usage Analysis', currentLanguage),
              description: await translateText('To understand how our tools are being used', currentLanguage),
            },
            {
              title: await translateText('Technical Support', currentLanguage),
              description: await translateText('To identify and fix technical issues', currentLanguage),
            },
            {
              title: await translateText('Personalization', currentLanguage),
              description: await translateText('To provide a customized experience', currentLanguage),
            }
          ],
          dataSecurity: {
            title: await translateText('Data Security', currentLanguage),
            description: await translateText('Your files are processed entirely in your browser and are never uploaded to our servers. We use industry-standard security measures to protect any information we do collect.', currentLanguage),
          },
          policyUpdates: {
            title: await translateText('Policy Updates', currentLanguage),
            description: await translateText('We may update this privacy policy from time to time. We will notify you of any changes by posting the new policy on this page.', currentLanguage),
          },
          contact: {
            title: await translateText('Contact Us', currentLanguage),
            description: await translateText('If you have any questions about our privacy policy, please contact us at support@imgtoollab.com', currentLanguage),
          }
        };
        setTranslations(translatedContent);
        setIsLoading(false);
      } catch (error) {
        console.error('Translation error:', error);
        // Fallback to English if translation fails
        setTranslations({
          title: 'Privacy Policy',
          introduction: 'At ImgToolLab, we take your privacy seriously. This policy explains how we collect, use, and protect your personal information when you use our services.',
          infoCollectedTitle: 'Information We Collect',
          infoCollectedItems: [
            {
              title: 'No Image Storage',
              description: 'We do not store any uploaded images on our servers',
            },
            {
              title: 'Local Processing',
              description: 'All image processing is done locally in your browser',
            },
            {
              title: 'Usage Analytics',
              description: 'Basic, non-personal usage statistics to improve our service',
            },
            {
              title: 'User Preferences',
              description: 'Your preferred settings and cookie choices',
            }
          ],
          howWeUseTitle: 'How We Use Your Information',
          howWeUseItems: [
            {
              title: 'Service Improvement',
              description: 'To enhance and optimize our tools and features',
            },
            {
              title: 'Usage Analysis',
              description: 'To understand how our tools are being used',
            },
            {
              title: 'Technical Support',
              description: 'To identify and fix technical issues',
            },
            {
              title: 'Personalization',
              description: 'To provide a customized experience',
            }
          ],
          dataSecurity: {
            title: 'Data Security',
            description: 'Your files are processed entirely in your browser and are never uploaded to our servers. We use industry-standard security measures to protect any information we do collect.',
          },
          policyUpdates: {
            title: 'Policy Updates',
            description: 'We may update this privacy policy from time to time. We will notify you of any changes by posting the new policy on this page.',
          },
          contact: {
            title: 'Contact Us',
            description: 'If you have any questions about our privacy policy, please contact us at support@imgtoollab.com',
          }
        });
        setIsLoading(false);
      }
    };

    translatePrivacyPolicy();
  }, [translateText, currentLanguage]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="max-w-4xl mx-auto px-4 py-12">
      <div className="flex items-center justify-center gap-4 mb-8">
        <Shield className="w-12 h-12 text-[#F7AA01]" />
        <h1 className="text-4xl font-bold text-white">{translations.title}</h1>
      </div>

      <div className="space-y-8">
        <section className="bg-gray-800 rounded-lg p-6">
          <p className="text-gray-300 leading-relaxed">
            {translations.introduction}
          </p>
        </section>

        <section className="bg-gray-800 rounded-lg p-6">
          <h2 className="text-2xl font-semibold text-white mb-4">{translations.infoCollectedTitle}</h2>
          <div className="grid md:grid-cols-2 gap-6">
            {translations.infoCollectedItems.map((item, index) => (
              <div key={index}>
                <h3 className="text-lg font-semibold text-[#F7AA01] mb-2">{item.title}</h3>
                <p className="text-gray-300">{item.description}</p>
              </div>
            ))}
          </div>
        </section>

        <section className="bg-gray-800 rounded-lg p-6">
          <h2 className="text-2xl font-semibold text-white mb-4">{translations.howWeUseTitle}</h2>
          <div className="grid md:grid-cols-2 gap-6">
            {translations.howWeUseItems.map((item, index) => (
              <div key={index}>
                <h3 className="text-lg font-semibold text-[#F7AA01] mb-2">{item.title}</h3>
                <p className="text-gray-300">{item.description}</p>
              </div>
            ))}
          </div>
        </section>

        <section className="bg-gray-800 rounded-lg p-6">
          <h2 className="text-2xl font-semibold text-white mb-4">{translations.dataSecurity.title}</h2>
          <p className="text-gray-300 leading-relaxed">
            {translations.dataSecurity.description}
          </p>
        </section>

        <section className="bg-gray-800 rounded-lg p-6">
          <h2 className="text-2xl font-semibold text-white mb-4">{translations.policyUpdates.title}</h2>
          <p className="text-gray-300 leading-relaxed">
            {translations.policyUpdates.description}
          </p>
        </section>

        <section className="bg-gray-800 rounded-lg p-6">
          <h2 className="text-2xl font-semibold text-white mb-4">{translations.contact.title}</h2>
          <p className="text-gray-300 leading-relaxed">
            {translations.contact.description}
          </p>
        </section>
      </div>
    </div>
  );
};

export default PrivacyPolicy;